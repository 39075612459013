import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from "components/CustomButtons/Button";
import Slide from '@material-ui/core/Slide';
import { isMobile } from "react-device-detect";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function ResponsiveDialog(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { classes } = props;


    React.useEffect(() => {
        if (sessionStorage.getItem("modal") === null) {
            setTimeout(() => {
                setOpen(true);
                sessionStorage.setItem("modal", "ok");
            }, 3000);
        } else {
            console.log("se ignora modal")
        }

    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const texto = (<>
        Nos unimos a la iniciativa de gobierno Nacional, INNpulsa Colombia
        y Fedesoft y creamos <b style={{ color: "#000" }}>CoronavirusCOL.co</b>, un autodiagnóstico para que evalues tus síntomas respecto al COVID-19.</>)

    return (
        <Dialog
            fullWidth
            maxWidth={"lg"}
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={Transition}
        >
            <DialogActions>
                <CloseIcon style={{ fontSize: 50, cursor: "pointer" }} onClick={handleClose} />
            </DialogActions>
            <DialogContent>
                {isMobile ?
                    (<GridContainer>
                        <GridItem xs={12} md={12} sm={12} lg={12} className={classes.center}>
                            <img
                                alt={""}
                                src={require("assets/img/extra/coronaviruscolMobile.png")}
                                style={{
                                    width: "160px",
                                    height: "160px"
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} md={12} sm={12} lg={12} className={classes.center}>
                            <span style={{ fontSize: 13 }}>APOYAN</span>
                            <img
                                alt={""}
                                src={require("assets/img/modal/logoGov.png")}
                                width="100%"
                                height={"50"}
                            />
                        </GridItem>
                        <GridItem xs={6} md={6} sm={6} lg={6} className={classes.center}
                            style={{ display: "flex", padding: 10 }}
                        >
                            <img
                                alt={""}
                                src={require("assets/img/modal/innpulsa.png")}
                                width="100%"
                                height={"50"}
                            />
                        </GridItem>
                        <GridItem xs={6} md={6} sm={6} lg={6} className={classes.center}
                            style={{ textAlign: "center", alignSelf: "center", padding: 10 }}
                        >
                            <img
                                alt={""}
                                src={require("assets/img/modal/fedesotf.png")}
                                width="100%"
                                height={"50"}
                            />
                        </GridItem>
                        <GridItem xs={12} md={12} sm={12} lg={12} className={classes.center}>
                            <div className={classes.gray}>
                                <span style={{ lineHeight: "unset !important", fontFamily: "arial", fontSize: 14 }}>{texto}</span>
                                <h3>
                                    <Button
                                        round
                                        style={{ backgroundColor: "limegreen", color: "#fff" }}
                                        size={"sm"}
                                        fullWidth
                                        color="facebook"
                                        onClick={handleClose}
                                    >
                                        <b style={{ fontSize: 15 }}>OK</b>
                                    </Button>
                                </h3>
                                <h3>
                                    <span className={classes.region}>#EstaEnNuestrasManos</span>
                                    <span className={classes.blue}> QuedateEnCasa</span>
                                </h3>
                            </div>
                        </GridItem>
                    </GridContainer>)
                    :
                    <GridContainer>
                        <GridItem xs={12} md={12} sm={12} lg={4} className={classes.center}>
                            <img
                                alt={""}
                                src={require("assets/img/extra/coronaviruscol.png")}
                                width={"350"}
                                height={"350"}
                            />
                        </GridItem>
                        <GridItem xs={12} md={12} sm={12} lg={8} className={classes.center}>
                            <img
                                alt={""}
                                src={require("assets/img/extra/logos.png")}
                                width="100%"
                            />
                            <br />
                            <div className={classes.gray}>
                                <span style={{ fontSize: 25, fontFamily: "arial" }}>{texto}</span>
                                <h3>
                                    <Button
                                        round
                                        style={{ backgroundColor: "limegreen", color: "#fff" }}
                                        size={"sm"}
                                        fullWidth
                                        color="facebook"
                                        onClick={handleClose}
                                    >
                                        <b style={{ fontSize: 20 }}>OK</b>
                                    </Button>
                                </h3>
                                <h3>
                                    <span className={classes.region}>#EstaEnNuestrasManos</span>
                                    <span className={classes.blue}> QuedateEnCasa</span>
                                </h3>
                            </div>
                        </GridItem>
                    </GridContainer>
                }
            </DialogContent>
        </Dialog>
    );
}
