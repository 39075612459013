import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import * as moment from "moment";

const ButtonNextTest = props => {
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);

  useEffect(() => {
    //const dateFirebase = moment(props.lastTest.date.toDate());
    const nextTest = moment(props.lastTest.date.toDate()).add(12, "hours");
    console.log(nextTest.toDate());
    const totalMinutes = nextTest.diff(moment(), "minutes");
    const totelHours = totalMinutes / 60.0;
    console.log("totalMinutes: " + totalMinutes);
    if (totalMinutes > 0) {
      const hours = Math.trunc(totelHours);
      const minutes = Math.trunc((totelHours - Math.floor(totelHours)) * 60);
      setHours(hours);
      setMinutes(minutes);
    } else {
      setHours(0);
      setMinutes(0);
    }
  }, []); // eslint-disable-line

  const isReady = hours <= 0 && minutes <= 0;

  return (
    <div>
      <b style={{ fontSize: 12, color: isReady ? "green" : "#aaa" }}>
        {isReady
          ? "Te recomendamos repetir el test ahora"
          : `Podrás hacer otro test dentro de ${hours} h y ${minutes} minutos`}
      </b>
      <br></br>
      <Button
        fullWidth
        disabled={!isReady}
        size={"lg"}
        style={{ backgroundColor: isReady ? "green" : "#000" }}
        onClick={() => props.setStep("questions")}
      >
        {"Hacer nueva autoevaluación"}
      </Button>
    </div>
  );
};

export default ButtonNextTest;
