import React, { useState } from 'react';
import FirebaseConfig from "config/FirebaseConfig";
import firebase from 'firebase/app';
import "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import * as moment from "moment";

import Step1 from "./Step1";
import Login from "./Login";
import Questions from "./Questions";
import PersonalForm from "./PersonalForm";
import GeneralResult from "./GeneralResult";
import AutoTestFinal from "./AutoTestFinal";
import MedicalAttention from "./MedicalAttention";
import Notification from "./Notification";

const db = firebase.firestore(FirebaseConfig);
const Main = () => {
    const [step, setStep] = useState("start");
    const [personData, setPersonData] = useState({});
    const [notificationMessage, setNotificationMessage] = useState(false);


    const saveData = async (answers) => {

        const user = JSON.parse(localStorage.getItem("user"));

        if (localStorage.getItem("firstTest") !== undefined &&
            localStorage.getItem("firstTest") !== null &&
            localStorage.getItem("firstTest") === "yes"
        ) {
            console.log("segundo test....");
            const idTest = uuidv4();

            const answersSend = {
                answers,
                email: user.email,
                date: new Date()
            }

            await db.collection("auto-test").doc(idTest).set(answersSend).then(() => {
                console.log("Test successfully written!");
            }).catch((error) => {
                console.error("Error writing Test: ", error);
            });


        } else {
            console.log(personData);
            console.log(answers);

            localStorage.setItem("personData", JSON.stringify(personData));

            const personDataSend = {
                dataGoogle: user,
                personData,
                dateSignUp: new Date()
            }
            const answersSend = {
                answers,
                email: user.email,
                date: new Date()
            }
            const idTest = uuidv4();
            await db.collection("persons").doc(user.email).set(personDataSend).then(async () => {
                console.log("Person successfully written!");
                await db.collection("auto-test").doc(idTest).set(answersSend).then(() => {
                    console.log("Test successfully written!");
                    localStorage.setItem("firstTest", "yes");
                }).catch((error) => {
                    console.error("Error writing Test: ", error);
                });

            }).catch((error) => {
                console.error("Error writing Person: ", error);
            });
        }
        sendNotification();
        setStep("general-result");

    }

    const sendNotification = () => {
        //mandamos la notificacion aca:         
        var OneSignal = window.OneSignal || [];
        OneSignal.push(() => {
            const userIn = JSON.parse(localStorage.getItem("user"));
            OneSignal.sendTags({
                email: userIn.email,
            });
            OneSignal.getNotificationPermission(permission => {
                console.log("Site Notification Permission:", permission);
                OneSignal.getUserId(userId => {
                    console.log("OneSignal User ID (getNotificationPermission): " + userId);
                    if (userId != null) {
                        console.log("userId: " + userId);


                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", "Basic ZGZmNDU5NzAtNzI3YS00YzQ2LThhYTEtMzNmYzgyMjIxY2Y0");
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "template_id": "3aa988fb-182c-4c95-af5b-def170ab7744",
                            "app_id": "52846ce2-e2b7-4ad3-973d-a61e80aa6353",
                            "url": "https://www.coronaviruscol.com/test",
                            "include_player_ids": [userId],
                            "send_after": moment().add(13, "hours").toDate()
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch("https://onesignal.com/api/v1/notifications", requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                setNotificationMessage(true);
                                console.log(result);
                            })
                            .catch(error => console.log('error', error));


                    }
                });
            });
        });
    }

    const getComponent = () => {
        switch (step) {
            case "start":
                return <Step1 setStep={setStep} />
            case "login":
                return <Login setStep={setStep} />
            case "personal-form":
                return <PersonalForm setStep={setStep} setPersonData={setPersonData} />
            case "questions":
                return <Questions setStep={setStep} saveData={saveData} />
            case "general-result":
                return <GeneralResult db={db} setStep={setStep} />
            case "auto-test-final":
                return <AutoTestFinal db={db} setStep={setStep} />
            case "medical-attention":
                return <MedicalAttention db={db} setStep={setStep} />
            default:
                return "Lo sentimos ha ocurrido un error";
        }
    }


    return (
        <>
            {getComponent()}
            {notificationMessage && <Notification
                message={"Recordatorio programado!"}
                setClose={setNotificationMessage}
            />}
        </>
    )

};

export default Main;