export function getCorrectAnswerQuiz(answers) {
    const correctAnswersArray = ["B", "A", "B", "A", "D", "A", "A", "A", "D", "B"];
    let correctAnswers = 0;
    for (var prop in answers) {
        const indexAnswer = parseInt(prop);
        if (answers[prop] === correctAnswersArray[indexAnswer - 1]) {
            correctAnswers++;
        }
    }
    return correctAnswers;
}