//import ContactoConPersonas from "assets/img/recommendations/forU/ContactoConPersonas.svg";
import DuermeBien from "assets/img/recommendations/forU/Duerme bien.svg";
import EsMomentoDeEstarEnCasa from "assets/img/recommendations/forU/EsMomentoDeEstarEnCasa.svg";
import EstaEnNuestras from "assets/img/recommendations/forU/Está en nuestras manos.svg";
import EvitaElContactoConOtrasPersonas from "assets/img/recommendations/forU/Evita el contacto con otras personas.svg";
import HigieneEnCasa from "assets/img/recommendations/forU/Higiene en casa.svg";
//import NoEsObligatorioQueUtilicesMasca from "assets/img/recommendations/forU/No es obligatorio que utilices mascarilla.svg";
import NoToques from "assets/img/recommendations/forU/No toques tu cara.svg";
import NoSaturarElSistemaDeSalud from "assets/img/recommendations/forU/NoSaturarElSistemaDeSalud.svg";
import ObservaTuEvolucion from "assets/img/recommendations/forU/Observa tu evolucion.svg";
import PeriodoDeIncubacion from "assets/img/recommendations/forU/PeriodoDeIncubacion.svg";
import SeaCualSeaTu from "assets/img/recommendations/forU/SeaCualSeaTu estado, evita el contacto con los más vulnerables.svg";
//import SiNecesitasToserOEstornudar from "assets/img/recommendations/forU/Si necesitas toser o estornudar.svg";
import TeRecomendamosQueUtilicesTapabocas from "assets/img/recommendations/forU/Te recomendamos que utilices tapabocas.svg";


//
import ComoSeTransmite from "assets/img/recommendations/keeper/Como se transmite.svg";
import CuidadoEnCasa from "assets/img/recommendations/keeper/Cuidado en casa.svg";
//import EvitaEstarMuyCercanoCon from "assets/img/recommendations/keeper/Evita estar muy cercano con las personas de casa.svg";
import SiTienesUnaCita from "assets/img/recommendations/keeper/Si tienes una cita medica ya concertada.svg";

import CualesSonLasPoblacionesConMayorRiesgo from "assets/img/recommendations/forU/CualesSonLasPoblacionesConMayorRiesgo.png";
import EscribealWhatsAppazul from "assets/img/recommendations/forU/EscribealWhatsAppazul.png";



export const recommendations = [
    {
        id: 1,
        image: PeriodoDeIncubacion,
        title: "Periodo de incubación",
        detail: "El tiempo de incubación varía de 5 a 14 días, tu estado de salud puede cambiar en este tiempo."
    },
    {
        id: 2,
        image: EsMomentoDeEstarEnCasa,
        title: "Es momento de estar en casa",
        detailList: [
            "Permanece en tu casa, cualquier salida puede ser fuente de contagio. Protege a los que más quieres.",
            "Evita el uso de transporte público."
        ]
    },
    {
        id: 3,
        image: NoSaturarElSistemaDeSalud,
        title: "No saturar el sistema de salud",
        detail: "¿tienes dudas de tu estado de salud?, realiza la autoevaluación o comunícate con la línea de atención médica."
    },
    {
        id: 4,
        image: SeaCualSeaTu,
        title: "Sea cual sea tu estado, evita el contacto con los más vulnerables",
        detail: "Las personas mayores o con enfermedades crónicas son las más vulnerables, ponte en el lugar de ellos."
    },
    {
        id: 5,
        image: TeRecomendamosQueUtilicesTapabocas,
        title: "Te recomendamos que utilices tapabocas",
        detail: "Este momento es clave en la curva de crecimiento del COVID19, cumple tu deber de ciudadano."
    },
    {
        id: 6,
        image: EstaEnNuestras,
        title: "Está en nuestras manos",
        detailList: [
            "Tus manos pueden entrar en contacto con el virus. Cuando llegues a casa lava tus manos con agua y jabón.",
            "25 Segundos es un buen tiempo de lavado."
        ]
    },
    {
        id: 7,
        image: NoToques,
        title: "No toques tu cara",
        detail: "En promedio tocas tu cara 500 veces al día, es la principal manera de contagiarte."
    },
    {
        id: 8,
        image: EvitaElContactoConOtrasPersonas,
        title: "Mantén distancia con otras personas.",
        detail: "Manten 2 metros de distancia con cada persona (En bancos, tiendas), asi evitas el contagion."
    },
    {
        id: 9,
        image: DuermeBien,
        title: "Duerme bien, aliméntate bien y toma bastante agua.",
        detail: "Si llegas a contagiarte tu cuerpo necesitará estar de la mejor manera posible"
    },
    {
        id: 10,
        image: EvitaElContactoConOtrasPersonas,
        title: "Evita estar muy cercano con las personas de casa",
        detailList: [
            "Si llegas a contagiarte tu cuerpo necesitará estar de la mejor manera posible",
            "Si alguien de tu familia parece tener síntomas, usa tapabocas cuando estén cerca."
        ]
    },
    {
        id: 11,
        image: CuidadoEnCasa,
        title: "Cuidado en casa",
        detailList: [
            "Mantén especial cuidado en los objetos de uso cotidiano. Límpialos con desinfectante.",
            "Estornuda en el codo. Mantente al pendiente de tus seres queridos para notar cambios en su estado de salud."
        ]
    },
    {
        id: 12,
        image: ObservaTuEvolucion,
        title: "Chequea tu estado de salud continuamente",
        detail: "Haz la autoevaluación cada 12 horas para chequear tu evolución. Mide tu temperatura cada 8 horas."
    },
    {
        id: 13,
        image: EsMomentoDeEstarEnCasa,
        title: "Mejor estar en casa",
        detail: "Es necesario que cambies tus hábitos fuera de casa para evitar el contagio."
    },
    {
        id: 14,
        image: EvitaElContactoConOtrasPersonas,
        title: "Mantén distancia con tus seres queridos",
        detailList: [
            "Evita contacto cercano con tus seres queridos (familia, pareja, amigos), cuando estes con ellos usa tapabocas.",
            "Abre las ventanas de tu habitación."
        ]
    },
    {
        id: 15,
        image: HigieneEnCasa,
        title: "Higiene en casa",
        detailList: [
            "Desinfecta tus objetos de uso personal y cotidiano",
            "Manten en una caneca de basura separada tus desechos personales (pañuelos, papel higiénico)."
        ]
    },
    {
        id: 16,
        image: ComoSeTransmite,
        title: "Cómo se transmite",
        detail: "Por gotas que se expulsan al toser, por eso la higiene es vital para reducir el contagio."
    },
    {
        id: 17,
        image: SiTienesUnaCita,
        title: "Si tienes una cita médica ya concertada",
        detail: "Llama al centro médico y sigue sus recomendaciones."
    },
    {
        id: 18,
        image: CualesSonLasPoblacionesConMayorRiesgo,
        title: "¿Cuáles son las poblaciones con mayor riesgo?",
        detail: "Adultos mayores de 70 años, pacientes con diabetes mellitus, hipertensión arterial, enfermedad renal crónica, enfermedad pulmonar crónica"
    },
    {
        id: 19,
        image: EscribealWhatsAppazul,
        title: "Escribe al WhatsApp azul",
        detail: "Coméntales en detalle tus síntomas, ellos te ayudarán"
    },
];