export const questionsQuiz = [
    {
        id: 1,
        text: "¿En qué ciudad se originó el COVID19?",
        answers: ["Hong Kong, China", "Wuhan, China", "Pekín, China", "Bérgamo, Italia"]
    },
    {
        id: 2,
        text: "¿A qué país de América Latina llegó 1ero?",
        answers: ["Brasil", "Argentina", "Chile", "México"]
    },
    {
        id: 3,
        text: "¿País con mayor número de muertes por COVID19?",
        answers: ["China", "Italia", "España", "USA"]
    },
    {
        id: 4,
        text: "¿El COVID19 afecta a los animales?",
        answers: ["No", "Si"]
    },
    {
        id: 5,
        text: "¿De qué animal se originó la pandemia del COVID19?",
        answers: ["Pangolín", "Cerdos", "Ratas", "Murciélagos"]
    },
    {
        id: 6,
        text: "¿Cuál ha sido la pandemia más mortífera de la historia?",
        answers: ["Peste negra", "Sarampión", "Viruela", "Gripe Española"]
    },
    {
        id: 7,
        text: "¿Cuál es la diferencia entre pandemias y epidemias?",
        answers: [
            "Una epidemia se da en un área geográfica en concreto y Pandemia es mundial",
            "Una epidemia se contagia por el contacto físico y la pandemia por vía respiratoria a través de gotículas",
            "Una epidemia ataca a sola una población en específico (Ancianos, por ejemplo) mientras que la pandemia ataca a cualquier población",
            "Una epidemia tiene mayor número de contagiados que la pandemia"
        ]
    },
    {
        id: 8,
        text: "¿A lo largo de la historia cuáles continentes han originado más pandemias?",
        answers: ["Asia y África", "América y Europa"]
    },
    {
        id: 9,
        text: "¿Qué población es más susceptible a la afectación del COVID19?",
        answers: ["Niños", "Mujeres", "Europeos", "Tercera Edad"]
    },
    {
        id: 10,
        text: "¿Cuál es el periodo de incubación del COVID19?",
        answers: ["2-8 días", "1- 14 días", "5-16 días", "1-25 días"]
    },


];

