import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { isMobile } from "react-device-detect";
import { WhatsappShareButton, FacebookShareButton } from "react-share";
import { ReactComponent as VirusSvg } from "assets/img/Virus.svg";
import { ReactComponent as VirusSvgMobile } from "assets/img/VirusMobile.svg";
import { ReactComponent as Uno } from "assets/img/landing/uno.svg";
import { ReactComponent as Dos } from "assets/img/landing/dos.svg";
import { ReactComponent as Tres } from "assets/img/landing/tres.svg";
import { ReactComponent as Cuatro } from "assets/img/landing/cuatro.svg";
import { ReactComponent as Wapp } from "assets/img/landing/wapp.svg";
import { ReactComponent as Fb } from "assets/img/landing/fb.svg";
import ModalInnpulsa from "./ModalInnpulsa";

const useStyles = makeStyles({
    color: {
        color: "#000",
        fontFamily: "Inter, sans-serif"
    },
    center: {
        textAlign: "center"
    },
    back: {
        color: "rgba(51, 51, 51, 0.8)",
        backgroundColor: "rgba(0, 116, 163, 0.1)",
        fontSize: 16
    },
    gray: {
        color: "#808080"
    },
    blue: {
        color: "#0088ff",
        cursor: "pointer",
        "&:hover": {
            color: "#0088ff"
        }
    },
    region: {
        color: "#ffffff",
        backgroundColor: "#99cc00"
    },
});

const Index = props => {
    const classes = useStyles();
    const space = (
        <GridItem xs={12} md={12} sm={12} lg={12}>
            <br></br>
            <br></br>
        </GridItem>
    );

    return (

        <GridContainer>
            <GridItem xs={12} md={7} sm={12} lg={7}
                style={{
                    paddingTop: isMobile ? 10 : 80
                }}
            >
                <GridContainer style={{ padding: 40 }}>
                    <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingBottom: isMobile ? 10 : 20 }}>
                        <span style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: 50,
                            color: "#252525",
                            float: "left"
                        }}>COVID-19</span>
                    </GridItem>
                    <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingBottom: isMobile ? 30 : 70 }}>
                        <span style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: 30,
                            color: "#252525",
                            float: "left"
                        }}>Haz tu autoevaluación</span>
                    </GridItem>
                    {isMobile &&
                        <GridItem xs={12} md={12} sm={12} lg={12}
                            className={classes.center}
                            style={{ paddingBottom: 30 }}>
                            <VirusSvgMobile />
                        </GridItem>}
                    <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingBottom: isMobile ? 20 : 30 }}>
                        <span style={{
                            fontFamily: "Open Sans",
                            fontWeight: "normal",
                            fontSize: 17,
                            float: "left",
                            color: "#252525"
                        }}>Esta aplicación ayuda a oxigenar el sistema de salud en Colombia. Seamos responsables, ayudemos a nuestra a sanidad pública.</span>
                    </GridItem>
                    <GridItem xs={12} md={12} sm={12} lg={12}>
                        <Button
                            style={{ backgroundColor: "#FF9C09", borderRadius: 10, width: "70%" }}
                            size={"lg"}
                            onClick={() => props.history.push("test")}
                        >
                            <b style={{ fontSize: 15, fontFamily: "Poppins" }}>Empezar</b>
                        </Button>
                    </GridItem>

                </GridContainer>
            </GridItem>
            {!isMobile &&
                <GridItem xs={12} md={5} sm={12} lg={5}
                    className={classes.center}
                >
                    <VirusSvg style={{ width: "70%" }} />
                </GridItem>}
            <GridItem xs={12} md={12} sm={12} lg={12} style={{ backgroundColor: "#F7FAFF" }}>
                <GridContainer style={{ textAlign: "center", padding: 40 }}>
                    <GridItem xs={12} md={3} sm={6} lg={3} style={{ borderBottom: isMobile && "2px solid gray" }}>
                        <Uno style={{ width: 140 }} /><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: 24,
                                alignItems: "center",
                                color: "#252525",
                            }}
                        >Introduce tus datos</span><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: 18,
                                color: "#252525",
                            }}
                        >Con ellos podremos hacerte un seguimiento.</span>
                    </GridItem>
                    <GridItem xs={12} md={3} sm={6} lg={3} style={{ borderBottom: isMobile && "2px solid gray" }}>
                        <Dos style={{ width: 140 }} /><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: 24,
                                alignItems: "center",
                                color: "#252525",
                            }}
                        >Realiza tu autoevaluación</span><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: 18,
                                color: "#252525",
                            }}
                        >Contestando a las preguntas basicas.</span>
                    </GridItem>
                    <GridItem xs={12} md={3} sm={6} lg={3} style={{ borderBottom: isMobile && "2px solid gray" }}>
                        <Tres style={{ width: 140 }} /><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: 24,
                                alignItems: "center",
                                color: "#252525",
                            }}
                        >Consejos e Instrucciones</span><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: 18,
                                color: "#252525",
                            }}
                        >Damos recomendaciones personalizadas.</span>
                    </GridItem>
                    <GridItem xs={12} md={3} sm={6} lg={3} style={{ borderBottom: isMobile && "2px solid gray" }}>
                        <Cuatro style={{ width: 140 }} /><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: 24,
                                alignItems: "center",
                                color: "#252525",
                            }}
                        >Consulta medica online</span><br></br><br></br>
                        <span
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: 18,
                                color: "#252525",
                            }}
                        >Recibe una consulta medica gratuita.</span>
                    </GridItem>
                </GridContainer>
            </GridItem>

            <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingTop: 70, paddingBottom: 70, textAlign: "center" }}>
                <GridContainer>
                    <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingLeft: 60, paddingRight: 60 }}>
                        <span style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: isMobile ? 20 : 40,
                            lineHeight: 1
                        }}>¿Quieres ser parte de la iniciativa para prevenir la propagación del Covid-19?</span>
                    </GridItem>
                    <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 20 }}>
                        <span style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontStyle: "normal",
                            fontSize: isMobile ? 15 : 30,
                        }}>¡Comparte en tus redes sociales!</span>
                    </GridItem>
                    <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 20 }}>
                        <WhatsappShareButton
                            url={
                                "http://coronaviruscol.com/ \n ¡Haz tu test diagnóstico del Covid 19! Súmate y ayudemos a descongestionar el sistema de salud, es por tu bien, por el de todos. *#EstaEnNuestrasManos* 🇨🇴 \n\n¡Compártelo en tu historia y ayudemos a Colombia!"
                            }
                        >
                            <Wapp />
                        </WhatsappShareButton>
                        <FacebookShareButton
                            url={
                                "http://coronaviruscol.com/ \n ¡Haz tu test diagnóstico del Covid 19! Súmate y ayudemos a descongestionar el sistema de salud, es por tu bien, por el de todos. *#EstaEnNuestrasManos* 🇨🇴 \n\n¡Compártelo en tu historia y ayudemos a Colombia!"
                            }
                        >
                            <Fb />
                        </FacebookShareButton>
                    </GridItem>
                </GridContainer>
            </GridItem>

            <GridItem xs={12} md={12} sm={12} lg={12} className={classes.center}>
                {process.env.NODE_ENV === "production" ?
                    <iframe
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        title="Visualización de datos"
                        allowtransparency="true"
                        allowFullscreen="true"
                        className="tableauViz"
                        style={{
                            display: "block",
                            width: "100%",
                            height: 600,
                            margin: 0,
                            padding: 0,
                            border: "none"
                        }}
                        src="https://www.covidvisualizer.com/"
                    ></iframe> : "¡FRAME se muestra solo en producción para optimizar memoria en desarrollo!"}
            </GridItem>
            {space}
            <ModalInnpulsa classes={classes} history={props.history} />
        </GridContainer>
    );
};

export default Index;
