import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { CardActionArea } from '@material-ui/core';


const Index = props => {

  const goOtherPage = () => {
    const link =
      "https://www.cdc.gov/coronavirus/2019-ncov/prepare/prevention-sp.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fabout%2Fprevention-sp.html";
    window.open(link, "_blank");
  };

  const styleCard = {
    minHeight: 500
  }
  return (
    <GridContainer>
      <GridItem xs={12} md={4} sm={12} lg={4}>
        <Card>
          <CardActionArea onClick={() => props.history.push("/extras/quiz")} style={styleCard}>
            <CardHeader>
              <img alt={""} style={{ width: "100%" }} src={"https://images-na.ssl-images-amazon.com/images/I/41q4jxpct8L.png"} />
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <h3>Quiz interactivo</h3>
              <span>Pon a prueba tus conocimientos sobre el coronavirus</span>
            </CardBody>
          </CardActionArea>
        </Card>
      </GridItem>
      <GridItem xs={12} md={4} sm={12} lg={4}>
        <Card>
          <CardActionArea onClick={() => props.history.push("/extras/links")} style={styleCard}>
            <CardHeader>
              <img alt={""} style={{ width: "100%" }} src={"https://image.flaticon.com/icons/png/512/1428/1428075.png"} />
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <h3>Enlaces externos</h3>
              <span>Rumores del sobre el coronavirus</span>
            </CardBody>
          </CardActionArea>
        </Card>
      </GridItem>
      <GridItem xs={12} md={4} sm={12} lg={4}>
        <Card>
          <CardActionArea onClick={goOtherPage} style={styleCard}>
            <CardHeader>
              <img alt={""} style={{ width: "100%" }} src={"https://img2.freepng.es/20180410/jrw/kisspng-world-health-organization-who-collaborating-centre-organization-5acd0b78a8bfc3.2752645315233872566912.jpg"} />
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <h3>Sugerencias</h3>
              <span>Sugerencias y recomendaciones OMS</span>
            </CardBody>
          </CardActionArea>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Index;
