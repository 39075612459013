export const questions = [
    {
        id: 1,
        text: "¿Tienes sensación de falta de aire?"
    },
    {
        id: 2,
        text: "¿Tienes fiebre? (+37.7ºC)"
    },
    {
        id: 3,
        text: "¿Tienes tos seca y persistente?"
    },
    {
        id: 4,
        text: "¿Viajó o estuvo en contacto con alguien que en los últimos 14 días haya estado en alguno de los siguientes países: China, Irán, Corea del sur, Japón, Italia, Francia, Alemania, España, Estados Unidos, Ecuador?"
    },
    {
        id: 5,
        text: "¿Tienes mucosidad en la nariz?"
    },
    {
        id: 6,
        text: "¿Tienes dolor muscular?"
    },
    {
        id: 7,
        text: "¿Tienes sintomatología gastrointestinal? (Diarrea, vómito)"
    },
    {
        id: 8,
        text: "¿Llevas más de 20 días con estos síntomas?"
    },
];

