import React, { useState } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isMobile } from "react-device-detect";
import RadioQuiz from "components/Radio/RadioQuiz";
import { questionsQuiz } from "variables/questionsQuiz";
import Button from "components/CustomButtons/Button.js";
import Loader from 'react-loader-spinner';
import { getCorrectAnswerQuiz } from "functions/getCorrectAnswerQuiz";
import FirebaseConfig from "config/FirebaseConfig";
import firebase from 'firebase/app';
import "firebase/firestore";



const db = firebase.firestore(FirebaseConfig);
const Quiz = (props) => {
    const [loading, setLoading] = useState(false);
    const [answers, setAnswers] = useState({});
    const [result, setResult] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState();



    const handleChange = (id, answer) => {
        setAnswers({
            ...answers,
            [id]: answer
        });
    };

    const sendData = () => {
        setLoading(true);
        const correctAnswer = getCorrectAnswerQuiz(answers);
        console.log("correctAnswer: " + correctAnswer);

        let user = null;
        try {
            user = JSON.parse(localStorage.getItem("user"));
        } catch (error) {
            console.log("Sin usuario: " + error);
        }

        let r;
        if (correctAnswer <= 3) {
            r = "bad";
        } else if (correctAnswer > 3 && correctAnswer <= 7) {
            r = "regular";
        } else if (correctAnswer > 7) {
            r = "good";
        } else {
            console.log("Tu logica vale papitas.");
        }

        const answersSend = {
            email: user === null ? "anonymous" : user.email,
            answer: r,
            date: new Date(),
            correctAnswer,
        }

        db.collection("interactive-test").doc().set(answersSend).then(() => {
            setResult(r);
            setLoading(false);
            setCorrectAnswer(correctAnswer);
        }).catch((error) => {
            console.error("Error writing Test: ", error);
        });

    }


    if (result === null) {
        const ready = Object.keys(answers).length === questionsQuiz.length;
        return (
            <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                    <h2 style={{ fontWeight: "bold" }}>Quiz interactivo</h2>
                    <span>Responde a las siguientes preguntas para sabes que tanto sabes del coronavirus</span>
                </GridItem>
                {questionsQuiz.map(obj =>
                    <GridItem xs={12} md={12} sm={12} lg={12}
                        key={obj.id}
                        style={{
                            paddingTop: 30
                        }}>
                        <h5 style={{ fontWeight: "bold" }}>{obj.text}</h5>
                        <RadioQuiz id={obj.id} answers={obj.answers} handleChange={handleChange} />
                    </GridItem>
                )}
                <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "left", paddingTop: 40 }}>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                        <Button
                            disabled={!ready || loading}
                            size={"lg"}
                            style={{ backgroundColor: "#0044FF" }}
                            onClick={sendData}
                        >
                            {"Listo"}
                        </Button>
                        {loading &&
                            <Loader
                                type="BallTriangle"
                                color="#0044FF"
                                height={50}
                                width={50}
                            />}
                    </div>
                </GridItem>
            </GridContainer >
        );
    } else {
        const color = result === "bad" ? "red" : (result === "regular" ? "#ff8000" : "green");
        return (
            <GridContainer style={{ padding: isMobile ? 0 : 50, textAlign: "center" }}>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                    <h2 style={{ fontWeight: "bold" }}>Quiz interactivo</h2>
                    <span>{`El resultado que obtuviste es de ${correctAnswer}/10`}</span>
                </GridItem>
                <GridItem xs={12} md={12} sm={12} lg={12}><hr></hr><br></br></GridItem>

                {result === "bad" &&
                    <GridItem xs={12} md={12} sm={12} lg={12}>
                        <h3 style={{ color }}>¡ESTAMOS EN CUARENTENA, NO VACACIONES!</h3>
                        <span>
                            {"Has demostrado que no sabes NADA del COVID19, tal vez debas estar más pendiente de las noticias o redes sociales, todo el mundo está hablado de eso."}
                        </span>
                    </GridItem>
                }
                {result === "regular" &&
                    <GridItem xs={12} md={12} sm={12} lg={12}>
                        <h3 style={{ color }}>¡NO ESTUVO MAL, PERO DEBERIAS SABER MAS!</h3>
                        <span>
                            {"Has demostrado que conoces lo básico del COVID19, pero te falta para ser un experto. Siempre intenta mejorar, sal del grupo de personas promedio…esas son las que se contagian."}
                        </span>
                    </GridItem>
                }
                {result === "good" &&
                    <GridItem xs={12} md={12} sm={12} lg={12}>
                        <h3 style={{ color }}>¡TIENES UN CEREBRO BRILLANTE, CUIDALO DEL CONTAGIO!</h3>
                        <span>
                            {"Has demostrado que eres un experto en COVID19, sigue así!, mantente actualizado y comparte tu conocimiento con otras personas."}
                        </span>
                    </GridItem>
                }


                <GridItem xs={12} md={12} sm={12} lg={12}><br></br></GridItem>
                <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
                    <img style={{ width: "90%" }} src={require("assets/img/resultInteractive.jpg")} alt={""} />
                </GridItem>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                    <Button
                        size={"lg"}
                        style={{ backgroundColor: "#0044FF" }}
                        onClick={() => props.history.push("/extras")}
                    >
                        {"Listo"}
                    </Button>
                </GridItem>
                <GridItem xs={12} md={12} sm={12} lg={12}><hr></hr></GridItem>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                    <span style={{ color: "green" }}>¡Está en nuestras manos!</span>
                </GridItem>
            </GridContainer >
        );
    }


};

export default Quiz;
