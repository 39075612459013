import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isMobile } from "react-device-detect";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "assets/css/comun.css";

const Login = props => {
  const [isLogged, setIsLogged] = React.useState(0);
  const [checkbox, setCheckbox] = React.useState(false);

  const responseGoogle = response => {
    localStorage.setItem("user", JSON.stringify(response.profileObj));
    props.setStep("personal-form");
  };
  const responseGoogleFailure = response => {
    console.log(response);
  };
  const responseFacebook = response => {
    if (response.email !== undefined && response.email !== null) {
      localStorage.setItem("user", JSON.stringify(response));
      props.setStep("personal-form");
    } else {
      console.log("Error: " + JSON.stringify(response));
    }
  };

  React.useEffect(() => {
    try {
      if (
        localStorage.getItem("user") !== undefined &&
        localStorage.getItem("user") !== null
      ) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.email !== undefined) {
          props.setStep("auto-test-final");
        } else {
          setIsLogged(2);
        }
      } else {
        setIsLogged(2);
      }
    } catch (err) {
      console.error("====> " + err);
      setIsLogged(2);
    }
  }, []); // eslint-disable-line

  return (
    <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => props.setStep("start")}
        >
          <KeyboardBackspaceIcon />
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h2>Inicia Sesión</h2>
        <p style={{ color: "#000", fontFamily: "arial", fontSize: 15 }}>
          De esta manera seguiremos tu evolución médica.
        </p>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <br></br>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkbox}
              onClick={() => setCheckbox(!checkbox)}
              color="primary"
            />

          }
          label={<span style={{ fontSize: 12, fontFamily: "arial", color: "#000" }}>
            {"Acepto "}
            <a
              rel="noopener noreferrer"
              href={"/docs/CONDICIONES%20DE%20USO%20CORONAVIRUSCOL.pdf"}
              target="_blank"
              style={{ fontWeight: "bold", color: "#000" }}
            >
              Términos y Condiciones
              </a>
            {" y "}
            <a
              rel="noopener noreferrer"
              href={
                "/docs/POL%C3%8DTICA%20DE%20PRIVACIDAD%20CORONAVIRUSCOL.pdf"
              }
              target="_blank"
              style={{ fontWeight: "bold", color: "#000" }}
            >
              Aviso de Privacidad
              </a>
          </span>}
        />

      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <br></br>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        {isLogged === 2 && (
          <GoogleLogin
            clientId="264178732220-mrfj9b4n4mkiocdkcisu5q4uf7qodj35.apps.googleusercontent.com"
            buttonText={
              <div>
                <img
                  alt={""}
                  width="18"
                  height="18"
                  src={require("assets/img/google.png")}
                />
                &nbsp;&nbsp;Iniciar con Google
              </div>
            }
            onSuccess={responseGoogle}
            onFailure={responseGoogleFailure}
            cookiePolicy={"single_host_origin"}
            disabled={!checkbox}
            className={isMobile ? "googleStyleMobile" : "googleStyle"}
            icon={false}
          />
        )}
        <br></br>
        <br></br>
        {isLogged === 2 && (
          <div style={{ width: "100%" }}>
            <FacebookLogin
              appId="216897396213710"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              icon="fa-facebook"
              textButton={" Iniciar con Facebook"}
              isMobile={false}
              isDisabled={!checkbox}
              cssClass={isMobile ? "facebookStyleMobile" : "facebookStyle"}

            />
          </div>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default Login;
