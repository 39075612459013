import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { register } from 'register-service-worker';
import Home from "views/Home";
import NewVersion from "components/Notifications/NewVersion";
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

var hist = createBrowserHistory();


const analytics = Analytics({
    app: 'awesome-app',
    plugins: [
        googleAnalytics({
            trackingId: 'UA-168119323-1'
        })
    ]
})

const App = () => {

    const [newVersionAvailable, setNewVersionAvailable] = React.useState(false);

    React.useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            register('/OneSignalSDKWorker.js', {
                registrationOptions: { scope: './' },
                ready(registration) {
                    console.log('Service worker is active.')
                },
                registered(registration) {
                    console.log('Service worker has been registered.')
                },
                cached(registration) {
                    console.log('Content has been cached for offline use.')
                },
                updatefound(registration) {
                    console.log('New content is downloading.')
                },
                updated(registration) {
                    console.log('New content is available; please refresh.')
                    setNewVersionAvailable(true);
                },
                offline() {
                    console.log('No internet connection found. App is running in offline mode.')
                },
                error(error) {
                    console.error('Error during service worker registration:', error)
                }
            })
        } else {
            console.log("Service worker in development not allowed by Edwin Acosta");
        }
    }, []);


    const getNewVersion = () => {
        console.log("entro a getNewVersion");
        setNewVersionAvailable(false);
        window.location.reload();

    }
    return (
        <>
            <Router history={hist}>
                <Switch>
                    <Route path="/" component={Home} />
                </Switch>
            </Router>
            {newVersionAvailable && <NewVersion
                setClose={setNewVersionAvailable}
                getNewVersion={getNewVersion}
            />}
        </>
    );
};

hist.listen(() => {
    analytics.page();
});

export default App;






