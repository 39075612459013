import React, { useState } from 'react';
import MaterialTable from "material-table";
import * as moment from "moment";
import { getTowns, getDeparment } from "functions/getLocation";
import ReactJson from 'react-json-view'
import Button from "components/CustomButtons/Button";



const Persons = (props) => {
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState(null);


    React.useEffect(() => {

        (async () => {

            const tests = await getAllAutoTest();


            await props.db.collection("persons")
                .get()
                .then(querySnapshot => {
                    //const persons = querySnapshot.docs.map(doc => doc.data());

                    const persons = querySnapshot.docs.map(doc => {
                        const data = doc.data();
                        let type_sign_up;
                        if (data.dataGoogle.googleId !== undefined && data.dataGoogle.googleId !== null) {
                            type_sign_up = "Google";
                        } else {
                            type_sign_up = "FB";
                        }

                        const auto_tests = tests.filter(obj => obj.email === data.dataGoogle.email).length;

                        return {
                            ...data.personData,
                            location: getDeparment(data.personData.deparment) + " - " + getTowns(data.personData.town),
                            document: data.personData.typeDocument + " " + data.personData.numberDocument,
                            email: data.dataGoogle.email + " - " + data.dataGoogle.name,
                            date_sign_up: moment(data.dateSignUp.toDate()).format("MM-DD-YYYY HH:mm A"),
                            type_sign_up,
                            auto_tests,
                            email_id: data.dataGoogle.email
                        }

                    });
                    setPersons(persons);
                });


        })();


    }, []);// eslint-disable-line 


    const getAllAutoTest = async () => {
        return await props.db.collection("auto-test")
            .get()
            .then(querySnapshot => {
                return querySnapshot.docs.map(doc => doc.data());
            });
    }


    const getPerson = (rowData) => {
        props.db.collection("persons").doc(rowData.email_id)
            .get().then(doc => {
                if (doc.exists) {
                    setPerson(doc.data());
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(error => {
                console.log("Error getting document:", error);
            });
    }

    return (
        <div>
            {person === null ?
                <MaterialTable
                    onRowClick={(e, rowData) => getPerson(rowData)}
                    isLoading={persons.length === 0}
                    title={"Personas"}
                    columns={[
                        { title: 'names', field: 'names' },
                        { title: 'auto-tests', field: 'auto_tests' },
                        { title: 'email', field: 'email' },
                        { title: 'document', field: 'document' },
                        { title: 'age', field: 'age' },
                        { title: 'gender', field: 'gender' },
                        { title: 'location', field: 'location' },
                        { title: 'date_sign_up', field: 'date_sign_up' },
                        { title: 'type_sign_up', field: 'type_sign_up' },
                    ]}
                    data={persons}
                    localization={{
                        header: {
                            actions: 'Acciones'
                        },
                        toolbar: {
                            searchPlaceholder: "Buscar",
                        },
                    }}
                    options={{
                        exportAllData: true,
                        exportButton: true,
                    }}
                /> :
                <div>
                    <Button
                        onClick={() => setPerson(null)}
                    >
                        {"Volver"}
                    </Button>
                    <hr></hr>
                    <ReactJson src={person} theme="monokai" />
                </div>

            }
        </div>
    );
};

export default Persons;