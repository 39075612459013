import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HealingIcon from "@material-ui/icons/Healing";
import { isMobile } from "react-device-detect";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles({
  color: {
    color: "#000"
  },
  center: {
    textAlign: "left"
  },
  funcion: {
    flexDirection: "row",
    display: "flex",
    paddingBottom: 10,
    textAlign: "center"
  },
  icon: {
    alignSelf: "center",
    textAlign: "center",
    width: "20%"
  },
  detail: {
    alignSelf: "center",
    width: "80%",
    textAlign: "justify",
    color: "#847b78",
    fontFamily: "arial"
  }
});

const Step1 = props => {
  const classes = useStyles();
  const space = (
    <GridItem xs={12} md={12} sm={12} lg={12}>
      <br></br>
      <br></br>
    </GridItem>
  );
  return (
    <div className={classes.color}>
      <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
        <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
          <span style={{ fontWeight: "bold", fontSize: isMobile ? 30 : 40 }}>
            Gracias por utilizar esta app, aquí podrás:
          </span>
        </GridItem>
        {space}
        <GridItem xs={12} md={1} sm={1} lg={1}></GridItem>
        <GridItem xs={12} md={5} sm={5} lg={5} className={classes.funcion}>
          <div className={classes.icon}>
            <HealingIcon
              style={{
                alignSelf: "center",
                textAlign: "center",
                fontSize: 30,
                color: "pink"
              }}
            />
          </div>
          <div className={classes.detail}>
            {"Evaluar tu situación de salud en función de tus síntomas."}
          </div>
        </GridItem>
        <GridItem xs={12} md={5} sm={5} lg={5} className={classes.funcion}>
          <div className={classes.icon}>
            <NotificationsIcon
              style={{
                alignSelf: "center",
                textAlign: "center",
                fontSize: 30,
                color: "brown"
              }}
            />
          </div>
          <div className={classes.detail}>
            {
              "Recibir instrucciones y recomendaciones en función de tu estado de salud."
            }
          </div>
        </GridItem>
        <GridItem xs={12} md={1} sm={1} lg={1}></GridItem>
        <GridItem xs={12} md={1} sm={1} lg={1}></GridItem>
        <GridItem xs={12} md={5} sm={5} lg={5} className={classes.funcion}>
          <div className={classes.icon}>
            <AccessTimeIcon
              style={{
                alignSelf: "center",
                textAlign: "center",
                fontSize: 30,
                color: "gray"
              }}
            />
          </div>
          <div className={classes.detail}>
            {"Evaluarte cada 12h y mantener actualizado tu estado de salud."}
          </div>
        </GridItem>
        <GridItem xs={12} md={5} sm={5} lg={5} className={classes.funcion}>
          <div className={classes.icon}>
            <FavoriteIcon
              style={{
                alignSelf: "center",
                textAlign: "center",
                fontSize: 30,
                color: "red"
              }}
            />
          </div>
          <div className={classes.detail}>
            {
              "Ayudar a todos los profesionales que trabajan por tu seguridad y bienestar."
            }
          </div>
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <hr></hr>
          <span style={{ fontSize: 14 }}>
            * Para utilizar esta aplicación debes ser mayor de 14 años
          </span>
          <br></br>
          <br></br>
          <Button
            round
            fullWidth
            color="facebook"
            onClick={e => props.setStep("login")}
          >
            <b style={{ fontSize: 20 }}>Empezar</b>
          </Button>
        </GridItem>
      </GridContainer>
    </div >
  );
};

export default Step1;
