import React from "react";

import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { container } from "assets/jss/material-kit-react.js";

import News from "views/news";
//import Graphics from "views/graphics";
import Extras from "views/extras/Index";
import AutoTest from "views/auto-test";
import Main from "views/auto-test/Main";
import Admin from "views/admin/Index";
import Faq from "views/faq/Index";

import Quiz from "views/extras/Quiz";
import Links from "views/extras/Links";
import Suggestions from "views/extras/Suggestions";
import AboutUs from "views/about-us";

const dashboardRoutes = [];

const useStyles = makeStyles({
  container: {
    ...container,
    paddingTop: 100,
    minHeight: window.innerHeight - 80,
  },
  containerLanding: {
    ...container,
    paddingTop: 100,
    minHeight: window.innerHeight - 80,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: "fit-content"
  },

});

export default function Home(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const isLanding = () => {
    return window.location.pathname === "/";
  }

  return (
    <div>
      <Header
        color="rose"
        routes={dashboardRoutes}
        brand="Coronavirus"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div className={isLanding() ? classes.containerLanding : classes.container}
        style={{ maxWidth: isLanding() && "fit-content" }}>
        <Switch>
          <Route path="/extras/quiz" component={Quiz} />
          <Route path="/extras/links" component={Links} />
          <Route path="/extras/sugerencias" component={Suggestions} />

          <Route path="/noticias" component={News} />
          <Route path="/extras" component={Extras} />
          <Route path="/test" component={Main} />
          <Route path="/admin" component={Admin} />
          <Route path="/faq" component={Faq} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/" component={AutoTest} />
        </Switch>
      </div>
      <Footer />
    </div >
  );
}
