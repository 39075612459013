export const towns = [
    {
        "code": "25843",
        "name": "(Ubate) Villa De Sandiego De Ubate",
        "code_depar": "25000"
    },
    {
        "code": "5002",
        "name": "Abejorral",
        "code_depar": "5000"
    },
    {
        "code": "54003",
        "name": "Abrego",
        "code_depar": "54000"
    },
    {
        "code": "5004",
        "name": "Abriaqui",
        "code_depar": "5000"
    },
    {
        "code": "50006",
        "name": "Acacias",
        "code_depar": "50000"
    },
    {
        "code": "27006",
        "name": "Acandi",
        "code_depar": "27000"
    },
    {
        "code": "41006",
        "name": "Acevedo",
        "code_depar": "41000"
    },
    {
        "code": "13006",
        "name": "Achi",
        "code_depar": "13000"
    },
    {
        "code": "41013",
        "name": "Agrado",
        "code_depar": "41000"
    },
    {
        "code": "25001",
        "name": "Agua De Dios",
        "code_depar": "25000"
    },
    {
        "code": "20011",
        "name": "Aguachica",
        "code_depar": "20000"
    },
    {
        "code": "68013",
        "name": "Aguada",
        "code_depar": "68000"
    },
    {
        "code": "17013",
        "name": "Aguadas",
        "code_depar": "17000"
    },
    {
        "code": "85010",
        "name": "Aguazul",
        "code_depar": "85000"
    },
    {
        "code": "20013",
        "name": "Agustin Codazzi",
        "code_depar": "20000"
    },
    {
        "code": "41016",
        "name": "Aipe",
        "code_depar": "41000"
    },
    {
        "code": "25019",
        "name": "Alban",
        "code_depar": "25000"
    },
    {
        "code": "52019",
        "name": "Alban",
        "code_depar": "52000"
    },
    {
        "code": "68020",
        "name": "Albania",
        "code_depar": "68000"
    },
    {
        "code": "18029",
        "name": "Albania",
        "code_depar": "18000"
    },
    {
        "code": "44035",
        "name": "Albania",
        "code_depar": "44000"
    },
    {
        "code": "76020",
        "name": "Alcala",
        "code_depar": "76000"
    },
    {
        "code": "52022",
        "name": "Aldana",
        "code_depar": "52000"
    },
    {
        "code": "5021",
        "name": "Alejandria",
        "code_depar": "5000"
    },
    {
        "code": "47030",
        "name": "Algarrobo",
        "code_depar": "47000"
    },
    {
        "code": "41020",
        "name": "Algeciras",
        "code_depar": "41000"
    },
    {
        "code": "19022",
        "name": "Almaguer",
        "code_depar": "19000"
    },
    {
        "code": "15022",
        "name": "Almeida",
        "code_depar": "15000"
    },
    {
        "code": "73024",
        "name": "Alpujarra",
        "code_depar": "73000"
    },
    {
        "code": "5991",
        "name": "Altamira",
        "code_depar": "5000"
    },
    {
        "code": "41026",
        "name": "Altamira",
        "code_depar": "41000"
    },
    {
        "code": "27025",
        "name": "Alto Baudo",
        "code_depar": "27000"
    },
    {
        "code": "13030",
        "name": "Altos Del Rosario",
        "code_depar": "13000"
    },
    {
        "code": "73026",
        "name": "Alvarado",
        "code_depar": "73000"
    },
    {
        "code": "5030",
        "name": "Amaga",
        "code_depar": "5000"
    },
    {
        "code": "5031",
        "name": "Amalfi",
        "code_depar": "5000"
    },
    {
        "code": "73030",
        "name": "Ambalema",
        "code_depar": "73000"
    },
    {
        "code": "25035",
        "name": "Anapoima",
        "code_depar": "25000"
    },
    {
        "code": "52036",
        "name": "Ancuya",
        "code_depar": "52000"
    },
    {
        "code": "76036",
        "name": "Andalucia",
        "code_depar": "76000"
    },
    {
        "code": "5034",
        "name": "Andes",
        "code_depar": "5000"
    },
    {
        "code": "5036",
        "name": "Angelopolis",
        "code_depar": "5000"
    },
    {
        "code": "5038",
        "name": "Angostura",
        "code_depar": "5000"
    },
    {
        "code": "25040",
        "name": "Anolaima",
        "code_depar": "25000"
    },
    {
        "code": "5040",
        "name": "Anori",
        "code_depar": "5000"
    },
    {
        "code": "17042",
        "name": "Anserma",
        "code_depar": "17000"
    },
    {
        "code": "76041",
        "name": "Ansermanuevo",
        "code_depar": "76000"
    },
    {
        "code": "5044",
        "name": "Anza",
        "code_depar": "5000"
    },
    {
        "code": "73043",
        "name": "Anzoategui",
        "code_depar": "73000"
    },
    {
        "code": "5045",
        "name": "Apartado",
        "code_depar": "5000"
    },
    {
        "code": "66045",
        "name": "Apia",
        "code_depar": "66000"
    },
    {
        "code": "25599",
        "name": "Apulo",
        "code_depar": "25000"
    },
    {
        "code": "15047",
        "name": "Aquitania",
        "code_depar": "15000"
    },
    {
        "code": "47053",
        "name": "Aracataca",
        "code_depar": "47000"
    },
    {
        "code": "17050",
        "name": "Aranzazu",
        "code_depar": "17000"
    },
    {
        "code": "68051",
        "name": "Aratoca",
        "code_depar": "68000"
    },
    {
        "code": "81001",
        "name": "Arauca",
        "code_depar": "81000"
    },
    {
        "code": "17991",
        "name": "Arauca",
        "code_depar": "17000"
    },
    {
        "code": "81065",
        "name": "Arauquita",
        "code_depar": "81000"
    },
    {
        "code": "25053",
        "name": "Arbelaez",
        "code_depar": "25000"
    },
    {
        "code": "52051",
        "name": "Arboleda",
        "code_depar": "52000"
    },
    {
        "code": "17998",
        "name": "Arboleda",
        "code_depar": "17000"
    },
    {
        "code": "54051",
        "name": "Arboledas",
        "code_depar": "54000"
    },
    {
        "code": "5051",
        "name": "Arboletes",
        "code_depar": "5000"
    },
    {
        "code": "15051",
        "name": "Arcabuco",
        "code_depar": "15000"
    },
    {
        "code": "13042",
        "name": "Arenal",
        "code_depar": "13000"
    },
    {
        "code": "5055",
        "name": "Argelia",
        "code_depar": "5000"
    },
    {
        "code": "76054",
        "name": "Argelia",
        "code_depar": "76000"
    },
    {
        "code": "19050",
        "name": "Argelia",
        "code_depar": "19000"
    },
    {
        "code": "47058",
        "name": "Ariguani",
        "code_depar": "47000"
    },
    {
        "code": "13052",
        "name": "Arjona",
        "code_depar": "13000"
    },
    {
        "code": "5059",
        "name": "Armenia",
        "code_depar": "5000"
    },
    {
        "code": "63001",
        "name": "Armenia",
        "code_depar": "63000"
    },
    {
        "code": "73055",
        "name": "Armero",
        "code_depar": "73000"
    },
    {
        "code": "13062",
        "name": "Arroyohondo",
        "code_depar": "13000"
    },
    {
        "code": "20032",
        "name": "Astrea",
        "code_depar": "20000"
    },
    {
        "code": "73067",
        "name": "Ataco",
        "code_depar": "73000"
    },
    {
        "code": "27050",
        "name": "Atrato",
        "code_depar": "27000"
    },
    {
        "code": "23068",
        "name": "Ayapel",
        "code_depar": "23000"
    },
    {
        "code": "27073",
        "name": "Bagado",
        "code_depar": "27000"
    },
    {
        "code": "27075",
        "name": "Bahia Solano",
        "code_depar": "27000"
    },
    {
        "code": "27077",
        "name": "Bajo Baudo",
        "code_depar": "27000"
    },
    {
        "code": "27082",
        "name": "Bajo San Juan",
        "code_depar": "27000"
    },
    {
        "code": "66075",
        "name": "Balboa",
        "code_depar": "66000"
    },
    {
        "code": "19075",
        "name": "Balboa",
        "code_depar": "19000"
    },
    {
        "code": "8078",
        "name": "Baranoa",
        "code_depar": "8000"
    },
    {
        "code": "41078",
        "name": "Baraya",
        "code_depar": "41000"
    },
    {
        "code": "52079",
        "name": "Barbacoas",
        "code_depar": "52000"
    },
    {
        "code": "5079",
        "name": "Barbosa",
        "code_depar": "5000"
    },
    {
        "code": "68077",
        "name": "Barbosa",
        "code_depar": "68000"
    },
    {
        "code": "68079",
        "name": "Barichara",
        "code_depar": "68000"
    },
    {
        "code": "50110",
        "name": "Barranca De Upia",
        "code_depar": "50000"
    },
    {
        "code": "68081",
        "name": "Barrancabermeja",
        "code_depar": "68000"
    },
    {
        "code": "44078",
        "name": "Barrancas",
        "code_depar": "44000"
    },
    {
        "code": "13074",
        "name": "Barranco De Loba",
        "code_depar": "13000"
    },
    {
        "code": "94343",
        "name": "Barranco Minas",
        "code_depar": "94000"
    },
    {
        "code": "8001",
        "name": "Barranquilla",
        "code_depar": "8000"
    },
    {
        "code": "20045",
        "name": "Becerril",
        "code_depar": "20000"
    },
    {
        "code": "17088",
        "name": "Belalcazar",
        "code_depar": "17000"
    },
    {
        "code": "52083",
        "name": "Belen",
        "code_depar": "52000"
    },
    {
        "code": "15087",
        "name": "Belen",
        "code_depar": "15000"
    },
    {
        "code": "27086",
        "name": "Belen De Bajira",
        "code_depar": "27000"
    },
    {
        "code": "18094",
        "name": "Belen De Los Andaqui",
        "code_depar": "18000"
    },
    {
        "code": "66088",
        "name": "Belen De Umbria",
        "code_depar": "66000"
    },
    {
        "code": "5088",
        "name": "Bello",
        "code_depar": "5000"
    },
    {
        "code": "5086",
        "name": "Belmira",
        "code_depar": "5000"
    },
    {
        "code": "25086",
        "name": "Beltran",
        "code_depar": "25000"
    },
    {
        "code": "15090",
        "name": "Berbeo",
        "code_depar": "15000"
    },
    {
        "code": "5091",
        "name": "Betania",
        "code_depar": "5000"
    },
    {
        "code": "15092",
        "name": "Beteitiva",
        "code_depar": "15000"
    },
    {
        "code": "5093",
        "name": "Betulia",
        "code_depar": "5000"
    },
    {
        "code": "68092",
        "name": "Betulia",
        "code_depar": "68000"
    },
    {
        "code": "25095",
        "name": "Bituima",
        "code_depar": "25000"
    },
    {
        "code": "15097",
        "name": "Boavita",
        "code_depar": "15000"
    },
    {
        "code": "54099",
        "name": "Bochalema",
        "code_depar": "54000"
    },
    {
        "code": "11001",
        "name": "Bogotá D.C",
        "code_depar": "11000"
    },
    {
        "code": "25099",
        "name": "Bojaca",
        "code_depar": "25000"
    },
    {
        "code": "27099",
        "name": "Bojaya",
        "code_depar": "27000"
    },
    {
        "code": "68101",
        "name": "Bolivar",
        "code_depar": "68000"
    },
    {
        "code": "5101",
        "name": "Bolivar",
        "code_depar": "5000"
    },
    {
        "code": "76100",
        "name": "Bolivar",
        "code_depar": "76000"
    },
    {
        "code": "19100",
        "name": "Bolivar",
        "code_depar": "19000"
    },
    {
        "code": "17992",
        "name": "Bolivia",
        "code_depar": "17000"
    },
    {
        "code": "20060",
        "name": "Bosconia",
        "code_depar": "20000"
    },
    {
        "code": "15104",
        "name": "Boyaca",
        "code_depar": "15000"
    },
    {
        "code": "15106",
        "name": "Briceno",
        "code_depar": "15000"
    },
    {
        "code": "5107",
        "name": "Briceño",
        "code_depar": "5000"
    },
    {
        "code": "68001",
        "name": "Bucaramanga",
        "code_depar": "68000"
    },
    {
        "code": "54109",
        "name": "Bucarasica",
        "code_depar": "54000"
    },
    {
        "code": "76109",
        "name": "Buenaventura",
        "code_depar": "76000"
    },
    {
        "code": "63111",
        "name": "Buenavista",
        "code_depar": "63000"
    },
    {
        "code": "23079",
        "name": "Buenavista",
        "code_depar": "23000"
    },
    {
        "code": "15109",
        "name": "Buenavista",
        "code_depar": "15000"
    },
    {
        "code": "70110",
        "name": "Buenavista",
        "code_depar": "70000"
    },
    {
        "code": "19110",
        "name": "Buenos Aires",
        "code_depar": "19000"
    },
    {
        "code": "52110",
        "name": "Buesaco",
        "code_depar": "52000"
    },
    {
        "code": "76113",
        "name": "Bugalagrande",
        "code_depar": "76000"
    },
    {
        "code": "5113",
        "name": "Buritica",
        "code_depar": "5000"
    },
    {
        "code": "15114",
        "name": "Busbanza",
        "code_depar": "15000"
    },
    {
        "code": "25120",
        "name": "Cabrera",
        "code_depar": "25000"
    },
    {
        "code": "68121",
        "name": "Cabrera",
        "code_depar": "68000"
    },
    {
        "code": "50124",
        "name": "Cabuyaro",
        "code_depar": "50000"
    },
    {
        "code": "94886",
        "name": "Cacahual",
        "code_depar": "94000"
    },
    {
        "code": "5120",
        "name": "Caceres",
        "code_depar": "5000"
    },
    {
        "code": "25123",
        "name": "Cachipay",
        "code_depar": "25000"
    },
    {
        "code": "54128",
        "name": "Cachira",
        "code_depar": "54000"
    },
    {
        "code": "54125",
        "name": "Cacota",
        "code_depar": "54000"
    },
    {
        "code": "5125",
        "name": "Caicedo",
        "code_depar": "5000"
    },
    {
        "code": "76122",
        "name": "Caicedonia",
        "code_depar": "76000"
    },
    {
        "code": "70124",
        "name": "Caimito",
        "code_depar": "70000"
    },
    {
        "code": "73124",
        "name": "Cajamarca",
        "code_depar": "73000"
    },
    {
        "code": "19130",
        "name": "Cajibio",
        "code_depar": "19000"
    },
    {
        "code": "25126",
        "name": "Cajica",
        "code_depar": "25000"
    },
    {
        "code": "13140",
        "name": "Calamar",
        "code_depar": "13000"
    },
    {
        "code": "95015",
        "name": "Calamar",
        "code_depar": "95000"
    },
    {
        "code": "63130",
        "name": "Calarca",
        "code_depar": "63000"
    },
    {
        "code": "5129",
        "name": "Caldas",
        "code_depar": "5000"
    },
    {
        "code": "15131",
        "name": "Caldas",
        "code_depar": "15000"
    },
    {
        "code": "19137",
        "name": "Caldono",
        "code_depar": "19000"
    },
    {
        "code": "76001",
        "name": "Cali",
        "code_depar": "76000"
    },
    {
        "code": "68132",
        "name": "California",
        "code_depar": "68000"
    },
    {
        "code": "76126",
        "name": "Calima",
        "code_depar": "76000"
    },
    {
        "code": "19142",
        "name": "Caloto",
        "code_depar": "19000"
    },
    {
        "code": "5134",
        "name": "Campamento",
        "code_depar": "5000"
    },
    {
        "code": "8137",
        "name": "Campo De La Cruz",
        "code_depar": "8000"
    },
    {
        "code": "41132",
        "name": "Campoalegre",
        "code_depar": "41000"
    },
    {
        "code": "15135",
        "name": "Campohermoso",
        "code_depar": "15000"
    },
    {
        "code": "23090",
        "name": "Canalete",
        "code_depar": "23000"
    },
    {
        "code": "5138",
        "name": "Cañasgordas",
        "code_depar": "5000"
    },
    {
        "code": "8141",
        "name": "Candelaria",
        "code_depar": "8000"
    },
    {
        "code": "76130",
        "name": "Candelaria",
        "code_depar": "76000"
    },
    {
        "code": "13160",
        "name": "Cantagallo",
        "code_depar": "13000"
    },
    {
        "code": "25148",
        "name": "Caparrapi",
        "code_depar": "25000"
    },
    {
        "code": "68147",
        "name": "Capitanejo",
        "code_depar": "68000"
    },
    {
        "code": "25151",
        "name": "Caqueza",
        "code_depar": "25000"
    },
    {
        "code": "5142",
        "name": "Caracoli",
        "code_depar": "5000"
    },
    {
        "code": "5145",
        "name": "Caramanta",
        "code_depar": "5000"
    },
    {
        "code": "68152",
        "name": "Carcasi",
        "code_depar": "68000"
    },
    {
        "code": "5147",
        "name": "Carepa",
        "code_depar": "5000"
    },
    {
        "code": "73148",
        "name": "Carmen De Apicala",
        "code_depar": "73000"
    },
    {
        "code": "25154",
        "name": "Carmen De Carupa",
        "code_depar": "25000"
    },
    {
        "code": "5148",
        "name": "Carmen De Viboral",
        "code_depar": "5000"
    },
    {
        "code": "27150",
        "name": "Carmen Del Darien",
        "code_depar": "27000"
    },
    {
        "code": "5150",
        "name": "Carolina",
        "code_depar": "5000"
    },
    {
        "code": "13001",
        "name": "Cartagena",
        "code_depar": "13000"
    },
    {
        "code": "18150",
        "name": "Cartagena Del Chaira",
        "code_depar": "18000"
    },
    {
        "code": "76147",
        "name": "Cartago",
        "code_depar": "76000"
    },
    {
        "code": "97161",
        "name": "Caruru",
        "code_depar": "97000"
    },
    {
        "code": "73152",
        "name": "Casabianca",
        "code_depar": "73000"
    },
    {
        "code": "50150",
        "name": "Castilla La Nueva",
        "code_depar": "50000"
    },
    {
        "code": "5154",
        "name": "Caucasia",
        "code_depar": "5000"
    },
    {
        "code": "68160",
        "name": "Cepita",
        "code_depar": "68000"
    },
    {
        "code": "23162",
        "name": "Cerete",
        "code_depar": "23000"
    },
    {
        "code": "15162",
        "name": "Cerinza",
        "code_depar": "15000"
    },
    {
        "code": "68162",
        "name": "Cerrito",
        "code_depar": "68000"
    },
    {
        "code": "47161",
        "name": "Cerro San Antonio",
        "code_depar": "47000"
    },
    {
        "code": "27160",
        "name": "Certegui",
        "code_depar": "27000"
    },
    {
        "code": "52240",
        "name": "Chachagüí",
        "code_depar": "52000"
    },
    {
        "code": "25168",
        "name": "Chaguani",
        "code_depar": "25000"
    },
    {
        "code": "70230",
        "name": "Chalan",
        "code_depar": "70000"
    },
    {
        "code": "85015",
        "name": "Chameza",
        "code_depar": "85000"
    },
    {
        "code": "73168",
        "name": "Chaparral",
        "code_depar": "73000"
    },
    {
        "code": "68167",
        "name": "Charala",
        "code_depar": "68000"
    },
    {
        "code": "68169",
        "name": "Charta",
        "code_depar": "68000"
    },
    {
        "code": "25175",
        "name": "Chia",
        "code_depar": "25000"
    },
    {
        "code": "5172",
        "name": "Chigorodo",
        "code_depar": "5000"
    },
    {
        "code": "68176",
        "name": "Chima",
        "code_depar": "68000"
    },
    {
        "code": "23168",
        "name": "Chima",
        "code_depar": "23000"
    },
    {
        "code": "20175",
        "name": "Chimichagua",
        "code_depar": "20000"
    },
    {
        "code": "54172",
        "name": "Chinacota",
        "code_depar": "54000"
    },
    {
        "code": "15172",
        "name": "Chinavita",
        "code_depar": "15000"
    },
    {
        "code": "17174",
        "name": "Chinchina",
        "code_depar": "17000"
    },
    {
        "code": "23182",
        "name": "Chinu",
        "code_depar": "23000"
    },
    {
        "code": "25178",
        "name": "Chipaque",
        "code_depar": "25000"
    },
    {
        "code": "68179",
        "name": "Chipata",
        "code_depar": "68000"
    },
    {
        "code": "15176",
        "name": "Chiquinquira",
        "code_depar": "15000"
    },
    {
        "code": "15232",
        "name": "Chiquiza",
        "code_depar": "15000"
    },
    {
        "code": "20178",
        "name": "Chiriguana",
        "code_depar": "20000"
    },
    {
        "code": "15180",
        "name": "Chiscas",
        "code_depar": "15000"
    },
    {
        "code": "15183",
        "name": "Chita",
        "code_depar": "15000"
    },
    {
        "code": "54174",
        "name": "Chitaga",
        "code_depar": "54000"
    },
    {
        "code": "15185",
        "name": "Chitaraque",
        "code_depar": "15000"
    },
    {
        "code": "15187",
        "name": "Chivata",
        "code_depar": "15000"
    },
    {
        "code": "47170",
        "name": "Chivolo",
        "code_depar": "47000"
    },
    {
        "code": "15236",
        "name": "Chivor",
        "code_depar": "15000"
    },
    {
        "code": "25181",
        "name": "Choachi",
        "code_depar": "25000"
    },
    {
        "code": "25183",
        "name": "Choconta",
        "code_depar": "25000"
    },
    {
        "code": "13188",
        "name": "Cicuco",
        "code_depar": "13000"
    },
    {
        "code": "47189",
        "name": "Cienaga",
        "code_depar": "47000"
    },
    {
        "code": "23189",
        "name": "Cienaga De Oro",
        "code_depar": "23000"
    },
    {
        "code": "15189",
        "name": "Cienega",
        "code_depar": "15000"
    },
    {
        "code": "68190",
        "name": "Cimitarra",
        "code_depar": "68000"
    },
    {
        "code": "63190",
        "name": "Circasia",
        "code_depar": "63000"
    },
    {
        "code": "5190",
        "name": "Cisneros",
        "code_depar": "5000"
    },
    {
        "code": "13222",
        "name": "Clemencia",
        "code_depar": "13000"
    },
    {
        "code": "5197",
        "name": "Cocorna",
        "code_depar": "5000"
    },
    {
        "code": "73200",
        "name": "Coello",
        "code_depar": "73000"
    },
    {
        "code": "25200",
        "name": "Cogua",
        "code_depar": "25000"
    },
    {
        "code": "41206",
        "name": "Colombia",
        "code_depar": "41000"
    },
    {
        "code": "52203",
        "name": "Colon",
        "code_depar": "52000"
    },
    {
        "code": "86219",
        "name": "Colon",
        "code_depar": "86000"
    },
    {
        "code": "70204",
        "name": "Coloso",
        "code_depar": "70000"
    },
    {
        "code": "15204",
        "name": "Combita",
        "code_depar": "15000"
    },
    {
        "code": "5206",
        "name": "Concepcion",
        "code_depar": "5000"
    },
    {
        "code": "68207",
        "name": "Concepcion",
        "code_depar": "68000"
    },
    {
        "code": "5209",
        "name": "Concordia",
        "code_depar": "5000"
    },
    {
        "code": "47205",
        "name": "Concordia",
        "code_depar": "47000"
    },
    {
        "code": "27205",
        "name": "Condoto",
        "code_depar": "27000"
    },
    {
        "code": "68209",
        "name": "Confines",
        "code_depar": "68000"
    },
    {
        "code": "52207",
        "name": "Consaca",
        "code_depar": "52000"
    },
    {
        "code": "52210",
        "name": "Contadero",
        "code_depar": "52000"
    },
    {
        "code": "68211",
        "name": "Contratacion",
        "code_depar": "68000"
    },
    {
        "code": "54206",
        "name": "Convencion",
        "code_depar": "54000"
    },
    {
        "code": "5212",
        "name": "Copacabana",
        "code_depar": "5000"
    },
    {
        "code": "15212",
        "name": "Coper",
        "code_depar": "15000"
    },
    {
        "code": "13212",
        "name": "Cordoba",
        "code_depar": "13000"
    },
    {
        "code": "52215",
        "name": "Cordoba",
        "code_depar": "52000"
    },
    {
        "code": "63212",
        "name": "Cordoba",
        "code_depar": "63000"
    },
    {
        "code": "19212",
        "name": "Corinto",
        "code_depar": "19000"
    },
    {
        "code": "68217",
        "name": "Coromoro",
        "code_depar": "68000"
    },
    {
        "code": "70215",
        "name": "Corozal",
        "code_depar": "70000"
    },
    {
        "code": "15215",
        "name": "Corrales",
        "code_depar": "15000"
    },
    {
        "code": "25214",
        "name": "Cota",
        "code_depar": "25000"
    },
    {
        "code": "23300",
        "name": "Cotorra",
        "code_depar": "23000"
    },
    {
        "code": "15218",
        "name": "Covarachia",
        "code_depar": "15000"
    },
    {
        "code": "70221",
        "name": "Coveñas",
        "code_depar": "70000"
    },
    {
        "code": "73217",
        "name": "Coyaima",
        "code_depar": "73000"
    },
    {
        "code": "81220",
        "name": "Cravo Norte",
        "code_depar": "81000"
    },
    {
        "code": "52224",
        "name": "Cuaspud",
        "code_depar": "52000"
    },
    {
        "code": "15223",
        "name": "Cubara",
        "code_depar": "15000"
    },
    {
        "code": "50223",
        "name": "Cubarral",
        "code_depar": "50000"
    },
    {
        "code": "15224",
        "name": "Cucaita",
        "code_depar": "15000"
    },
    {
        "code": "25224",
        "name": "Cucunuba",
        "code_depar": "25000"
    },
    {
        "code": "54001",
        "name": "Cucuta",
        "code_depar": "54000"
    },
    {
        "code": "54223",
        "name": "Cucutilla",
        "code_depar": "54000"
    },
    {
        "code": "15226",
        "name": "Cuitiva",
        "code_depar": "15000"
    },
    {
        "code": "50226",
        "name": "Cumaral",
        "code_depar": "50000"
    },
    {
        "code": "99773",
        "name": "Cumaribo",
        "code_depar": "99000"
    },
    {
        "code": "52227",
        "name": "Cumbal",
        "code_depar": "52000"
    },
    {
        "code": "52233",
        "name": "Cumbitara",
        "code_depar": "52000"
    },
    {
        "code": "73226",
        "name": "Cunday",
        "code_depar": "73000"
    },
    {
        "code": "18205",
        "name": "Curillo",
        "code_depar": "18000"
    },
    {
        "code": "68229",
        "name": "Curiti",
        "code_depar": "68000"
    },
    {
        "code": "20228",
        "name": "Curumani",
        "code_depar": "20000"
    },
    {
        "code": "5234",
        "name": "Dabeiba",
        "code_depar": "5000"
    },
    {
        "code": "76233",
        "name": "Dagua",
        "code_depar": "76000"
    },
    {
        "code": "44090",
        "name": "Dibulla",
        "code_depar": "44000"
    },
    {
        "code": "44098",
        "name": "Distracción",
        "code_depar": "44000"
    },
    {
        "code": "73236",
        "name": "Dolores",
        "code_depar": "73000"
    },
    {
        "code": "5237",
        "name": "Don Matias",
        "code_depar": "5000"
    },
    {
        "code": "66170",
        "name": "Dos Quebradas",
        "code_depar": "66000"
    },
    {
        "code": "15238",
        "name": "Duitama",
        "code_depar": "15000"
    },
    {
        "code": "54239",
        "name": "Durania",
        "code_depar": "54000"
    },
    {
        "code": "5240",
        "name": "Ebejico",
        "code_depar": "5000"
    },
    {
        "code": "76243",
        "name": "El Aguila",
        "code_depar": "76000"
    },
    {
        "code": "5250",
        "name": "El Bagre",
        "code_depar": "5000"
    },
    {
        "code": "47245",
        "name": "El Banco",
        "code_depar": "47000"
    },
    {
        "code": "76246",
        "name": "El Cairo",
        "code_depar": "76000"
    },
    {
        "code": "50245",
        "name": "El Calvario",
        "code_depar": "50000"
    },
    {
        "code": "27135",
        "name": "El Canton De San Pablo",
        "code_depar": "27000"
    },
    {
        "code": "54245",
        "name": "El Carmen",
        "code_depar": "54000"
    },
    {
        "code": "68235",
        "name": "El Carmen",
        "code_depar": "68000"
    },
    {
        "code": "27245",
        "name": "El Carmen De Atrato",
        "code_depar": "27000"
    },
    {
        "code": "13244",
        "name": "El Carmen De Bolivar",
        "code_depar": "13000"
    },
    {
        "code": "50251",
        "name": "El Castillo",
        "code_depar": "50000"
    },
    {
        "code": "76248",
        "name": "El Cerrito",
        "code_depar": "76000"
    },
    {
        "code": "52250",
        "name": "El Charco",
        "code_depar": "52000"
    },
    {
        "code": "15244",
        "name": "El Cocuy",
        "code_depar": "15000"
    },
    {
        "code": "25245",
        "name": "El Colegio",
        "code_depar": "25000"
    },
    {
        "code": "20238",
        "name": "El Copey",
        "code_depar": "20000"
    },
    {
        "code": "18247",
        "name": "El Doncello",
        "code_depar": "18000"
    },
    {
        "code": "50270",
        "name": "El Dorado",
        "code_depar": "50000"
    },
    {
        "code": "76250",
        "name": "El Dovio",
        "code_depar": "76000"
    },
    {
        "code": "91263",
        "name": "El Encanto",
        "code_depar": "91000"
    },
    {
        "code": "15248",
        "name": "El Espino",
        "code_depar": "15000"
    },
    {
        "code": "68245",
        "name": "El Guacamayo",
        "code_depar": "68000"
    },
    {
        "code": "13248",
        "name": "El Guamo",
        "code_depar": "13000"
    },
    {
        "code": "27250",
        "name": "El Litoral Del San Juan",
        "code_depar": "27000"
    },
    {
        "code": "44110",
        "name": "El Molino",
        "code_depar": "44000"
    },
    {
        "code": "20250",
        "name": "El Paso",
        "code_depar": "20000"
    },
    {
        "code": "18256",
        "name": "El Paujil",
        "code_depar": "18000"
    },
    {
        "code": "52254",
        "name": "El Peñol",
        "code_depar": "52000"
    },
    {
        "code": "25258",
        "name": "El Penon",
        "code_depar": "25000"
    },
    {
        "code": "13268",
        "name": "El Peñon",
        "code_depar": "13000"
    },
    {
        "code": "68250",
        "name": "El Peñón",
        "code_depar": "68000"
    },
    {
        "code": "47258",
        "name": "El Piñon",
        "code_depar": "47000"
    },
    {
        "code": "68255",
        "name": "El Playon",
        "code_depar": "68000"
    },
    {
        "code": "47268",
        "name": "El Retén",
        "code_depar": "47000"
    },
    {
        "code": "95025",
        "name": "El Retorno",
        "code_depar": "95000"
    },
    {
        "code": "70233",
        "name": "El Roble",
        "code_depar": "70000"
    },
    {
        "code": "25260",
        "name": "El Rosal",
        "code_depar": "25000"
    },
    {
        "code": "52256",
        "name": "El Rosario",
        "code_depar": "52000"
    },
    {
        "code": "5697",
        "name": "El Santuario",
        "code_depar": "5000"
    },
    {
        "code": "52258",
        "name": "El Tablon De Gomez",
        "code_depar": "52000"
    },
    {
        "code": "52260",
        "name": "El Tambo",
        "code_depar": "52000"
    },
    {
        "code": "19256",
        "name": "El Tambo",
        "code_depar": "19000"
    },
    {
        "code": "54250",
        "name": "El Tarra",
        "code_depar": "54000"
    },
    {
        "code": "54261",
        "name": "El Zulia",
        "code_depar": "54000"
    },
    {
        "code": "41244",
        "name": "Elias",
        "code_depar": "41000"
    },
    {
        "code": "68264",
        "name": "Encino",
        "code_depar": "68000"
    },
    {
        "code": "68266",
        "name": "Enciso",
        "code_depar": "68000"
    },
    {
        "code": "5264",
        "name": "Entrerrios",
        "code_depar": "5000"
    },
    {
        "code": "5266",
        "name": "Envigado",
        "code_depar": "5000"
    },
    {
        "code": "73268",
        "name": "Espinal",
        "code_depar": "73000"
    },
    {
        "code": "25269",
        "name": "Facatativa",
        "code_depar": "25000"
    },
    {
        "code": "73270",
        "name": "Falan",
        "code_depar": "73000"
    },
    {
        "code": "17272",
        "name": "Filadelfia",
        "code_depar": "17000"
    },
    {
        "code": "63272",
        "name": "Filandia",
        "code_depar": "63000"
    },
    {
        "code": "15272",
        "name": "Firavitoba",
        "code_depar": "15000"
    },
    {
        "code": "73275",
        "name": "Flandes",
        "code_depar": "73000"
    },
    {
        "code": "19290",
        "name": "Florencia",
        "code_depar": "19000"
    },
    {
        "code": "18001",
        "name": "Florencia",
        "code_depar": "18000"
    },
    {
        "code": "17993",
        "name": "Florencia",
        "code_depar": "17000"
    },
    {
        "code": "15276",
        "name": "Floresta",
        "code_depar": "15000"
    },
    {
        "code": "68271",
        "name": "Florian",
        "code_depar": "68000"
    },
    {
        "code": "76275",
        "name": "Florida",
        "code_depar": "76000"
    },
    {
        "code": "68276",
        "name": "Floridablanca",
        "code_depar": "68000"
    },
    {
        "code": "25279",
        "name": "Fomeque",
        "code_depar": "25000"
    },
    {
        "code": "44279",
        "name": "Fonseca",
        "code_depar": "44000"
    },
    {
        "code": "81300",
        "name": "Fortul",
        "code_depar": "81000"
    },
    {
        "code": "25281",
        "name": "Fosca",
        "code_depar": "25000"
    },
    {
        "code": "52520",
        "name": "Francisco Pizarro",
        "code_depar": "52000"
    },
    {
        "code": "5282",
        "name": "Fredonia",
        "code_depar": "5000"
    },
    {
        "code": "73283",
        "name": "Fresno",
        "code_depar": "73000"
    },
    {
        "code": "5284",
        "name": "Frontino",
        "code_depar": "5000"
    },
    {
        "code": "50287",
        "name": "Fuente De Oro",
        "code_depar": "50000"
    },
    {
        "code": "47288",
        "name": "Fundacion",
        "code_depar": "47000"
    },
    {
        "code": "52287",
        "name": "Funes",
        "code_depar": "52000"
    },
    {
        "code": "25286",
        "name": "Funza",
        "code_depar": "25000"
    },
    {
        "code": "25288",
        "name": "Fuquene",
        "code_depar": "25000"
    },
    {
        "code": "25290",
        "name": "Fusagasuga",
        "code_depar": "25000"
    },
    {
        "code": "25293",
        "name": "Gachala",
        "code_depar": "25000"
    },
    {
        "code": "25295",
        "name": "Gachancipa",
        "code_depar": "25000"
    },
    {
        "code": "15293",
        "name": "Gachantiva",
        "code_depar": "15000"
    },
    {
        "code": "25297",
        "name": "Gacheta",
        "code_depar": "25000"
    },
    {
        "code": "68296",
        "name": "Galan",
        "code_depar": "68000"
    },
    {
        "code": "8296",
        "name": "Galapa",
        "code_depar": "8000"
    },
    {
        "code": "70235",
        "name": "Galeras",
        "code_depar": "70000"
    },
    {
        "code": "25299",
        "name": "Gama",
        "code_depar": "25000"
    },
    {
        "code": "20295",
        "name": "Gamarra",
        "code_depar": "20000"
    },
    {
        "code": "68298",
        "name": "Gambita",
        "code_depar": "68000"
    },
    {
        "code": "15296",
        "name": "Gameza",
        "code_depar": "15000"
    },
    {
        "code": "15299",
        "name": "Garagoa",
        "code_depar": "15000"
    },
    {
        "code": "41298",
        "name": "Garzon",
        "code_depar": "41000"
    },
    {
        "code": "63302",
        "name": "Genova",
        "code_depar": "63000"
    },
    {
        "code": "41306",
        "name": "Gigante",
        "code_depar": "41000"
    },
    {
        "code": "76306",
        "name": "Ginebra",
        "code_depar": "76000"
    },
    {
        "code": "5306",
        "name": "Giraldo",
        "code_depar": "5000"
    },
    {
        "code": "25307",
        "name": "Girardot",
        "code_depar": "25000"
    },
    {
        "code": "5308",
        "name": "Girardota",
        "code_depar": "5000"
    },
    {
        "code": "68307",
        "name": "Giron",
        "code_depar": "68000"
    },
    {
        "code": "5310",
        "name": "Gomez Plata",
        "code_depar": "5000"
    },
    {
        "code": "20310",
        "name": "Gonzalez",
        "code_depar": "20000"
    },
    {
        "code": "54313",
        "name": "Gramalote",
        "code_depar": "54000"
    },
    {
        "code": "5313",
        "name": "Granada",
        "code_depar": "5000"
    },
    {
        "code": "25312",
        "name": "Granada",
        "code_depar": "25000"
    },
    {
        "code": "50313",
        "name": "Granada",
        "code_depar": "50000"
    },
    {
        "code": "68318",
        "name": "Guaca",
        "code_depar": "68000"
    },
    {
        "code": "15317",
        "name": "Guacamayas",
        "code_depar": "15000"
    },
    {
        "code": "76318",
        "name": "Guacari",
        "code_depar": "76000"
    },
    {
        "code": "19300",
        "name": "Guachené",
        "code_depar": "19000"
    },
    {
        "code": "25317",
        "name": "Guacheta",
        "code_depar": "25000"
    },
    {
        "code": "52317",
        "name": "Guachucal",
        "code_depar": "52000"
    },
    {
        "code": "76111",
        "name": "Guadalajara De Buga (Buga)",
        "code_depar": "76000"
    },
    {
        "code": "5315",
        "name": "Guadalupe",
        "code_depar": "5000"
    },
    {
        "code": "41319",
        "name": "Guadalupe",
        "code_depar": "41000"
    },
    {
        "code": "68320",
        "name": "Guadalupe",
        "code_depar": "68000"
    },
    {
        "code": "25320",
        "name": "Guaduas",
        "code_depar": "25000"
    },
    {
        "code": "52320",
        "name": "Guaitarilla",
        "code_depar": "52000"
    },
    {
        "code": "52323",
        "name": "Gualmatan",
        "code_depar": "52000"
    },
    {
        "code": "50318",
        "name": "Guamal",
        "code_depar": "50000"
    },
    {
        "code": "47318",
        "name": "Guamal",
        "code_depar": "47000"
    },
    {
        "code": "73319",
        "name": "Guamo",
        "code_depar": "73000"
    },
    {
        "code": "19318",
        "name": "Guapi",
        "code_depar": "19000"
    },
    {
        "code": "68322",
        "name": "Guapota",
        "code_depar": "68000"
    },
    {
        "code": "70265",
        "name": "Guaranda",
        "code_depar": "70000"
    },
    {
        "code": "5318",
        "name": "Guarne",
        "code_depar": "5000"
    },
    {
        "code": "25322",
        "name": "Guasca",
        "code_depar": "25000"
    },
    {
        "code": "5321",
        "name": "Guatape",
        "code_depar": "5000"
    },
    {
        "code": "25324",
        "name": "Guataqui",
        "code_depar": "25000"
    },
    {
        "code": "25326",
        "name": "Guatavita",
        "code_depar": "25000"
    },
    {
        "code": "15322",
        "name": "Guateque",
        "code_depar": "15000"
    },
    {
        "code": "66318",
        "name": "Guatica",
        "code_depar": "66000"
    },
    {
        "code": "68324",
        "name": "Guavata",
        "code_depar": "68000"
    },
    {
        "code": "25328",
        "name": "Guayabal De Siquima",
        "code_depar": "25000"
    },
    {
        "code": "25335",
        "name": "Guayabetal",
        "code_depar": "25000"
    },
    {
        "code": "15325",
        "name": "Guayata",
        "code_depar": "15000"
    },
    {
        "code": "68327",
        "name": "Guepsa",
        "code_depar": "68000"
    },
    {
        "code": "15332",
        "name": "Guican",
        "code_depar": "15000"
    },
    {
        "code": "25339",
        "name": "Gutierrez",
        "code_depar": "25000"
    },
    {
        "code": "54344",
        "name": "Hacari",
        "code_depar": "54000"
    },
    {
        "code": "13300",
        "name": "Hatillo De Loba",
        "code_depar": "13000"
    },
    {
        "code": "68344",
        "name": "Hato",
        "code_depar": "68000"
    },
    {
        "code": "85125",
        "name": "Hato Corozal",
        "code_depar": "85000"
    },
    {
        "code": "44378",
        "name": "Hato Nuevo",
        "code_depar": "44000"
    },
    {
        "code": "5347",
        "name": "Heliconia",
        "code_depar": "5000"
    },
    {
        "code": "54347",
        "name": "Herran",
        "code_depar": "54000"
    },
    {
        "code": "73993",
        "name": "Herrera",
        "code_depar": "73000"
    },
    {
        "code": "73347",
        "name": "Herveo",
        "code_depar": "73000"
    },
    {
        "code": "5353",
        "name": "Hispania",
        "code_depar": "5000"
    },
    {
        "code": "41349",
        "name": "Hobo",
        "code_depar": "41000"
    },
    {
        "code": "73349",
        "name": "Honda",
        "code_depar": "73000"
    },
    {
        "code": "73001",
        "name": "Ibague",
        "code_depar": "73000"
    },
    {
        "code": "73352",
        "name": "Icononzo",
        "code_depar": "73000"
    },
    {
        "code": "52352",
        "name": "Iles",
        "code_depar": "52000"
    },
    {
        "code": "52354",
        "name": "Imues",
        "code_depar": "52000"
    },
    {
        "code": "94001",
        "name": "Inirida",
        "code_depar": "94000"
    },
    {
        "code": "19355",
        "name": "Inza",
        "code_depar": "19000"
    },
    {
        "code": "52356",
        "name": "Ipiales",
        "code_depar": "52000"
    },
    {
        "code": "41357",
        "name": "Iquira",
        "code_depar": "41000"
    },
    {
        "code": "41359",
        "name": "Isnos",
        "code_depar": "41000"
    },
    {
        "code": "5360",
        "name": "Itagui",
        "code_depar": "5000"
    },
    {
        "code": "27361",
        "name": "Itsmina",
        "code_depar": "27000"
    },
    {
        "code": "5361",
        "name": "Ituango",
        "code_depar": "5000"
    },
    {
        "code": "15362",
        "name": "Iza",
        "code_depar": "15000"
    },
    {
        "code": "19364",
        "name": "Jambalo",
        "code_depar": "19000"
    },
    {
        "code": "76364",
        "name": "Jamundi",
        "code_depar": "76000"
    },
    {
        "code": "5364",
        "name": "Jardin",
        "code_depar": "5000"
    },
    {
        "code": "15367",
        "name": "Jenesano",
        "code_depar": "15000"
    },
    {
        "code": "5368",
        "name": "Jerico",
        "code_depar": "5000"
    },
    {
        "code": "15368",
        "name": "Jerico",
        "code_depar": "15000"
    },
    {
        "code": "25368",
        "name": "Jerusalen",
        "code_depar": "25000"
    },
    {
        "code": "68368",
        "name": "Jesus Maria",
        "code_depar": "68000"
    },
    {
        "code": "68370",
        "name": "Jordan",
        "code_depar": "68000"
    },
    {
        "code": "8372",
        "name": "Juan De Acosta",
        "code_depar": "8000"
    },
    {
        "code": "25372",
        "name": "Junin",
        "code_depar": "25000"
    },
    {
        "code": "27372",
        "name": "Jurado",
        "code_depar": "27000"
    },
    {
        "code": "23350",
        "name": "La Apartada",
        "code_depar": "23000"
    },
    {
        "code": "41378",
        "name": "La Argentina",
        "code_depar": "41000"
    },
    {
        "code": "68377",
        "name": "La Belleza",
        "code_depar": "68000"
    },
    {
        "code": "25377",
        "name": "La Calera",
        "code_depar": "25000"
    },
    {
        "code": "15380",
        "name": "La Capilla",
        "code_depar": "15000"
    },
    {
        "code": "5376",
        "name": "La Ceja",
        "code_depar": "5000"
    },
    {
        "code": "66383",
        "name": "La Celia",
        "code_depar": "66000"
    },
    {
        "code": "91405",
        "name": "La Chorrera",
        "code_depar": "91000"
    },
    {
        "code": "52378",
        "name": "La Cruz",
        "code_depar": "52000"
    },
    {
        "code": "76377",
        "name": "La Cumbre",
        "code_depar": "76000"
    },
    {
        "code": "17380",
        "name": "La Dorada",
        "code_depar": "17000"
    },
    {
        "code": "54385",
        "name": "La Esperanza",
        "code_depar": "54000"
    },
    {
        "code": "5380",
        "name": "La Estrella",
        "code_depar": "5000"
    },
    {
        "code": "52381",
        "name": "La Florida",
        "code_depar": "52000"
    },
    {
        "code": "20383",
        "name": "La Gloria",
        "code_depar": "20000"
    },
    {
        "code": "94885",
        "name": "La Guadalupe",
        "code_depar": "94000"
    },
    {
        "code": "20400",
        "name": "La Jagua De Ibirico",
        "code_depar": "20000"
    },
    {
        "code": "44420",
        "name": "La Jagua Del Pilar",
        "code_depar": "44000"
    },
    {
        "code": "52385",
        "name": "La Llanada",
        "code_depar": "52000"
    },
    {
        "code": "50350",
        "name": "La Macarena",
        "code_depar": "50000"
    },
    {
        "code": "17388",
        "name": "La Merced",
        "code_depar": "17000"
    },
    {
        "code": "25386",
        "name": "La Mesa",
        "code_depar": "25000"
    },
    {
        "code": "18410",
        "name": "La Montañita",
        "code_depar": "18000"
    },
    {
        "code": "25394",
        "name": "La Palma",
        "code_depar": "25000"
    },
    {
        "code": "68397",
        "name": "La Paz",
        "code_depar": "68000"
    },
    {
        "code": "20621",
        "name": "La Paz",
        "code_depar": "20000"
    },
    {
        "code": "91407",
        "name": "La Pedrera",
        "code_depar": "91000"
    },
    {
        "code": "25398",
        "name": "La Peña",
        "code_depar": "25000"
    },
    {
        "code": "5390",
        "name": "La Pintada",
        "code_depar": "5000"
    },
    {
        "code": "41396",
        "name": "La Plata",
        "code_depar": "41000"
    },
    {
        "code": "54398",
        "name": "La Playa",
        "code_depar": "54000"
    },
    {
        "code": "99524",
        "name": "La Primavera",
        "code_depar": "99000"
    },
    {
        "code": "85136",
        "name": "La Salina",
        "code_depar": "85000"
    },
    {
        "code": "19392",
        "name": "La Sierra",
        "code_depar": "19000"
    },
    {
        "code": "63401",
        "name": "La Tebaida",
        "code_depar": "63000"
    },
    {
        "code": "52390",
        "name": "La Tola",
        "code_depar": "52000"
    },
    {
        "code": "5400",
        "name": "La Union",
        "code_depar": "5000"
    },
    {
        "code": "52399",
        "name": "La Union",
        "code_depar": "52000"
    },
    {
        "code": "76400",
        "name": "La Union",
        "code_depar": "76000"
    },
    {
        "code": "70400",
        "name": "La Union",
        "code_depar": "70000"
    },
    {
        "code": "50370",
        "name": "La Uribe",
        "code_depar": "50000"
    },
    {
        "code": "15403",
        "name": "La Uvita",
        "code_depar": "15000"
    },
    {
        "code": "25402",
        "name": "La Vega",
        "code_depar": "25000"
    },
    {
        "code": "19397",
        "name": "La Vega",
        "code_depar": "19000"
    },
    {
        "code": "76403",
        "name": "La Victoria",
        "code_depar": "76000"
    },
    {
        "code": "15401",
        "name": "La Victoria",
        "code_depar": "15000"
    },
    {
        "code": "91430",
        "name": "La Victoria",
        "code_depar": "91000"
    },
    {
        "code": "66400",
        "name": "La Virginia",
        "code_depar": "66000"
    },
    {
        "code": "54377",
        "name": "Labateca",
        "code_depar": "54000"
    },
    {
        "code": "15377",
        "name": "Labranzagrande",
        "code_depar": "15000"
    },
    {
        "code": "68385",
        "name": "Landazuri",
        "code_depar": "68000"
    },
    {
        "code": "68406",
        "name": "Lebrija",
        "code_depar": "68000"
    },
    {
        "code": "86573",
        "name": "Leguizamo (Puerto Leguizamo)",
        "code_depar": "86000"
    },
    {
        "code": "52405",
        "name": "Leiva",
        "code_depar": "52000"
    },
    {
        "code": "50400",
        "name": "Lejanias",
        "code_depar": "50000"
    },
    {
        "code": "25407",
        "name": "Lenguazaque",
        "code_depar": "25000"
    },
    {
        "code": "73408",
        "name": "Lerida",
        "code_depar": "73000"
    },
    {
        "code": "91001",
        "name": "Leticia",
        "code_depar": "91000"
    },
    {
        "code": "73411",
        "name": "Libano",
        "code_depar": "73000"
    },
    {
        "code": "5411",
        "name": "Liborina",
        "code_depar": "5000"
    },
    {
        "code": "52411",
        "name": "Linares",
        "code_depar": "52000"
    },
    {
        "code": "27413",
        "name": "Lloro",
        "code_depar": "27000"
    },
    {
        "code": "19418",
        "name": "Lopez",
        "code_depar": "19000"
    },
    {
        "code": "23417",
        "name": "Lorica",
        "code_depar": "23000"
    },
    {
        "code": "52418",
        "name": "Los Andes",
        "code_depar": "52000"
    },
    {
        "code": "23419",
        "name": "Los Cordobas",
        "code_depar": "23000"
    },
    {
        "code": "70418",
        "name": "Los Palmitos",
        "code_depar": "70000"
    },
    {
        "code": "54405",
        "name": "Los Patios",
        "code_depar": "54000"
    },
    {
        "code": "68418",
        "name": "Los Santos",
        "code_depar": "68000"
    },
    {
        "code": "54418",
        "name": "Lourdes",
        "code_depar": "54000"
    },
    {
        "code": "8421",
        "name": "Luruaco",
        "code_depar": "8000"
    },
    {
        "code": "15425",
        "name": "Macanal",
        "code_depar": "15000"
    },
    {
        "code": "68425",
        "name": "Macaravita",
        "code_depar": "68000"
    },
    {
        "code": "5425",
        "name": "Maceo",
        "code_depar": "5000"
    },
    {
        "code": "25426",
        "name": "Macheta",
        "code_depar": "25000"
    },
    {
        "code": "25430",
        "name": "Madrid",
        "code_depar": "25000"
    },
    {
        "code": "13430",
        "name": "Magangue",
        "code_depar": "13000"
    },
    {
        "code": "52427",
        "name": "Magui",
        "code_depar": "52000"
    },
    {
        "code": "13433",
        "name": "Mahates",
        "code_depar": "13000"
    },
    {
        "code": "44430",
        "name": "Maicao",
        "code_depar": "44000"
    },
    {
        "code": "70429",
        "name": "Majagual",
        "code_depar": "70000"
    },
    {
        "code": "68432",
        "name": "Malaga",
        "code_depar": "68000"
    },
    {
        "code": "8433",
        "name": "Malambo",
        "code_depar": "8000"
    },
    {
        "code": "52435",
        "name": "Mallama",
        "code_depar": "52000"
    },
    {
        "code": "8436",
        "name": "Manati",
        "code_depar": "8000"
    },
    {
        "code": "44560",
        "name": "Manaure",
        "code_depar": "44000"
    },
    {
        "code": "20443",
        "name": "Manaure Balcon Dl Ce",
        "code_depar": "20000"
    },
    {
        "code": "85139",
        "name": "Mani",
        "code_depar": "85000"
    },
    {
        "code": "17001",
        "name": "Manizales",
        "code_depar": "17000"
    },
    {
        "code": "25436",
        "name": "Manta",
        "code_depar": "25000"
    },
    {
        "code": "17433",
        "name": "Manzanares",
        "code_depar": "17000"
    },
    {
        "code": "50325",
        "name": "Mapiripan",
        "code_depar": "50000"
    },
    {
        "code": "94663",
        "name": "Mapiripana",
        "code_depar": "94000"
    },
    {
        "code": "13440",
        "name": "Margarita",
        "code_depar": "13000"
    },
    {
        "code": "13442",
        "name": "Maria La Baja",
        "code_depar": "13000"
    },
    {
        "code": "5440",
        "name": "Marinilla",
        "code_depar": "5000"
    },
    {
        "code": "15442",
        "name": "Maripi",
        "code_depar": "15000"
    },
    {
        "code": "73443",
        "name": "Mariquita",
        "code_depar": "73000"
    },
    {
        "code": "17442",
        "name": "Marmato",
        "code_depar": "17000"
    },
    {
        "code": "17444",
        "name": "Marquetalia",
        "code_depar": "17000"
    },
    {
        "code": "66440",
        "name": "Marsella",
        "code_depar": "66000"
    },
    {
        "code": "17446",
        "name": "Marulanda",
        "code_depar": "17000"
    },
    {
        "code": "68444",
        "name": "Matanza",
        "code_depar": "68000"
    },
    {
        "code": "5001",
        "name": "Medellin",
        "code_depar": "5000"
    },
    {
        "code": "25438",
        "name": "Medina",
        "code_depar": "25000"
    },
    {
        "code": "27425",
        "name": "Medio Atrato",
        "code_depar": "27000"
    },
    {
        "code": "27430",
        "name": "Medio Baudó",
        "code_depar": "27000"
    },
    {
        "code": "27450",
        "name": "Medio San Juan",
        "code_depar": "27000"
    },
    {
        "code": "73449",
        "name": "Melgar",
        "code_depar": "73000"
    },
    {
        "code": "19450",
        "name": "Mercaderes",
        "code_depar": "19000"
    },
    {
        "code": "50330",
        "name": "Mesetas",
        "code_depar": "50000"
    },
    {
        "code": "18460",
        "name": "Milan",
        "code_depar": "18000"
    },
    {
        "code": "15455",
        "name": "Miraflores",
        "code_depar": "15000"
    },
    {
        "code": "95200",
        "name": "Miraflores",
        "code_depar": "95000"
    },
    {
        "code": "19455",
        "name": "Miranda",
        "code_depar": "19000"
    },
    {
        "code": "91460",
        "name": "Miriti-Parana",
        "code_depar": "91000"
    },
    {
        "code": "66456",
        "name": "Mistrato",
        "code_depar": "66000"
    },
    {
        "code": "97001",
        "name": "Mitu",
        "code_depar": "97000"
    },
    {
        "code": "86001",
        "name": "Mocoa",
        "code_depar": "86000"
    },
    {
        "code": "68464",
        "name": "Mogotes",
        "code_depar": "68000"
    },
    {
        "code": "68468",
        "name": "Molagavita",
        "code_depar": "68000"
    },
    {
        "code": "23464",
        "name": "Momil",
        "code_depar": "23000"
    },
    {
        "code": "13468",
        "name": "Mompos",
        "code_depar": "13000"
    },
    {
        "code": "15464",
        "name": "Mongua",
        "code_depar": "15000"
    },
    {
        "code": "15466",
        "name": "Mongui",
        "code_depar": "15000"
    },
    {
        "code": "15469",
        "name": "Moniquira",
        "code_depar": "15000"
    },
    {
        "code": "23500",
        "name": "Moñitos",
        "code_depar": "23000"
    },
    {
        "code": "5467",
        "name": "Montebello",
        "code_depar": "5000"
    },
    {
        "code": "13458",
        "name": "Montecristo",
        "code_depar": "13000"
    },
    {
        "code": "23466",
        "name": "Montelibano",
        "code_depar": "23000"
    },
    {
        "code": "63470",
        "name": "Montenegro",
        "code_depar": "63000"
    },
    {
        "code": "23001",
        "name": "Monteria",
        "code_depar": "23000"
    },
    {
        "code": "85162",
        "name": "Monterrey",
        "code_depar": "85000"
    },
    {
        "code": "13473",
        "name": "Morales",
        "code_depar": "13000"
    },
    {
        "code": "19473",
        "name": "Morales",
        "code_depar": "19000"
    },
    {
        "code": "18479",
        "name": "Morelia",
        "code_depar": "18000"
    },
    {
        "code": "95220",
        "name": "Morichal",
        "code_depar": "95000"
    },
    {
        "code": "94888",
        "name": "Morichal",
        "code_depar": "94000"
    },
    {
        "code": "70473",
        "name": "Morroa",
        "code_depar": "70000"
    },
    {
        "code": "25473",
        "name": "Mosquera",
        "code_depar": "25000"
    },
    {
        "code": "52473",
        "name": "Mosquera",
        "code_depar": "52000"
    },
    {
        "code": "15476",
        "name": "Motavita",
        "code_depar": "15000"
    },
    {
        "code": "73461",
        "name": "Murillo",
        "code_depar": "73000"
    },
    {
        "code": "5475",
        "name": "Murindo",
        "code_depar": "5000"
    },
    {
        "code": "5480",
        "name": "Mutata",
        "code_depar": "5000"
    },
    {
        "code": "54480",
        "name": "Mutiscua",
        "code_depar": "54000"
    },
    {
        "code": "15480",
        "name": "Muzo",
        "code_depar": "15000"
    },
    {
        "code": "5483",
        "name": "Nariño",
        "code_depar": "5000"
    },
    {
        "code": "25483",
        "name": "Nariño",
        "code_depar": "25000"
    },
    {
        "code": "52480",
        "name": "Nariño",
        "code_depar": "52000"
    },
    {
        "code": "41483",
        "name": "Nataga",
        "code_depar": "41000"
    },
    {
        "code": "73483",
        "name": "Natagaima",
        "code_depar": "73000"
    },
    {
        "code": "5495",
        "name": "Nechi",
        "code_depar": "5000"
    },
    {
        "code": "5490",
        "name": "Necocli",
        "code_depar": "5000"
    },
    {
        "code": "17486",
        "name": "Neira",
        "code_depar": "17000"
    },
    {
        "code": "41001",
        "name": "Neiva",
        "code_depar": "41000"
    },
    {
        "code": "25486",
        "name": "Nemocon",
        "code_depar": "25000"
    },
    {
        "code": "25488",
        "name": "Nilo",
        "code_depar": "25000"
    },
    {
        "code": "25489",
        "name": "Nimaima",
        "code_depar": "25000"
    },
    {
        "code": "15491",
        "name": "Nobsa",
        "code_depar": "15000"
    },
    {
        "code": "25491",
        "name": "Nocaima",
        "code_depar": "25000"
    },
    {
        "code": "17495",
        "name": "Norcasia",
        "code_depar": "17000"
    },
    {
        "code": "13490",
        "name": "Norosí",
        "code_depar": "13000"
    },
    {
        "code": "27491",
        "name": "Novita",
        "code_depar": "27000"
    },
    {
        "code": "47460",
        "name": "Nueva Granada",
        "code_depar": "47000"
    },
    {
        "code": "15494",
        "name": "Nuevo Colon",
        "code_depar": "15000"
    },
    {
        "code": "85225",
        "name": "Nunchia",
        "code_depar": "85000"
    },
    {
        "code": "27495",
        "name": "Nuqui",
        "code_depar": "27000"
    },
    {
        "code": "76497",
        "name": "Obando",
        "code_depar": "76000"
    },
    {
        "code": "68498",
        "name": "Ocamonte",
        "code_depar": "68000"
    },
    {
        "code": "54498",
        "name": "Ocaña",
        "code_depar": "54000"
    },
    {
        "code": "68500",
        "name": "Oiba",
        "code_depar": "68000"
    },
    {
        "code": "15500",
        "name": "Oicata",
        "code_depar": "15000"
    },
    {
        "code": "5501",
        "name": "Olaya",
        "code_depar": "5000"
    },
    {
        "code": "52490",
        "name": "Olaya Herrera",
        "code_depar": "52000"
    },
    {
        "code": "68502",
        "name": "Onzaga",
        "code_depar": "68000"
    },
    {
        "code": "41503",
        "name": "Oporapa",
        "code_depar": "41000"
    },
    {
        "code": "86320",
        "name": "Orito",
        "code_depar": "86000"
    },
    {
        "code": "85230",
        "name": "Orocue",
        "code_depar": "85000"
    },
    {
        "code": "73504",
        "name": "Ortega",
        "code_depar": "73000"
    },
    {
        "code": "52506",
        "name": "Ospina",
        "code_depar": "52000"
    },
    {
        "code": "15507",
        "name": "Otanche",
        "code_depar": "15000"
    },
    {
        "code": "70508",
        "name": "Ovejas",
        "code_depar": "70000"
    },
    {
        "code": "15511",
        "name": "Pachavita",
        "code_depar": "15000"
    },
    {
        "code": "25513",
        "name": "Pacho",
        "code_depar": "25000"
    },
    {
        "code": "97511",
        "name": "Pacoa",
        "code_depar": "97000"
    },
    {
        "code": "17513",
        "name": "Pacora",
        "code_depar": "17000"
    },
    {
        "code": "19513",
        "name": "Padilla",
        "code_depar": "19000"
    },
    {
        "code": "19517",
        "name": "Paez",
        "code_depar": "19000"
    },
    {
        "code": "15514",
        "name": "Paez",
        "code_depar": "15000"
    },
    {
        "code": "41518",
        "name": "Paicol",
        "code_depar": "41000"
    },
    {
        "code": "20517",
        "name": "Pailitas",
        "code_depar": "20000"
    },
    {
        "code": "25518",
        "name": "Paime",
        "code_depar": "25000"
    },
    {
        "code": "15516",
        "name": "Paipa",
        "code_depar": "15000"
    },
    {
        "code": "15518",
        "name": "Pajarito",
        "code_depar": "15000"
    },
    {
        "code": "41524",
        "name": "Palermo",
        "code_depar": "41000"
    },
    {
        "code": "17524",
        "name": "Palestina",
        "code_depar": "17000"
    },
    {
        "code": "41530",
        "name": "Palestina",
        "code_depar": "41000"
    },
    {
        "code": "68522",
        "name": "Palmar",
        "code_depar": "68000"
    },
    {
        "code": "8520",
        "name": "Palmar De Varela",
        "code_depar": "8000"
    },
    {
        "code": "68524",
        "name": "Palmas Del Socorro",
        "code_depar": "68000"
    },
    {
        "code": "76520",
        "name": "Palmira",
        "code_depar": "76000"
    },
    {
        "code": "70523",
        "name": "Palmito",
        "code_depar": "70000"
    },
    {
        "code": "73520",
        "name": "Palocabildo",
        "code_depar": "73000"
    },
    {
        "code": "54518",
        "name": "Pamplona",
        "code_depar": "54000"
    },
    {
        "code": "54520",
        "name": "Pamplonita",
        "code_depar": "54000"
    },
    {
        "code": "94887",
        "name": "Pana Pana",
        "code_depar": "94000"
    },
    {
        "code": "25524",
        "name": "Pandi",
        "code_depar": "25000"
    },
    {
        "code": "15522",
        "name": "Panqueba",
        "code_depar": "15000"
    },
    {
        "code": "97777",
        "name": "Papunaua",
        "code_depar": "97000"
    },
    {
        "code": "68533",
        "name": "Paramo",
        "code_depar": "68000"
    },
    {
        "code": "25530",
        "name": "Paratebueno",
        "code_depar": "25000"
    },
    {
        "code": "25535",
        "name": "Pasca",
        "code_depar": "25000"
    },
    {
        "code": "52001",
        "name": "Pasto",
        "code_depar": "52000"
    },
    {
        "code": "19532",
        "name": "Patia(El Bordo)",
        "code_depar": "19000"
    },
    {
        "code": "15531",
        "name": "Pauna",
        "code_depar": "15000"
    },
    {
        "code": "15533",
        "name": "Paya",
        "code_depar": "15000"
    },
    {
        "code": "85250",
        "name": "Paz De Ariporo",
        "code_depar": "85000"
    },
    {
        "code": "15537",
        "name": "Paz De Rio",
        "code_depar": "15000"
    },
    {
        "code": "47541",
        "name": "Pedraza",
        "code_depar": "47000"
    },
    {
        "code": "20550",
        "name": "Pelaya",
        "code_depar": "20000"
    },
    {
        "code": "5541",
        "name": "Peñol",
        "code_depar": "5000"
    },
    {
        "code": "17541",
        "name": "Pensilvania",
        "code_depar": "17000"
    },
    {
        "code": "5543",
        "name": "Peque",
        "code_depar": "5000"
    },
    {
        "code": "66001",
        "name": "Pereira",
        "code_depar": "66000"
    },
    {
        "code": "15542",
        "name": "Pesca",
        "code_depar": "15000"
    },
    {
        "code": "19533",
        "name": "Piamonte",
        "code_depar": "19000"
    },
    {
        "code": "68547",
        "name": "Piedecuesta",
        "code_depar": "68000"
    },
    {
        "code": "73547",
        "name": "Piedras",
        "code_depar": "73000"
    },
    {
        "code": "19548",
        "name": "Piendamo",
        "code_depar": "19000"
    },
    {
        "code": "63548",
        "name": "Pijao",
        "code_depar": "63000"
    },
    {
        "code": "47545",
        "name": "Pijiño Del Carmen",
        "code_depar": "47000"
    },
    {
        "code": "68549",
        "name": "Pinchote",
        "code_depar": "68000"
    },
    {
        "code": "13549",
        "name": "Pinillos",
        "code_depar": "13000"
    },
    {
        "code": "8549",
        "name": "Piojo",
        "code_depar": "8000"
    },
    {
        "code": "15550",
        "name": "Pisba",
        "code_depar": "15000"
    },
    {
        "code": "41548",
        "name": "Pital",
        "code_depar": "41000"
    },
    {
        "code": "41551",
        "name": "Pitalito",
        "code_depar": "41000"
    },
    {
        "code": "47551",
        "name": "Pivijay",
        "code_depar": "47000"
    },
    {
        "code": "73555",
        "name": "Planadas",
        "code_depar": "73000"
    },
    {
        "code": "23555",
        "name": "Planeta Rica",
        "code_depar": "23000"
    },
    {
        "code": "47555",
        "name": "Plato",
        "code_depar": "47000"
    },
    {
        "code": "52540",
        "name": "Policarpa",
        "code_depar": "52000"
    },
    {
        "code": "8558",
        "name": "Polonuevo",
        "code_depar": "8000"
    },
    {
        "code": "8560",
        "name": "Ponedera",
        "code_depar": "8000"
    },
    {
        "code": "19001",
        "name": "Popayan",
        "code_depar": "19000"
    },
    {
        "code": "85263",
        "name": "Pore",
        "code_depar": "85000"
    },
    {
        "code": "52560",
        "name": "Potosi",
        "code_depar": "52000"
    },
    {
        "code": "76563",
        "name": "Pradera",
        "code_depar": "76000"
    },
    {
        "code": "73563",
        "name": "Prado",
        "code_depar": "73000"
    },
    {
        "code": "88564",
        "name": "Providencia",
        "code_depar": "88000"
    },
    {
        "code": "52565",
        "name": "Providencia",
        "code_depar": "52000"
    },
    {
        "code": "20570",
        "name": "Pueblo Bello",
        "code_depar": "20000"
    },
    {
        "code": "23570",
        "name": "Pueblo Nuevo",
        "code_depar": "23000"
    },
    {
        "code": "66572",
        "name": "Pueblo Rico",
        "code_depar": "66000"
    },
    {
        "code": "5576",
        "name": "Pueblorrico",
        "code_depar": "5000"
    },
    {
        "code": "47570",
        "name": "Puebloviejo",
        "code_depar": "47000"
    },
    {
        "code": "68572",
        "name": "Puente Nacional",
        "code_depar": "68000"
    },
    {
        "code": "52573",
        "name": "Puerres",
        "code_depar": "52000"
    },
    {
        "code": "91530",
        "name": "Puerto Alegría",
        "code_depar": "91000"
    },
    {
        "code": "91536",
        "name": "Puerto Arica",
        "code_depar": "91000"
    },
    {
        "code": "86568",
        "name": "Puerto Asis",
        "code_depar": "86000"
    },
    {
        "code": "5579",
        "name": "Puerto Berrio",
        "code_depar": "5000"
    },
    {
        "code": "15572",
        "name": "Puerto Boyaca",
        "code_depar": "15000"
    },
    {
        "code": "86569",
        "name": "Puerto Caicedo",
        "code_depar": "86000"
    },
    {
        "code": "99001",
        "name": "Puerto Carreno",
        "code_depar": "99000"
    },
    {
        "code": "8573",
        "name": "Puerto Colombia",
        "code_depar": "8000"
    },
    {
        "code": "94884",
        "name": "Puerto Colombia",
        "code_depar": "94000"
    },
    {
        "code": "50450",
        "name": "Puerto Concordia",
        "code_depar": "50000"
    },
    {
        "code": "23574",
        "name": "Puerto Escondido",
        "code_depar": "23000"
    },
    {
        "code": "50568",
        "name": "Puerto Gaitan",
        "code_depar": "50000"
    },
    {
        "code": "86571",
        "name": "Puerto Guzmán",
        "code_depar": "86000"
    },
    {
        "code": "23580",
        "name": "Puerto Libertador",
        "code_depar": "23000"
    },
    {
        "code": "50577",
        "name": "Puerto Lleras",
        "code_depar": "50000"
    },
    {
        "code": "50573",
        "name": "Puerto Lopez",
        "code_depar": "50000"
    },
    {
        "code": "5585",
        "name": "Puerto Nare(Lamagdalena",
        "code_depar": "5000"
    },
    {
        "code": "91540",
        "name": "Puerto Nariño",
        "code_depar": "91000"
    },
    {
        "code": "68573",
        "name": "Puerto Parra",
        "code_depar": "68000"
    },
    {
        "code": "50590",
        "name": "Puerto Rico",
        "code_depar": "50000"
    },
    {
        "code": "18592",
        "name": "Puerto Rico",
        "code_depar": "18000"
    },
    {
        "code": "81591",
        "name": "Puerto Rondon",
        "code_depar": "81000"
    },
    {
        "code": "25572",
        "name": "Puerto Salgar",
        "code_depar": "25000"
    },
    {
        "code": "54553",
        "name": "Puerto Santander",
        "code_depar": "54000"
    },
    {
        "code": "91669",
        "name": "Puerto Santander",
        "code_depar": "91000"
    },
    {
        "code": "19573",
        "name": "Puerto Tejada",
        "code_depar": "19000"
    },
    {
        "code": "5591",
        "name": "Puerto Triunfo",
        "code_depar": "5000"
    },
    {
        "code": "68575",
        "name": "Puerto Wilches",
        "code_depar": "68000"
    },
    {
        "code": "25580",
        "name": "Puli",
        "code_depar": "25000"
    },
    {
        "code": "52585",
        "name": "Pupiales",
        "code_depar": "52000"
    },
    {
        "code": "19585",
        "name": "Purace",
        "code_depar": "19000"
    },
    {
        "code": "73585",
        "name": "Purificacion",
        "code_depar": "73000"
    },
    {
        "code": "23586",
        "name": "Purisima",
        "code_depar": "23000"
    },
    {
        "code": "25592",
        "name": "Quebradanegra",
        "code_depar": "25000"
    },
    {
        "code": "25594",
        "name": "Quetame",
        "code_depar": "25000"
    },
    {
        "code": "27001",
        "name": "Quibdo",
        "code_depar": "27000"
    },
    {
        "code": "63594",
        "name": "Quimbaya",
        "code_depar": "63000"
    },
    {
        "code": "66594",
        "name": "Quinchia",
        "code_depar": "66000"
    },
    {
        "code": "15580",
        "name": "Quipama",
        "code_depar": "15000"
    },
    {
        "code": "25596",
        "name": "Quipile",
        "code_depar": "25000"
    },
    {
        "code": "54599",
        "name": "Ragonvalia",
        "code_depar": "54000"
    },
    {
        "code": "15599",
        "name": "Ramiriqui",
        "code_depar": "15000"
    },
    {
        "code": "15600",
        "name": "Raquira",
        "code_depar": "15000"
    },
    {
        "code": "85279",
        "name": "Recetor",
        "code_depar": "85000"
    },
    {
        "code": "13580",
        "name": "Regidor",
        "code_depar": "13000"
    },
    {
        "code": "5604",
        "name": "Remedios",
        "code_depar": "5000"
    },
    {
        "code": "47605",
        "name": "Remolino",
        "code_depar": "47000"
    },
    {
        "code": "8606",
        "name": "Repelon",
        "code_depar": "8000"
    },
    {
        "code": "50606",
        "name": "Restrepo",
        "code_depar": "50000"
    },
    {
        "code": "76606",
        "name": "Restrepo",
        "code_depar": "76000"
    },
    {
        "code": "5607",
        "name": "Retiro",
        "code_depar": "5000"
    },
    {
        "code": "25612",
        "name": "Ricaurte",
        "code_depar": "25000"
    },
    {
        "code": "52612",
        "name": "Ricaurte",
        "code_depar": "52000"
    },
    {
        "code": "20614",
        "name": "Rio De Oro",
        "code_depar": "20000"
    },
    {
        "code": "27580",
        "name": "Río Iro",
        "code_depar": "27000"
    },
    {
        "code": "27600",
        "name": "Río Quito",
        "code_depar": "27000"
    },
    {
        "code": "13600",
        "name": "Rio Viejo",
        "code_depar": "13000"
    },
    {
        "code": "73616",
        "name": "Rioblanco",
        "code_depar": "73000"
    },
    {
        "code": "76616",
        "name": "Riofrio",
        "code_depar": "76000"
    },
    {
        "code": "44001",
        "name": "Riohacha",
        "code_depar": "44000"
    },
    {
        "code": "5615",
        "name": "Rionegro",
        "code_depar": "5000"
    },
    {
        "code": "68615",
        "name": "Rionegro",
        "code_depar": "68000"
    },
    {
        "code": "17614",
        "name": "Riosucio",
        "code_depar": "17000"
    },
    {
        "code": "27615",
        "name": "Riosucio",
        "code_depar": "27000"
    },
    {
        "code": "17616",
        "name": "Risaralda",
        "code_depar": "17000"
    },
    {
        "code": "41615",
        "name": "Rivera",
        "code_depar": "41000"
    },
    {
        "code": "52621",
        "name": "Roberto Payan",
        "code_depar": "52000"
    },
    {
        "code": "76622",
        "name": "Roldanillo",
        "code_depar": "76000"
    },
    {
        "code": "73622",
        "name": "Roncesvalles",
        "code_depar": "73000"
    },
    {
        "code": "15621",
        "name": "Rondon",
        "code_depar": "15000"
    },
    {
        "code": "19622",
        "name": "Rosas",
        "code_depar": "19000"
    },
    {
        "code": "73624",
        "name": "Rovira",
        "code_depar": "73000"
    },
    {
        "code": "25645",
        "name": "S.Antonio Tequendama",
        "code_depar": "25000"
    },
    {
        "code": "68655",
        "name": "Sabana De Torres",
        "code_depar": "68000"
    },
    {
        "code": "8634",
        "name": "Sabanagrande",
        "code_depar": "8000"
    },
    {
        "code": "5628",
        "name": "Sabanalarga",
        "code_depar": "5000"
    },
    {
        "code": "8638",
        "name": "Sabanalarga",
        "code_depar": "8000"
    },
    {
        "code": "85300",
        "name": "Sabanalarga",
        "code_depar": "85000"
    },
    {
        "code": "47660",
        "name": "Sabanas De San Angel",
        "code_depar": "47000"
    },
    {
        "code": "5631",
        "name": "Sabaneta",
        "code_depar": "5000"
    },
    {
        "code": "15632",
        "name": "Saboya",
        "code_depar": "15000"
    },
    {
        "code": "85315",
        "name": "Sacama",
        "code_depar": "85000"
    },
    {
        "code": "15638",
        "name": "Sachica",
        "code_depar": "15000"
    },
    {
        "code": "23660",
        "name": "Sahagun",
        "code_depar": "23000"
    },
    {
        "code": "41660",
        "name": "Saladoblanco",
        "code_depar": "41000"
    },
    {
        "code": "17653",
        "name": "Salamina",
        "code_depar": "17000"
    },
    {
        "code": "47675",
        "name": "Salamina",
        "code_depar": "47000"
    },
    {
        "code": "54660",
        "name": "Salazar",
        "code_depar": "54000"
    },
    {
        "code": "73671",
        "name": "Saldaña",
        "code_depar": "73000"
    },
    {
        "code": "63690",
        "name": "Salento",
        "code_depar": "63000"
    },
    {
        "code": "5642",
        "name": "Salgar",
        "code_depar": "5000"
    },
    {
        "code": "15646",
        "name": "Samaca",
        "code_depar": "15000"
    },
    {
        "code": "17662",
        "name": "Samana",
        "code_depar": "17000"
    },
    {
        "code": "52678",
        "name": "Samaniego",
        "code_depar": "52000"
    },
    {
        "code": "70670",
        "name": "Sampues",
        "code_depar": "70000"
    },
    {
        "code": "41668",
        "name": "San Agustin",
        "code_depar": "41000"
    },
    {
        "code": "20710",
        "name": "San Alberto",
        "code_depar": "20000"
    },
    {
        "code": "88001",
        "name": "San Andres",
        "code_depar": "88000"
    },
    {
        "code": "5647",
        "name": "San Andres",
        "code_depar": "5000"
    },
    {
        "code": "68669",
        "name": "San Andres",
        "code_depar": "68000"
    },
    {
        "code": "23670",
        "name": "San Andres Sotavento",
        "code_depar": "23000"
    },
    {
        "code": "23672",
        "name": "San Antero",
        "code_depar": "23000"
    },
    {
        "code": "73675",
        "name": "San Antonio",
        "code_depar": "73000"
    },
    {
        "code": "68673",
        "name": "San Benito",
        "code_depar": "68000"
    },
    {
        "code": "70678",
        "name": "San Benito Abad",
        "code_depar": "70000"
    },
    {
        "code": "25649",
        "name": "San Bernardo",
        "code_depar": "25000"
    },
    {
        "code": "52685",
        "name": "San Bernardo",
        "code_depar": "52000"
    },
    {
        "code": "23675",
        "name": "San Bernardo Del Vie",
        "code_depar": "23000"
    },
    {
        "code": "54670",
        "name": "San Calixto",
        "code_depar": "54000"
    },
    {
        "code": "5649",
        "name": "San Carlos",
        "code_depar": "5000"
    },
    {
        "code": "23678",
        "name": "San Carlos",
        "code_depar": "23000"
    },
    {
        "code": "50680",
        "name": "San Carlos Guaroa",
        "code_depar": "50000"
    },
    {
        "code": "25653",
        "name": "San Cayetano",
        "code_depar": "25000"
    },
    {
        "code": "54673",
        "name": "San Cayetano",
        "code_depar": "54000"
    },
    {
        "code": "13620",
        "name": "San Cristobal",
        "code_depar": "13000"
    },
    {
        "code": "5992",
        "name": "San Cristobal",
        "code_depar": "5000"
    },
    {
        "code": "20750",
        "name": "San Diego",
        "code_depar": "20000"
    },
    {
        "code": "15660",
        "name": "San Eduardo",
        "code_depar": "15000"
    },
    {
        "code": "13647",
        "name": "San Estanislao",
        "code_depar": "13000"
    },
    {
        "code": "94883",
        "name": "San Felipe",
        "code_depar": "94000"
    },
    {
        "code": "17995",
        "name": "San Felix",
        "code_depar": "17000"
    },
    {
        "code": "13650",
        "name": "San Fernando",
        "code_depar": "13000"
    },
    {
        "code": "5652",
        "name": "San Francisco",
        "code_depar": "5000"
    },
    {
        "code": "25658",
        "name": "San Francisco",
        "code_depar": "25000"
    },
    {
        "code": "86755",
        "name": "San Francisco",
        "code_depar": "86000"
    },
    {
        "code": "68679",
        "name": "San Gil",
        "code_depar": "68000"
    },
    {
        "code": "13654",
        "name": "San Jacinto",
        "code_depar": "13000"
    },
    {
        "code": "13655",
        "name": "San Jacinto Del Cauca",
        "code_depar": "13000"
    },
    {
        "code": "5656",
        "name": "San Jeronimo",
        "code_depar": "5000"
    },
    {
        "code": "68682",
        "name": "San Joaquin",
        "code_depar": "68000"
    },
    {
        "code": "17665",
        "name": "San Jose",
        "code_depar": "17000"
    },
    {
        "code": "18610",
        "name": "San Jose De Fragua",
        "code_depar": "18000"
    },
    {
        "code": "5658",
        "name": "San Jose De La Montaña",
        "code_depar": "5000"
    },
    {
        "code": "68684",
        "name": "San Jose De Miranda",
        "code_depar": "68000"
    },
    {
        "code": "99760",
        "name": "San Jose De Ocune",
        "code_depar": "99000"
    },
    {
        "code": "15664",
        "name": "San Jose De Pare",
        "code_depar": "15000"
    },
    {
        "code": "17996",
        "name": "San Jose De Risaralda",
        "code_depar": "17000"
    },
    {
        "code": "23682",
        "name": "San José De Uré",
        "code_depar": "23000"
    },
    {
        "code": "95001",
        "name": "San Jose Del Guaviar",
        "code_depar": "95000"
    },
    {
        "code": "27660",
        "name": "San Jose Del Palmar",
        "code_depar": "27000"
    },
    {
        "code": "50683",
        "name": "San Juan De Arama",
        "code_depar": "50000"
    },
    {
        "code": "70702",
        "name": "San Juan De Betulia",
        "code_depar": "70000"
    },
    {
        "code": "25662",
        "name": "San Juan De Rio Seco",
        "code_depar": "25000"
    },
    {
        "code": "5659",
        "name": "San Juan De Uraba",
        "code_depar": "5000"
    },
    {
        "code": "44650",
        "name": "San Juan Del Cesar",
        "code_depar": "44000"
    },
    {
        "code": "13657",
        "name": "San Juan Nepomuceno",
        "code_depar": "13000"
    },
    {
        "code": "50686",
        "name": "San Juanito",
        "code_depar": "50000"
    },
    {
        "code": "52687",
        "name": "San Lorenzo",
        "code_depar": "52000"
    },
    {
        "code": "5660",
        "name": "San Luis",
        "code_depar": "5000"
    },
    {
        "code": "73678",
        "name": "San Luis",
        "code_depar": "73000"
    },
    {
        "code": "15667",
        "name": "San Luis De Gaceno",
        "code_depar": "15000"
    },
    {
        "code": "85325",
        "name": "San Luis De Palenque",
        "code_depar": "85000"
    },
    {
        "code": "70708",
        "name": "San Marcos",
        "code_depar": "70000"
    },
    {
        "code": "50689",
        "name": "San Martin",
        "code_depar": "50000"
    },
    {
        "code": "20770",
        "name": "San Martin",
        "code_depar": "20000"
    },
    {
        "code": "13667",
        "name": "San Martin De Loba",
        "code_depar": "13000"
    },
    {
        "code": "15673",
        "name": "San Mateo",
        "code_depar": "15000"
    },
    {
        "code": "68686",
        "name": "San Miguel",
        "code_depar": "68000"
    },
    {
        "code": "86757",
        "name": "San Miguel",
        "code_depar": "86000"
    },
    {
        "code": "15676",
        "name": "San Miguel De Sema",
        "code_depar": "15000"
    },
    {
        "code": "70713",
        "name": "San Onofre",
        "code_depar": "70000"
    },
    {
        "code": "13670",
        "name": "San Pablo",
        "code_depar": "13000"
    },
    {
        "code": "52693",
        "name": "San Pablo",
        "code_depar": "52000"
    },
    {
        "code": "15681",
        "name": "San Pablo De Borbur",
        "code_depar": "15000"
    },
    {
        "code": "5664",
        "name": "San Pedro",
        "code_depar": "5000"
    },
    {
        "code": "76670",
        "name": "San Pedro",
        "code_depar": "76000"
    },
    {
        "code": "70717",
        "name": "San Pedro",
        "code_depar": "70000"
    },
    {
        "code": "52694",
        "name": "San Pedro De Cartago",
        "code_depar": "52000"
    },
    {
        "code": "5665",
        "name": "San Pedro De Uraba",
        "code_depar": "5000"
    },
    {
        "code": "23686",
        "name": "San Pelayo",
        "code_depar": "23000"
    },
    {
        "code": "5667",
        "name": "San Rafael",
        "code_depar": "5000"
    },
    {
        "code": "5670",
        "name": "San Roque",
        "code_depar": "5000"
    },
    {
        "code": "19693",
        "name": "San Sebastian",
        "code_depar": "19000"
    },
    {
        "code": "47692",
        "name": "San Sebastian Buenav",
        "code_depar": "47000"
    },
    {
        "code": "5674",
        "name": "San Vicente",
        "code_depar": "5000"
    },
    {
        "code": "68689",
        "name": "San Vicente De Chucuri",
        "code_depar": "68000"
    },
    {
        "code": "18753",
        "name": "San Vicente Del Cagu",
        "code_depar": "18000"
    },
    {
        "code": "47703",
        "name": "San Zenon",
        "code_depar": "47000"
    },
    {
        "code": "52683",
        "name": "Sandona",
        "code_depar": "52000"
    },
    {
        "code": "47707",
        "name": "Santa Ana",
        "code_depar": "47000"
    },
    {
        "code": "5679",
        "name": "Santa Barbara",
        "code_depar": "5000"
    },
    {
        "code": "52696",
        "name": "Santa Barbara",
        "code_depar": "52000"
    },
    {
        "code": "68705",
        "name": "Santa Barbara",
        "code_depar": "68000"
    },
    {
        "code": "47720",
        "name": "Santa Barbara De Pinto",
        "code_depar": "47000"
    },
    {
        "code": "13673",
        "name": "Santa Catalina",
        "code_depar": "13000"
    },
    {
        "code": "68720",
        "name": "Santa Helena Del Opon",
        "code_depar": "68000"
    },
    {
        "code": "73686",
        "name": "Santa Isabel",
        "code_depar": "73000"
    },
    {
        "code": "8675",
        "name": "Santa Lucia",
        "code_depar": "8000"
    },
    {
        "code": "41676",
        "name": "Santa Maria",
        "code_depar": "41000"
    },
    {
        "code": "15690",
        "name": "Santa Maria",
        "code_depar": "15000"
    },
    {
        "code": "47001",
        "name": "Santa Marta",
        "code_depar": "47000"
    },
    {
        "code": "99572",
        "name": "Santa Rita",
        "code_depar": "99000"
    },
    {
        "code": "13683",
        "name": "Santa Rosa",
        "code_depar": "13000"
    },
    {
        "code": "19701",
        "name": "Santa Rosa",
        "code_depar": "19000"
    },
    {
        "code": "66682",
        "name": "Santa Rosa De Cabal",
        "code_depar": "66000"
    },
    {
        "code": "5686",
        "name": "Santa Rosa De Osos",
        "code_depar": "5000"
    },
    {
        "code": "15693",
        "name": "Santa Rosa De Viterb",
        "code_depar": "15000"
    },
    {
        "code": "13688",
        "name": "Santa Rosa Del Sur",
        "code_depar": "13000"
    },
    {
        "code": "99624",
        "name": "Santa Rosalía",
        "code_depar": "99000"
    },
    {
        "code": "15696",
        "name": "Santa Sofia",
        "code_depar": "15000"
    },
    {
        "code": "52699",
        "name": "Santacruz",
        "code_depar": "52000"
    },
    {
        "code": "5042",
        "name": "Santafe De Antioquia",
        "code_depar": "5000"
    },
    {
        "code": "15686",
        "name": "Santana",
        "code_depar": "15000"
    },
    {
        "code": "19698",
        "name": "Santander De Quilich",
        "code_depar": "19000"
    },
    {
        "code": "54680",
        "name": "Santiago",
        "code_depar": "54000"
    },
    {
        "code": "86760",
        "name": "Santiago",
        "code_depar": "86000"
    },
    {
        "code": "70820",
        "name": "Santiago De Tolu (Tolu)",
        "code_depar": "70000"
    },
    {
        "code": "73997",
        "name": "Santiago Perez",
        "code_depar": "73000"
    },
    {
        "code": "5690",
        "name": "Santo Domingo",
        "code_depar": "5000"
    },
    {
        "code": "8685",
        "name": "Santo Tomas",
        "code_depar": "8000"
    },
    {
        "code": "66687",
        "name": "Santuario",
        "code_depar": "66000"
    },
    {
        "code": "52720",
        "name": "Sapuyes",
        "code_depar": "52000"
    },
    {
        "code": "81736",
        "name": "Saravena",
        "code_depar": "81000"
    },
    {
        "code": "54720",
        "name": "Sardinata",
        "code_depar": "54000"
    },
    {
        "code": "25718",
        "name": "Sasaima",
        "code_depar": "25000"
    },
    {
        "code": "15720",
        "name": "Sativanorte",
        "code_depar": "15000"
    },
    {
        "code": "15723",
        "name": "Sativasur",
        "code_depar": "15000"
    },
    {
        "code": "5736",
        "name": "Segovia",
        "code_depar": "5000"
    },
    {
        "code": "25736",
        "name": "Sesquile",
        "code_depar": "25000"
    },
    {
        "code": "76736",
        "name": "Sevilla",
        "code_depar": "76000"
    },
    {
        "code": "15740",
        "name": "Siachoque",
        "code_depar": "15000"
    },
    {
        "code": "25740",
        "name": "Sibate",
        "code_depar": "25000"
    },
    {
        "code": "86749",
        "name": "Sibundoy",
        "code_depar": "86000"
    },
    {
        "code": "54743",
        "name": "Silos",
        "code_depar": "54000"
    },
    {
        "code": "25743",
        "name": "Silvania",
        "code_depar": "25000"
    },
    {
        "code": "19743",
        "name": "Silvia",
        "code_depar": "19000"
    },
    {
        "code": "68745",
        "name": "Simacota",
        "code_depar": "68000"
    },
    {
        "code": "25745",
        "name": "Simijaca",
        "code_depar": "25000"
    },
    {
        "code": "13744",
        "name": "Simiti",
        "code_depar": "13000"
    },
    {
        "code": "70742",
        "name": "Since",
        "code_depar": "70000"
    },
    {
        "code": "70001",
        "name": "Sincelejo",
        "code_depar": "70000"
    },
    {
        "code": "27745",
        "name": "Sipi",
        "code_depar": "27000"
    },
    {
        "code": "47745",
        "name": "Sitionuevo",
        "code_depar": "47000"
    },
    {
        "code": "25754",
        "name": "Soacha",
        "code_depar": "25000"
    },
    {
        "code": "15753",
        "name": "Soata",
        "code_depar": "15000"
    },
    {
        "code": "15757",
        "name": "Socha",
        "code_depar": "15000"
    },
    {
        "code": "68755",
        "name": "Socorro",
        "code_depar": "68000"
    },
    {
        "code": "15755",
        "name": "Socota",
        "code_depar": "15000"
    },
    {
        "code": "15759",
        "name": "Sogamoso",
        "code_depar": "15000"
    },
    {
        "code": "18765",
        "name": "Solano",
        "code_depar": "18000"
    },
    {
        "code": "18756",
        "name": "Solano",
        "code_depar": "18000"
    },
    {
        "code": "8758",
        "name": "Soledad",
        "code_depar": "8000"
    },
    {
        "code": "18785",
        "name": "Solita",
        "code_depar": "18000"
    },
    {
        "code": "15761",
        "name": "Somondoco",
        "code_depar": "15000"
    },
    {
        "code": "5756",
        "name": "Sonson",
        "code_depar": "5000"
    },
    {
        "code": "5761",
        "name": "Sopetran",
        "code_depar": "5000"
    },
    {
        "code": "13760",
        "name": "Soplaviento",
        "code_depar": "13000"
    },
    {
        "code": "25758",
        "name": "Sopo",
        "code_depar": "25000"
    },
    {
        "code": "15762",
        "name": "Sora",
        "code_depar": "15000"
    },
    {
        "code": "15764",
        "name": "Soraca",
        "code_depar": "15000"
    },
    {
        "code": "15763",
        "name": "Sotaquira",
        "code_depar": "15000"
    },
    {
        "code": "19760",
        "name": "Sotara",
        "code_depar": "19000"
    },
    {
        "code": "68770",
        "name": "Suaita",
        "code_depar": "68000"
    },
    {
        "code": "8770",
        "name": "Suan",
        "code_depar": "8000"
    },
    {
        "code": "73770",
        "name": "Suarez",
        "code_depar": "73000"
    },
    {
        "code": "19780",
        "name": "Suarez",
        "code_depar": "19000"
    },
    {
        "code": "41770",
        "name": "Suaza",
        "code_depar": "41000"
    },
    {
        "code": "25769",
        "name": "Subachoque",
        "code_depar": "25000"
    },
    {
        "code": "68773",
        "name": "Sucre",
        "code_depar": "68000"
    },
    {
        "code": "19785",
        "name": "Sucre",
        "code_depar": "19000"
    },
    {
        "code": "70771",
        "name": "Sucre",
        "code_depar": "70000"
    },
    {
        "code": "25772",
        "name": "Suesca",
        "code_depar": "25000"
    },
    {
        "code": "25777",
        "name": "Supata",
        "code_depar": "25000"
    },
    {
        "code": "17777",
        "name": "Supia",
        "code_depar": "17000"
    },
    {
        "code": "68780",
        "name": "Surata",
        "code_depar": "68000"
    },
    {
        "code": "25779",
        "name": "Susa",
        "code_depar": "25000"
    },
    {
        "code": "15774",
        "name": "Susacon",
        "code_depar": "15000"
    },
    {
        "code": "15776",
        "name": "Sutamarchan",
        "code_depar": "15000"
    },
    {
        "code": "25781",
        "name": "Sutatausa",
        "code_depar": "25000"
    },
    {
        "code": "15778",
        "name": "Sutatenza",
        "code_depar": "15000"
    },
    {
        "code": "25785",
        "name": "Tabio",
        "code_depar": "25000"
    },
    {
        "code": "27787",
        "name": "Tado",
        "code_depar": "27000"
    },
    {
        "code": "13780",
        "name": "Talaigua Nuevo",
        "code_depar": "13000"
    },
    {
        "code": "20787",
        "name": "Tamalameque",
        "code_depar": "20000"
    },
    {
        "code": "85400",
        "name": "Tamara",
        "code_depar": "85000"
    },
    {
        "code": "81794",
        "name": "Tame",
        "code_depar": "81000"
    },
    {
        "code": "5789",
        "name": "Tamesis",
        "code_depar": "5000"
    },
    {
        "code": "52786",
        "name": "Taminango",
        "code_depar": "52000"
    },
    {
        "code": "52788",
        "name": "Tangua",
        "code_depar": "52000"
    },
    {
        "code": "97666",
        "name": "Taraira",
        "code_depar": "97000"
    },
    {
        "code": "91798",
        "name": "Tarapaca",
        "code_depar": "91000"
    },
    {
        "code": "5790",
        "name": "Taraza",
        "code_depar": "5000"
    },
    {
        "code": "41791",
        "name": "Tarqui",
        "code_depar": "41000"
    },
    {
        "code": "5792",
        "name": "Tarso",
        "code_depar": "5000"
    },
    {
        "code": "15790",
        "name": "Tasco",
        "code_depar": "15000"
    },
    {
        "code": "85410",
        "name": "Tauramena",
        "code_depar": "85000"
    },
    {
        "code": "25793",
        "name": "Tausa",
        "code_depar": "25000"
    },
    {
        "code": "41799",
        "name": "Tello",
        "code_depar": "41000"
    },
    {
        "code": "25797",
        "name": "Tena",
        "code_depar": "25000"
    },
    {
        "code": "47798",
        "name": "Tenerife",
        "code_depar": "47000"
    },
    {
        "code": "25799",
        "name": "Tenjo",
        "code_depar": "25000"
    },
    {
        "code": "15798",
        "name": "Tenza",
        "code_depar": "15000"
    },
    {
        "code": "54800",
        "name": "Teorama",
        "code_depar": "54000"
    },
    {
        "code": "41801",
        "name": "Teruel",
        "code_depar": "41000"
    },
    {
        "code": "41797",
        "name": "Tesalia",
        "code_depar": "41000"
    },
    {
        "code": "25805",
        "name": "Tibacuy",
        "code_depar": "25000"
    },
    {
        "code": "15804",
        "name": "Tibana",
        "code_depar": "15000"
    },
    {
        "code": "15806",
        "name": "Tibasosa",
        "code_depar": "15000"
    },
    {
        "code": "25807",
        "name": "Tibirita",
        "code_depar": "25000"
    },
    {
        "code": "54810",
        "name": "Tibu",
        "code_depar": "54000"
    },
    {
        "code": "23807",
        "name": "Tierralta",
        "code_depar": "23000"
    },
    {
        "code": "41807",
        "name": "Timana",
        "code_depar": "41000"
    },
    {
        "code": "19807",
        "name": "Timbio",
        "code_depar": "19000"
    },
    {
        "code": "19809",
        "name": "Timbiqui",
        "code_depar": "19000"
    },
    {
        "code": "15808",
        "name": "Tinjaca",
        "code_depar": "15000"
    },
    {
        "code": "15810",
        "name": "Tipacoque",
        "code_depar": "15000"
    },
    {
        "code": "13810",
        "name": "Tiquisio",
        "code_depar": "13000"
    },
    {
        "code": "5809",
        "name": "Titiribi",
        "code_depar": "5000"
    },
    {
        "code": "15814",
        "name": "Toca",
        "code_depar": "15000"
    },
    {
        "code": "25815",
        "name": "Tocaima",
        "code_depar": "25000"
    },
    {
        "code": "25817",
        "name": "Tocancipa",
        "code_depar": "25000"
    },
    {
        "code": "15816",
        "name": "Togui",
        "code_depar": "15000"
    },
    {
        "code": "5819",
        "name": "Toledo",
        "code_depar": "5000"
    },
    {
        "code": "54820",
        "name": "Toledo",
        "code_depar": "54000"
    },
    {
        "code": "70823",
        "name": "Toluviejo",
        "code_depar": "70000"
    },
    {
        "code": "68820",
        "name": "Tona",
        "code_depar": "68000"
    },
    {
        "code": "15820",
        "name": "Topaga",
        "code_depar": "15000"
    },
    {
        "code": "25823",
        "name": "Topaipi",
        "code_depar": "25000"
    },
    {
        "code": "19821",
        "name": "Toribio",
        "code_depar": "19000"
    },
    {
        "code": "76823",
        "name": "Toro",
        "code_depar": "76000"
    },
    {
        "code": "15822",
        "name": "Tota",
        "code_depar": "15000"
    },
    {
        "code": "19824",
        "name": "Totoro",
        "code_depar": "19000"
    },
    {
        "code": "85430",
        "name": "Trinidad",
        "code_depar": "85000"
    },
    {
        "code": "76828",
        "name": "Trujillo",
        "code_depar": "76000"
    },
    {
        "code": "8832",
        "name": "Tubara",
        "code_depar": "8000"
    },
    {
        "code": "76834",
        "name": "Tulua",
        "code_depar": "76000"
    },
    {
        "code": "52835",
        "name": "Tumaco",
        "code_depar": "52000"
    },
    {
        "code": "15001",
        "name": "Tunja",
        "code_depar": "15000"
    },
    {
        "code": "15832",
        "name": "Tunungua",
        "code_depar": "15000"
    },
    {
        "code": "52838",
        "name": "Tuquerres",
        "code_depar": "52000"
    },
    {
        "code": "13836",
        "name": "Turbaco",
        "code_depar": "13000"
    },
    {
        "code": "13838",
        "name": "Turbana",
        "code_depar": "13000"
    },
    {
        "code": "5837",
        "name": "Turbo",
        "code_depar": "5000"
    },
    {
        "code": "15835",
        "name": "Turmeque",
        "code_depar": "15000"
    },
    {
        "code": "15837",
        "name": "Tuta",
        "code_depar": "15000"
    },
    {
        "code": "15839",
        "name": "Tutasa",
        "code_depar": "15000"
    },
    {
        "code": "25839",
        "name": "Ubala",
        "code_depar": "25000"
    },
    {
        "code": "25841",
        "name": "Ubaque",
        "code_depar": "25000"
    },
    {
        "code": "76845",
        "name": "Ulloa",
        "code_depar": "76000"
    },
    {
        "code": "15842",
        "name": "Umbita",
        "code_depar": "15000"
    },
    {
        "code": "25845",
        "name": "Une",
        "code_depar": "25000"
    },
    {
        "code": "27800",
        "name": "Unguia",
        "code_depar": "27000"
    },
    {
        "code": "27810",
        "name": "Unión Panamericana",
        "code_depar": "27000"
    },
    {
        "code": "5842",
        "name": "Uramita",
        "code_depar": "5000"
    },
    {
        "code": "44847",
        "name": "Uribia",
        "code_depar": "44000"
    },
    {
        "code": "5847",
        "name": "Urrao",
        "code_depar": "5000"
    },
    {
        "code": "44855",
        "name": "Urumita",
        "code_depar": "44000"
    },
    {
        "code": "8849",
        "name": "Usiacuri",
        "code_depar": "8000"
    },
    {
        "code": "25851",
        "name": "Utica",
        "code_depar": "25000"
    },
    {
        "code": "5854",
        "name": "Valdivia",
        "code_depar": "5000"
    },
    {
        "code": "23855",
        "name": "Valencia",
        "code_depar": "23000"
    },
    {
        "code": "68855",
        "name": "Valle De San Jose",
        "code_depar": "68000"
    },
    {
        "code": "73854",
        "name": "Valle De San Juan",
        "code_depar": "73000"
    },
    {
        "code": "86865",
        "name": "Valle Del Guamuez",
        "code_depar": "86000"
    },
    {
        "code": "20001",
        "name": "Valledupar",
        "code_depar": "20000"
    },
    {
        "code": "5856",
        "name": "Valparaiso",
        "code_depar": "5000"
    },
    {
        "code": "18860",
        "name": "Valparaiso",
        "code_depar": "18000"
    },
    {
        "code": "5858",
        "name": "Vegachi",
        "code_depar": "5000"
    },
    {
        "code": "68861",
        "name": "Velez",
        "code_depar": "68000"
    },
    {
        "code": "73861",
        "name": "Venadillo",
        "code_depar": "73000"
    },
    {
        "code": "5861",
        "name": "Venecia",
        "code_depar": "5000"
    },
    {
        "code": "25506",
        "name": "Venecia",
        "code_depar": "25000"
    },
    {
        "code": "15861",
        "name": "Ventaquemada",
        "code_depar": "15000"
    },
    {
        "code": "25862",
        "name": "Vergara",
        "code_depar": "25000"
    },
    {
        "code": "76863",
        "name": "Versalles",
        "code_depar": "76000"
    },
    {
        "code": "68867",
        "name": "Vetas",
        "code_depar": "68000"
    },
    {
        "code": "25867",
        "name": "Viani",
        "code_depar": "25000"
    },
    {
        "code": "17867",
        "name": "Victoria",
        "code_depar": "17000"
    },
    {
        "code": "5873",
        "name": "Vigia Del Fuerte",
        "code_depar": "5000"
    },
    {
        "code": "76869",
        "name": "Vijes",
        "code_depar": "76000"
    },
    {
        "code": "54871",
        "name": "Villa Caro",
        "code_depar": "54000"
    },
    {
        "code": "15407",
        "name": "Villa De Leyva",
        "code_depar": "15000"
    },
    {
        "code": "54874",
        "name": "Villa Del Rosario",
        "code_depar": "54000"
    },
    {
        "code": "19845",
        "name": "Villa Rica",
        "code_depar": "19000"
    },
    {
        "code": "86885",
        "name": "Villagarzon",
        "code_depar": "86000"
    },
    {
        "code": "25871",
        "name": "Villagomez",
        "code_depar": "25000"
    },
    {
        "code": "73870",
        "name": "Villahermosa",
        "code_depar": "73000"
    },
    {
        "code": "17873",
        "name": "Villamaria",
        "code_depar": "17000"
    },
    {
        "code": "13873",
        "name": "Villanueva",
        "code_depar": "13000"
    },
    {
        "code": "68872",
        "name": "Villanueva",
        "code_depar": "68000"
    },
    {
        "code": "44874",
        "name": "Villanueva",
        "code_depar": "44000"
    },
    {
        "code": "85440",
        "name": "Villanueva",
        "code_depar": "85000"
    },
    {
        "code": "25873",
        "name": "Villapinzon",
        "code_depar": "25000"
    },
    {
        "code": "73873",
        "name": "Villarrica",
        "code_depar": "73000"
    },
    {
        "code": "50001",
        "name": "Villavicencio",
        "code_depar": "50000"
    },
    {
        "code": "41872",
        "name": "Villavieja",
        "code_depar": "41000"
    },
    {
        "code": "25875",
        "name": "Villeta",
        "code_depar": "25000"
    },
    {
        "code": "25878",
        "name": "Viota",
        "code_depar": "25000"
    },
    {
        "code": "15879",
        "name": "Viracacha",
        "code_depar": "15000"
    },
    {
        "code": "50711",
        "name": "Vista Hermosa",
        "code_depar": "50000"
    },
    {
        "code": "17877",
        "name": "Viterbo",
        "code_depar": "17000"
    },
    {
        "code": "25885",
        "name": "Yacopi",
        "code_depar": "25000"
    },
    {
        "code": "52885",
        "name": "Yacuanquer",
        "code_depar": "52000"
    },
    {
        "code": "41885",
        "name": "Yaguara",
        "code_depar": "41000"
    },
    {
        "code": "5885",
        "name": "Yali",
        "code_depar": "5000"
    },
    {
        "code": "5887",
        "name": "Yarumal",
        "code_depar": "5000"
    },
    {
        "code": "97889",
        "name": "Yavarate",
        "code_depar": "97000"
    },
    {
        "code": "5890",
        "name": "Yolombo",
        "code_depar": "5000"
    },
    {
        "code": "5893",
        "name": "Yondo",
        "code_depar": "5000"
    },
    {
        "code": "85001",
        "name": "Yopal",
        "code_depar": "85000"
    },
    {
        "code": "76890",
        "name": "Yotoco",
        "code_depar": "76000"
    },
    {
        "code": "76892",
        "name": "Yumbo",
        "code_depar": "76000"
    },
    {
        "code": "13894",
        "name": "Zambrano",
        "code_depar": "13000"
    },
    {
        "code": "68895",
        "name": "Zapatoca",
        "code_depar": "68000"
    },
    {
        "code": "47960",
        "name": "Zapayán",
        "code_depar": "47000"
    },
    {
        "code": "5895",
        "name": "Zaragoza",
        "code_depar": "5000"
    },
    {
        "code": "76895",
        "name": "Zarzal",
        "code_depar": "76000"
    },
    {
        "code": "15897",
        "name": "Zetaquira",
        "code_depar": "15000"
    },
    {
        "code": "25898",
        "name": "Zipacon",
        "code_depar": "25000"
    },
    {
        "code": "25899",
        "name": "Zipaquira",
        "code_depar": "25000"
    },
    {
        "code": "47980",
        "name": "Zona Bananera",
        "code_depar": "47000"
    }
];