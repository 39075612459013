import React from "react";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import NoteIcon from "@material-ui/icons/Note";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import GroupIcon from "@material-ui/icons/Group";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink to="/test" color="transparent" className={classes.navLink}>
          <LocalHospitalIcon className={classes.icons} /> Autoevaluación
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to="/noticias" color="transparent" className={classes.navLink}>
          <NewReleasesIcon className={classes.icons} /> Noticias
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to="/extras" color="transparent" className={classes.navLink}>
          <NoteIcon className={classes.icons} /> Curiosea
        </NavLink>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        <NavLink to="/graficas" color="transparent" className={classes.navLink}>
          <ShowChartIcon className={classes.icons} /> Graficas
        </NavLink>
  </ListItem>*/}
      <ListItem className={classes.listItem}>
        <NavLink to="/faq" color="transparent" className={classes.navLink}>
          <LiveHelpIcon className={classes.icons} /> FAQ
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to="/about-us" color="transparent" className={classes.navLink}>
          <GroupIcon className={classes.icons} /> Nosotros
        </NavLink>
      </ListItem>
    </List>
  );
}
