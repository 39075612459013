import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import firebase from "firebase/app";
import "firebase/auth";
import { validateEmail } from "../../config/Validation";
import { cardTitle } from "assets/jss/material-kit-react.js";


const useStyles = makeStyles({
    color: {
        color: "#000"
    },
    colorWhite: {
        color: "#fff",
        backgroundColor: "#40f",
        fontWeight: "bold"
    },
    textCenter: {
        textAlign: "center"
    },
    cardTitle
});

export default function LoginAdmin(props) {
    const classes = useStyles();

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const submit = async e => {
        e.preventDefault();
        if (!user || !password) {
            alert("Debe diligenciar los campos");
        } else {
            if (!validateEmail(user)) {
                alert("El formato del correo es incorrecto");
            } else {
                await firebase
                    .auth()
                    .signInWithEmailAndPassword(user, password)
                    /*.then(() => { NO ES NECESARIO EL THEN
                        props.history.push("admin-form");
                    })*/
                    .catch(error => {
                        alert("Email o contraseña incorrecta!.");
                    });
            }
        }
    };

    return (
        <div>
            <GridContainer>
                <GridItem md={3} lg={3} />
                <GridItem xs={12} md={6} sm={12} lg={6}>
                    <form onSubmit={e => submit(e)}>
                        <Card className={classes.textCenter}>
                            <CardHeader className={classes.colorWhite}>
                                <h2>Administración</h2>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Correo electrónico"
                                    name="user"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "email",
                                        name: "user",
                                        onChange: e => setUser(e.target.value),
                                        required: true,
                                        value: user
                                    }}
                                />
                                <CustomInput
                                    labelText="Contraseña"
                                    name="password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "password",
                                        name: "password",
                                        onChange: e => setPassword(e.target.value),
                                        required: true,
                                        value: password
                                    }}
                                />
                                <Button type="submit" color="facebook">
                                    Ingresar
                </Button>
                            </CardBody>
                        </Card>
                    </form>
                </GridItem>
                <GridItem md={3} lg={3} />
            </GridContainer>
        </div>
    );
}
