import React, { useState } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isMobile } from "react-device-detect";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Radio from "components/Radio/Index";
import { questions } from "variables/questions";
import Button from "components/CustomButtons/Button.js";
import Loader from "react-loader-spinner";
import { scrollToTop } from "functions/scrollToTop";

const Questions = props => {
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (id, answer) => {
    setAnswers({
      ...answers,
      [id]: answer
    });
  };

  const sendData = () => {
    setLoading(true);
    props.saveData(answers);
  };
  const cancelAll = () => {
    if (
      !(
        localStorage.getItem("firstTest") !== undefined &&
        localStorage.getItem("firstTest") !== null &&
        localStorage.getItem("firstTest") === "yes"
      )
    ) {
      localStorage.clear();
    }
    props.setStep("start");
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const ready = Object.keys(answers).length === questions.length;

  return (
    <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <div
          style={{ cursor: "pointer" }}
          onClick={cancelAll}
        >
          <KeyboardBackspaceIcon />
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h2>¿Qué síntomas tienes?</h2>
        <span style={{ fontFamily: "arial" }}>
          Responde a las siguientes preguntas para ayudarnos con la evaluación
        </span>
      </GridItem>
      {questions.map(obj => (
        <GridItem
          xs={12}
          md={12}
          sm={12}
          lg={12}
          key={obj.id}
          style={{
            paddingTop: 30
          }}
        >
          <h5>{obj.text}</h5>
          <Radio id={obj.id} handleChange={handleChange} />
        </GridItem>
      ))}
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "left", paddingTop: 40 }}
      >
        <div style={{ textAlign: "center" }}>
          {loading && (
            <Loader type="BallTriangle" color="#000" height={50} width={50} />
          )}
          <br></br>
          <Button
            disabled={!ready || loading}
            size={"lg"}
            fullWidth
            color="facebook"
            onClick={sendData}
          >
            {"Continuar"}
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default Questions;
