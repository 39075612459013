import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import firebase from "firebase/app";
import FirebaseConfig from "config/FirebaseConfig";

import News from "./News";
import Persons from "./Persons";
import Quiz from "./Quiz";
import Total from "./Total";




const db = firebase.firestore(FirebaseConfig);
const HomeAdmin = () => {
    const [value, setValue] = React.useState(0);

    const getComponent = () => {
        switch (value) {
            case 0:
                return <News />
            case 1:
                return <Persons db={db} />
            case 2:
                return <Quiz db={db} />
            case 3:
                return <Total db={db} />
            default:
                return "Error."
        }
    }
    return (
        <>
            <Tabs
                value={value}
                onChange={(e, newValue) => setValue(newValue)}
                variant="scrollable"
            >
                <Tab label="Noticias" />
                <Tab label="Personas" />
                <Tab label="Quiz" />
                <Tab label="Totales" />
            </Tabs>
            {getComponent()}
        </>
    );
};

export default HomeAdmin;