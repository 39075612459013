import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isMobile } from "react-device-detect";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import RadioGender from "components/Radio/Gender";
import { towns } from "variables/towns";
import { deparments } from "variables/deparments";
import { scrollToTop } from "functions/scrollToTop";

const useStyles = makeStyles({
  gridCenter: {
    alignSelf: "center",
    textAlign: "center"
  }
});
const PersonalForm = props => {
  const classes = useStyles();
  const [names, setNames] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [numberDocument, setNumberDocument] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [town, setTown] = useState("");
  const [deparment, setDeparment] = useState("");

  const sendData = () => {
    const person = {
      names,
      typeDocument,
      numberDocument,
      gender,
      age,
      town,
      deparment,
    };
    props.setPersonData(person);
    props.setStep("questions");
  };

  const cancelAll = () => {
    localStorage.clear();
    props.setStep("start");
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <div
          style={{ cursor: "pointer" }}
          onClick={cancelAll}
        >
          <KeyboardBackspaceIcon />
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h2>Introduce tus datos personales</h2>
        <span style={{ fontFamily: "arial" }}>
          De esta manera realizamos una correcta evolución de tu estado de salud.
        </span>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingTop: 40 }}>
        <div style={{ width: isMobile ? "100%" : "40%" }}>
          <CustomInput
            labelText="Nombres y apellidos"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              value: names,
              onChange: e => setNames(e.target.value)
            }}
          />
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ paddingTop: 20 }}>
        <div style={{ width: isMobile ? "100%" : "40%" }}>
          <CustomInput
            labelText="Edad"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "number",
              value: age,
              onChange: e => setAge(e.target.value)
            }}
          />
        </div>
      </GridItem>
      <GridItem
        xs={12}
        md={6}
        sm={6}
        lg={6}
        className={classes.gridCenter}
        style={{ paddingTop: 40 }}
      >
        <select
          className={"select-css"}
          value={typeDocument}
          onChange={e => setTypeDocument(e.target.value)}
        >
          <option value={""}>{"Selecciona tu tipo de documento"}</option>
          <option value="CC">Cédula de ciudadanía</option>
          <option value="RC">Registro civil</option>
          <option value="TI">Tarjeta de identidad</option>
          <option value="CE">Cédula de extranjería</option>
          <option value="PP">Pasaporte</option>
          <option value="NIT">NIT</option>
        </select>
      </GridItem>
      <GridItem
        xs={12}
        md={6}
        sm={6}
        lg={6}
        className={classes.gridCenter}
        style={{ paddingTop: 40 }}
      >
        <CustomInput
          labelText="Número de documento"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "number",
            value: numberDocument,
            onChange: e => setNumberDocument(e.target.value)
          }}
        />
      </GridItem>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ alignSelf: "center", paddingTop: 20 }}
      >
        <span style={{ float: "left", fontSize: 14, color: "#aaa" }}>Sexo</span><br></br>
        <RadioGender id={0} handleChange={(id, value) => setGender(value)} />
      </GridItem>
      <GridItem
        xs={12}
        md={6}
        sm={6}
        lg={6}
        className={classes.gridCenter}
        style={{ paddingTop: 40 }}
      >
        <select
          className={"select-css"}
          value={deparment}
          onChange={e => setDeparment(e.target.value)}
        >
          <option value={""}>{"Seleccione tu departamento"}</option>
          {deparments.map(obj => (
            <option value={obj.code} key={obj.code}>
              {obj.name}
            </option>
          ))}
        </select>
      </GridItem>
      <GridItem
        xs={12}
        md={6}
        sm={6}
        lg={6}
        className={classes.gridCenter}
        style={{ paddingTop: 40 }}
      >
        <select
          className={"select-css"}
          value={town}
          onChange={e => setTown(e.target.value)}
        >
          <option value={""}>{"Seleccione tu municipio"}</option>
          {towns
            .filter(obj => parseInt(obj.code_depar) === parseInt(deparment))
            .map(obj => (
              <option value={obj.code} key={obj.code}>
                {obj.name}
              </option>
            ))}
        </select>
      </GridItem>

      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "left", paddingTop: 40 }}
      >
        <div style={{ flexDirection: "row", display: "flex" }}>
          <Button
            round
            disabled={
              !(
                names &&
                age &&
                typeDocument &&
                numberDocument &&
                gender &&
                deparment &&
                town
              )
            }
            fullWidth
            color="facebook"
            size={"lg"}
            onClick={sendData}
          >
            {"Continuar"}
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default PersonalForm;
