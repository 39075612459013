import React, { useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { isMobile } from 'react-device-detect';
//import { getCorrectAnswer } from "functions/getCorrectAnswer";
import Recommendation from "./recommendations/Recommendation";
import { recommendations } from "variables/recommendations";
import Button from "components/CustomButtons/Button.js";
import { getCorrectAnswer } from "functions/getCorrectAnswer";
import Loader from 'react-loader-spinner';
import { scrollToTop } from "functions/scrollToTop";


const MedicalAttention = (props) => {
  const [recommen, setRecommen] = useState([]);
  const [recommenKeeper, setRecommenKeeper] = useState([]);
  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));


  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    (async () => {
      //buscamos el ultimo auto-test
      const idTest = await props.db.collection("auto-test")
        .where("email", "==", user.email)
        .orderBy("date", "desc")
        .limit(1)
        .get()
        .then(querySnapshot => {
          console.log("ultimo test: " + querySnapshot.docs[0].id);
          return querySnapshot.docs[0].id;
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });

      props.db.collection("auto-test").doc(idTest).get().then((doc) => {
        if (doc.exists) {
          const correctAnswers = getCorrectAnswer(doc.data().answers);
          setRecommen(getRecommen(correctAnswers));
          setRecommenKeeper(getRecommenKeeper(correctAnswers));
          setLoading(false);
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    })();
  }, []);// eslint-disable-line 








  const getRecommen = (correctAnswers) => {
    switch (correctAnswers) {
      case 0:
        return [1, 2, 3, 6, 7, 8, 13, 15, 18];
      case 1:
        return [1, 2, 3, 6, 7, 8, 13, 15, 18];
      case 2:
        return [1, 2, 3, 6, 7, 8, 13, 15, 18];
      case 3:
        return [1, 2, 3, 6, 7, 8, 13, 15, 18];
      case 4:
        return [1, 2, 3, 6, 7, 8, 13, 15, 18];
      case 5:
        return [2, 4, 5, 6, 7, 9, 12, 14, 15, 18, 19]
      case 6:
        return [2, 4, 5, 6, 7, 9, 12, 14, 15, 18, 19]
      case 7:
        return [4, 5, 6, 9, 18]
      case 8:
        return [4, 5, 6, 9, 18]
      default:
        return [];
    }
  }

  //10,11,16,17
  const getRecommenKeeper = (correctAnswers) => {
    switch (correctAnswers) {
      case 0:
        return [11, 16];
      case 1:
        return [11, 16];
      case 2:
        return [11, 16];
      case 3:
        return [11, 16];
      case 4:
        return [11, 16];
      case 5:
        return [16];
      case 6:
        return [16];
      case 7:
        return [10, 16, 17]
      case 8:
        return [10, 16, 17]
      default:
        return [];
    }
  }

  const recommendationsFiltered = recommendations.filter(obj => recommen.includes(obj.id));
  const recommendationsKeeperFiltered = recommendations.filter(obj => recommenKeeper.includes(obj.id));

  return (
    <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h3>Tus Recomendaciones</h3>
        <span style={{ fontFamily: "arial" }}>De acuerdo a tu resultado te aconsejamos lo siguiente:</span>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}><hr></hr><br></br></GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center", color: "#0044FF" }}>
        <h3>Recomendaciones para ti</h3>
      </GridItem>

      {loading &&
        <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
          <Loader
            type="BallTriangle"
            color="#0044FF"
            height={50}
            width={50}
          />
        </GridItem>}
      {recommendationsFiltered.map(obj =>
        <GridItem xs={12} md={6} sm={12} lg={6} key={obj.id} style={{ paddingBottom: 50 }}>
          <Recommendation
            data={obj}
          />
          <hr></hr>
        </GridItem>
      )}
      <GridItem xs={12} md={12} sm={12} lg={12}><br></br><hr></hr></GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center", color: "#0044FF" }}>
        <h4><b>Para las personas con las que vives</b></h4>
      </GridItem>
      {recommendationsKeeperFiltered.map(obj =>
        <GridItem xs={12} md={6} sm={12} lg={6} key={obj.id} style={{ paddingBottom: 50 }}>
          <Recommendation
            data={obj}
          />
          <hr></hr>
        </GridItem>
      )}
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "left", paddingTop: 40 }}>
        <Button
          fullWidth
          size={"lg"}
          style={{ backgroundColor: "#0044FF" }}
          onClick={() => props.setStep("auto-test-final")}
        >
          {"Continuar"}
        </Button>

      </GridItem>
    </GridContainer >
  );
};

export default MedicalAttention;