import React from "react";
import ReactDOM from "react-dom";

import "assets/css/comun.css";
import "assets/scss/material-kit-react.scss?v=1.8.0";

import App from "App";

//variable temporal modal
sessionStorage.removeItem("modal");

ReactDOM.render(<App />, document.getElementById("root"));

