import firebase from 'firebase/app';
firebase.initializeApp({
    apiKey: "AIzaSyD6myasQC_nhxvWsWzuNGf3V-9ZTEYTyAo",
    authDomain: "coronavirus-col-79855.firebaseapp.com",
    databaseURL: "https://coronavirus-col-79855.firebaseio.com",
    projectId: "coronavirus-col-79855",
    storageBucket: "coronavirus-col-79855.appspot.com",
    messagingSenderId: "1007321405034",
    appId: "1:1007321405034:web:2d0c4d1d0a4afa96891c13",
    measurementId: "G-4CPVBSLQTW"
});
export default firebase;