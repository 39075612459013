export const deparments =
    [
        {
            "code": "91000",
            "name": "Amazonas",
            "phone": "3153615018"
        },
        {
            "code": "5000",
            "name": "Antioquia",
            "phone": "3003050295/123"
        },
        {
            "code": "81000",
            "name": "Arauca ",
            "phone": "3504120127/125"
        },
        {
            "code": "8000",
            "name": "Atlantico",
            "phone": "(035)3236220/3175173964"
        },
        {
            "code": "11000",
            "name": "Bogota D.C.",
            "phone": "123"
        },
        {
            "code": "13000",
            "name": "Bolivar",
            "phone": "123"
        },
        {
            "code": "15000",
            "name": "Boyaca",
            "phone": "7407464/3114834101"
        },
        {
            "code": "17000",
            "name": "Caldas",
            "phone": "(6)8800818/3102467906"
        },
        {
            "code": "18000",
            "name": "Caqueta",
            "phone": "(098)4352160"
        },
        {
            "code": "85000",
            "name": "Casanare",
            "phone": "(8)6345555/3213945317"
        },
        {
            "code": "19000",
            "name": "Cauca",
            "phone": "3012737787/192"
        },
        {
            "code": "20000",
            "name": "Cesar",
            "phone": "3205653159"
        },
        {
            "code": "23000",
            "name": "Cordoba",
            "phone": "3205308209/3168751277"
        },
        {
            "code": "27000",
            "name": "Choco",
            "phone": "3213945297"
        },
        {
            "code": "25000",
            "name": "Cundinamarca",
            "phone": "123"
        },
        {
            "code": "41000",
            "name": "Huila",
            "phone": "8706633/8702277"
        },
        {
            "code": "44000",
            "name": "Guajira",
            "phone": "3183400883/3213945330"
        },
        {
            "code": "47000",
            "name": "Magdalena",
            "phone": "3012737783/3213944087"
        },
        {
            "code": "50000",
            "name": "Meta",
            "phone": "3213945351"
        },
        {
            "code": "52000",
            "name": "Nariño",
            "phone": "3177129818"
        },
        {
            "code": "54000",
            "name": "Norte Santander",
            "phone": "3202714512"
        },
        {
            "code": "63000",
            "name": "Quindio",
            "phone": "3225816422"
        },
        {
            "code": "66000",
            "name": "Risaralda",
            "phone": "3152823059"
        },
        {
            "code": "68000",
            "name": "Santander",
            "phone": "6798785/6978788"
        },
        {
            "code": "70000",
            "name": "Sucre",
            "phone": "2822556/3174028049"
        },
        {
            "code": "73000",
            "name": "Tolima",
            "phone": "3228123975"
        },
        {
            "code": "76000",
            "name": "Valle",
            "phone": "6206819"
        },
        {
            "code": "86000",
            "name": "Putumayo",
            "phone": "3123191736/3118784864"
        },
        {
            "code": "88000",
            "name": "Arch.Sn Andres Y Pro",
            "phone": "3106511341"
        },
        {
            "code": "94000",
            "name": "Guainia",
            "phone": "(8)5656251/3117421883"
        },
        {
            "code": "95000",
            "name": "Guaviare",
            "phone": "3213946560/3213945350"
        },
        {
            "code": "97000",
            "name": "Vaupes",
            "phone": "3213945341"
        },
        {
            "code": "99000",
            "name": "Vichada",
            "phone": "3222476344"
        }
    ];