import { deparments } from "variables/deparments";
import { towns } from "variables/towns";

export function getDeparment(idDeparment) {
    try {
        const dpts = deparments.filter(obj => parseInt(obj.code) === parseInt(idDeparment));
        return dpts[0].name;
    } catch (error) {
        console.log(error);
        console.log("Error: (getDeparment)=> " + idDeparment);
    }
    return "NO_DISPONIBLE";

}

export function getTowns(idTown) {
    try {
        const dpts = towns.filter(obj => parseInt(obj.code) === parseInt(idTown));
        return dpts[0].name;
    } catch (error) {
        console.log(error);
        console.log("Error: (getTowns)=> " + idTown);
    }
    return "NO_DISPONIBLE";
}