import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { isMobile } from 'react-device-detect';

const sizeIcon = 25;
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: sizeIcon,
    height: sizeIcon,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0044FF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: sizeIcon,
      height: sizeIcon,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

// Inspired by blueprintjs
const StyledRadio = (props) => {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}


const RadioQuiz = (props) => {
  const [value, setValue] = React.useState("");
  const handleChange = event => {
    setValue(event.target.value);
    props.handleChange(props.id, event.target.value);
  };
  //props.answers
  const abcd = ["A", "B", "C", "D"];
  return (
    <FormControl component="fieldset">
      <RadioGroup value={value} onChange={handleChange}>
        {props.answers.map((obj, key) =>
          <FormControlLabel
            key={key}
            value={abcd[key]}
            control={<StyledRadio />}
            label={<span style={{ color: "#000" }}>{obj}</span>}
            style={{ backgroundColor: value === abcd[key] ? "rgb(0,68,255,.3)" : "", width: (props.id === 7 && !isMobile ? 600 : 300), borderRadius: 10 }}
          />
        )}
      </RadioGroup>
    </FormControl>
  );
}


export default RadioQuiz;