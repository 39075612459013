import React from 'react';
import SimpleAccordion from "simple-react-accordion";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const Index = () => {


    const questions = [
        {
            header: "¿Quiénes somos y porque lo hacemos?",
            content:
                "Somos 4 emprendedores colombianos que decidieron aportar a la solución. Queremos ayudar a nuestro país a enfrentar al COVID19. Esta propuesta es 100% sin ánimo de lucro y Los recursos con los que trabajamos son propios."
        },
        {
            header: "¿Por qué debes aportar tus datos personales?",
            content:
                "Es necesario generar bases de datos con la evolución del estado de salud de cada paciente. Esto permite focalizar problemas y presentar soluciones. No haremos nada más con los datos. Todo lo puedes leer en nuestra política de privacidad."
        },
        {
            header: "¿Has encontrado un fallo en nuestra seguridad o un Bug?",
            content:
                "Estaremos muy agradecidos de que nos informes cualquier fallo que encuentres en el sistema. Puede escribirnos al correo contacto@coronaviruscol.com. De esta manera mejoraremos el servicio para todos los colombianos."
        },
        {
            header: "¿Quieres ayudar? ¿Tienes ideas?",
            content: "No dudes en comunicarlo, escríbenos al correo contacto@coronaviruscol.com. Te contactaremos de una. Cualquier ayuda es bienvenida."
        },
        {
            header: "¿Con quién puedes hablar si tienes dudas sobre tu estado de salud?",
            content: "Realiza la autoevaluación indicando en tus datos personales el departamento donde estas. Luego de esto te entregaremos recomendaciones personalizadas y te daremos el número de emergencias de tu zona."
        },
        {
            header: "Somos un servicio colombiano",
            content: "Por el momento solo funcionamos en Colombia, escríbenos si en tu país no tienen una solución similar al correo contacto@coronaviruscol.com. Haremos todo lo posible por ayudarte."
        },
    ];
    return (
        <GridContainer>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <h4>Preguntas frecuentes</h4>
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <SimpleAccordion theme="professional" items={questions} />
            </GridItem>

        </GridContainer>
    );
};

export default Index;