import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isMobile } from "react-device-detect";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import { getCorrectAnswer } from "functions/getCorrectAnswer";
import RecommendationsByCorrectAnswer from "./recommendations/RecommendationsByCorrectAnswer";
import { scrollToTop } from "functions/scrollToTop";



const GeneralResult = props => {
  const [correctAnswers, setCorrectAnswers] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    (async () => {
      //buscamos el ultimo auto-test
      const idTest = await props.db
        .collection("auto-test")
        .where("email", "==", user.email)
        .orderBy("date", "desc")
        .limit(1)
        .get()
        .then(querySnapshot => {
          console.log("ultimo test: " + querySnapshot.docs[0].id);
          return querySnapshot.docs[0].id;
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });

      props.db
        .collection("auto-test")
        .doc(idTest)
        .get()
        .then(doc => {
          if (doc.exists) {
            setCorrectAnswers(getCorrectAnswer(doc.data().answers));
          } else {
            console.log("No such document!");
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    scrollToTop();
  }, []);

  const getColorByCorrectAnswers = () => {
    if (
      correctAnswers === 0 ||
      correctAnswers === 1 ||
      correctAnswers === 2 ||
      correctAnswers === 3 ||
      correctAnswers === 4
    ) {
      return "forestgreen";
    } else if (correctAnswers === 5 || correctAnswers === 6) {
      return "orange";
    } else {
      return "crimson";
    }

  }

  return (
    <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h2 style={{ fontWeight: "bold" }}>Tu diagnóstico</h2>
        <span style={{ color: "#000", fontFamily: "arial" }}>Sigue las instrucciones que aparecen a continuación:</span>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Card style={{ backgroundColor: getColorByCorrectAnswers(), color: "#fff" }}>
          <CardBody>
            <RecommendationsByCorrectAnswer correctAnswers={correctAnswers} />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Button
          fullWidth
          size={"lg"}
          style={{ backgroundColor: getColorByCorrectAnswers() }}
          onClick={() => props.setStep("medical-attention")}
        >
          {"Explícame qué hacer"}
        </Button>
      </GridItem>
    </GridContainer>
  );
};

export default GeneralResult;
