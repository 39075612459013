import React from 'react';

const Total = (props) => {

    const [tests, setTests] = React.useState(0);

    React.useEffect(() => {
        props.db.collection("auto-test")
            .get()
            .then(querySnapshot => {
                const tests = querySnapshot.docs.map(doc => doc.data());
                setTests(tests.length);
            });
    }, []);// eslint-disable-line 


    return (
        <div>
            <br></br><hr></hr>
            Total test: {tests}
        </div>
    );
};

export default Total;