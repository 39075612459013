import React, { useEffect, useState } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import HomeAdmin from './HomeAdmin';
import LoginAdmin from "./LoginAdmin";

const Index = () => {
    const [isAdminLogged, setIsAdminLogged] = useState(0);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            //esta funcion se ejecuta cada vez que el estado de la sesion cambia
            if (user) {
                setIsAdminLogged(1);
            } else {
                setIsAdminLogged(2);
            }
        });

    }, []);
    return isAdminLogged === 1 ? <HomeAdmin /> : (isAdminLogged === 2 ? <LoginAdmin /> : "Cargando...");
};
export default Index;