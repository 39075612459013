import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import TweetEmbed from "react-tweet-embed";
import { cardTitle } from "assets/jss/material-kit-react.js";
import FirebaseConfig from "config/FirebaseConfig";
import firebase from "firebase/app";
import "firebase/firestore";


const db = firebase.firestore(FirebaseConfig);

const useStyles = makeStyles({
  color: {
    color: "#000"
  },
  cardTitle,
  maxWidth: {
    width: "100%"
  }
});

const Index = () => {
  const classes = useStyles();
  const [reloadData, setReloadData] = useState(false);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    (async () => {
      db.collection("news")
        .orderBy("date", "desc")
        .get()
        .then(querySnapshot => {
          const news = querySnapshot.docs.map(doc => doc.data());
          setPosts(news.filter(post => post.active === true));
        });
    })();
    setReloadData(false);
  }, [reloadData]);

  return (
    <GridContainer className={classes.color}>
      {posts.map(post => (
        <GridItem key={post.idPost} xs={12} md={6} sm={12} lg={6}>
          <Card>
            <CardBody>
              <h4 className={classes.cardTitle}>{post.title}</h4>
              <p>{post.description}</p>
              <div className={classes.maxWidth}>
                <TweetEmbed id={post.idTwit} />
              </div>
            </CardBody>

          </Card>
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default Index;
