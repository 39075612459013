import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SimpleAccordion from "simple-react-accordion";
import Button from "components/CustomButtons/Button";
import { cardTitle } from "assets/jss/material-kit-react.js";
import oms from "assets/img/extra/oms.png";
import hands from "assets/img/extra/hands.jpg";
import "assets/css/acordion.css";

const useStyles = makeStyles({
  color: {
    color: "#000",
  },
  cardTitle,
  maxWidth: {
    width: "100%",
  },
  colorWhite: {
    color: "#fff",
    backgroundColor: "#000",
    fontWeight: "bold",
    fontFamily: "Inter",
  },
  font: {
    fontFamily: "Inter",
  },
});

const Links = () => {
  const classes = useStyles();

  const extras = [
    {
      title: "Coronavirus en el mundo",
      description:
        "En esta plataforma encontrarás información en tiempo real de cómo evoluciona el COVID19 en el mundo",
      link:
        "https://who.maps.arcgis.com/apps/webappviewer/index.html?id=2203b04c3a5f486685a15482a0d97a87&extent=-17277700.8881%2C-1043174.5225%2C-1770156.5897%2C6979655.9663%2C102100",
      img: oms,
    },
    {
      title: "Fundación Manos Pintadas de Azul",
      description:
        "Son un grupo brutal de profesionales de la salud que brindan su tiempo y conocimiento para formar parte de la solución. Ellos manejan un Chat de WhatsApp que responde dudas sobre tu estado de salud respecto al COVID19 totalmente gratis.",
      link: "http://www.manospintadasdeazul.com",
      img: hands,
    },
  ];

  const rumors = [
    {
      header: "El COVID19 no puede transmitirse en clima cálido",
      content:
        "Falso, Las pruebas científicas obtenidas hasta ahora indican que el virus de la COVID-19 puede transmitirse en CUALQUIER ZONA, incluidas las de clima cálido y húmedo.",
    },
    {
      header: "¿El CORONAVIRUS puede transmitirse por picaduras de mosquitos?",
      content:
        "Falso, El nuevo coronavirus es un virus respiratorio que se propaga principalmente por contacto con una persona infectada a través de las gotículas respiratorias que se generan cuando esta persona tose o estornuda (por saliva o secreciones de la nariz), Hasta la fecha no hay información ni pruebas que indiquen que el 2019-nCoV pueda transmitirse por medio de mosquitos.",
    },
    {
      header: "¿Existe algún medicamento para tratar el COVID19?",
      content:
        "No, por el momento, no se recomienda ningún medicamento específico para prevenir o tratar la infección por el nuevo coronavirus (2019-nCoV).",
    },
    {
      header: "¿El CORONAVIRUS puede llegar hasta 8 metros con un estornudo?",
      content:
        "No, el coronavirus tiene un tamaño grande, además de ser pesado comparado con otros virus, a lo sumo puede volar 1.5m luego de un estornudo",
    },
    {
      header: "Aguantar la respiración por 10 segundos no representa nada",
      content:
        "Muchas personas hablan de un “TEST RAPIDO”, surgido en Taiwán que describe que si puedes aguantar la respiración 10 segundos sin toser no tienes el virus. FALSO",
    },
    {
      header: "¿Se pueden recibir paquetes de China?",
      content:
        "Si, según estudios realizados se demostró que el COVID19 no sobrevive mucho tiempo en superficies como paquetes o cartas.",
    },
    {
      header: "¿Los animales pueden propagar el coronavirus?",
      content:
        "No hay ninguna prueba que demuestre esto, más sin embargo procura lavar tus manos con agua y jabón luego de tocar un animal.",
    },
  ];

  const goOtherPage = (link) => {
    window.open(link, "_blank");
  };

  const space = (
    <GridItem xs={12} md={12} sm={12} lg={12}>
      <br></br>
      <br></br>
    </GridItem>
  );
  return (
    <NavPills
      tabs={[
        {
          tabButton: "Enlaces externos",
          tabContent: (
            <GridContainer className={classes.maxWidth}>
              <GridItem xs={12} md={12} sm={12} lg={12}>
                {extras.map((extra) => (
                  <Card key={extra.title}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} md={12} sm={12} lg={4}>
                          <img
                            alt={""}
                            width="250"
                            height="250"
                            src={extra.img}
                          />
                        </GridItem>
                        <GridItem xs={12} md={12} sm={12} lg={8}>
                          <h4 className={classes.cardTitle}>
                            <b>{extra.title}</b>
                          </h4>
                          <p>{extra.description}</p>
                          <Button
                            color="facebook"
                            onClick={() => goOtherPage(extra.link)}
                          >
                            {"Conoce más"}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                ))}
              </GridItem>
            </GridContainer>
          ),
        },
        {
          tabButton: "Rumores",
          tabContent: (
            <div>
              <GridContainer className={classes.maxWidth}>
                <GridItem xs={12} md={1} sm={12} lg={1}></GridItem>
                <GridItem xs={12} md={10} sm={12} lg={10}>
                  <h1>RUMORES DEL CORONAVIRUS</h1>
                  <p>
                    Acá encontraras algunas verdades sobre el COVID19 y otras
                    cosas que parecen imposible de creer, más sin embargo
                    algunas personas las dan por hecho y desinforman acerca de
                    este virus
                  </p>
                </GridItem>
                <GridItem xs={12} md={1} sm={12} lg={1}></GridItem>
                {space}
              </GridContainer>
              <SimpleAccordion theme="professional" items={rumors} />
            </div>
          ),
        },
      ]}
    />
  );
};

export default Links;
