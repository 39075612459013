import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { VERSION } from "config/version";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();

  const [version, setVersion] = React.useState(false);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <GridContainer style={{ color: "#000" }}>
          <GridItem xs={12} md={6} sm={12} lg={6} style={{ textAlign: isMobile ? "center" : "right" }}>
            <a
              rel="noopener noreferrer"
              href={"/docs/CONDICIONES%20DE%20USO%20CORONAVIRUSCOL.pdf"}
              target="_blank"
              style={{ color: "#000", fontFamily: "arial" }}
            >
              {"Términos y condiciones"}
            </a>
          </GridItem>
          <GridItem xs={12} md={6} sm={12} lg={6} style={{ textAlign: isMobile ? "center" : "left" }}>
            <a
              rel="noopener noreferrer"
              href={
                "/docs/POL%C3%8DTICA%20DE%20PRIVACIDAD%20CORONAVIRUSCOL.pdf"
              }
              target="_blank"
              style={{ color: "#000", fontFamily: "arial" }}
            >
              {"Aviso de privacidad"}
            </a>
          </GridItem>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            {version ?
              <span onClick={() => setVersion(!version)} style={{ fontSize: 10, color: "gray" }}>
                V {VERSION}
              </span> :
              <span onClick={() => setVersion(!version)}>
                &copy; {1900 + new Date().getYear()}
              </span>
            }
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
