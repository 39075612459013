import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const Recommendation = (props) => {
    const { data } = props;
    return (
        <GridContainer>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <div style={{ flexDirection: "row", display: "flex", textAlign: "center" }}>
                    <div style={{ alignSelf: "center", width: "100%", padding: 30, backgroundColor: "#E0E9FF", borderRadius: 10 }}>
                        <img alt="" src={data.image} />
                    </div>
                </div>
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <h5>{data.title}</h5>
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "justify" }}>
                {data.detail !== undefined && <span style={{ color: "gray", fontFamily: "arial" }}>{data.detail}</span>}
                {data.detailList !== undefined && (
                    <ul>
                        {data.detailList.map((obj, key) => <li key={key} style={{ color: "gray", fontFamily: "arial" }}>{obj}</li>)}
                    </ul>
                )}
            </GridItem>
        </GridContainer>
    );
};

export default Recommendation;