import React from 'react';
import MaterialTable from "material-table";
import * as moment from "moment";

const Quiz = (props) => {
    const [quices, setQuices] = React.useState([]);


    React.useEffect(() => {
        props.db.collection("interactive-test")
            .get()
            .then(querySnapshot => {
                const quices = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...data,
                        dateF: moment(data.date.toDate()).format("MM-DD-YYYY HH:mm A")
                    }
                });
                setQuices(quices);
            });
    }, []);// eslint-disable-line 

    return (
        <div>
            <MaterialTable
                isLoading={quices.length === 0}
                title={"Quiz interactivo"}
                columns={[
                    { title: 'email', field: 'email' },
                    { title: 'correctAnswer', field: 'correctAnswer' },
                    { title: 'date', field: 'dateF' },
                    { title: 'email', field: 'email' },
                ]}
                data={quices}
                localization={{
                    header: {
                        actions: 'Acciones'
                    },
                    toolbar: {
                        searchPlaceholder: "Buscar",
                    },
                }}
                options={{
                    exportAllData: true,
                    exportButton: true,
                }}
            />
        </div>
    );
};

export default Quiz;