export function getCorrectAnswer(answers) {
    let correctAnswers = 0;
    for (var prop in answers) {
        const indexAnswer = parseInt(prop);
        if ((indexAnswer >= 1 && indexAnswer <= 4) || indexAnswer === 6) {
            if (answers[prop] === "yes") {
                correctAnswers++;
            }
        } else {
            if (answers[prop] === "no") {
                correctAnswers++;
            }
        }
    }
    return correctAnswers;
}