import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";

import JohnPhoto from "assets/img/about-us/john.jpeg";
import JulianPhoto from "assets/img/about-us/julian.jpeg";
import EdiwnPhoto from "assets/img/about-us/edwin.jpeg";
import MauricioPhoto from "assets/img/about-us/mauricio.jpeg";
import Banner from "assets/img/about-us/banner.jpg";

import { cardTitle } from "assets/jss/material-kit-react.js";
import "assets/css/about-us.css";

const useStyles = makeStyles({
  color: {
    color: "#000",
  },
  instagram: {
    backgroundColor: "#962FBF",
    "&:hover": {
      backgroundColor: "#FEDA75",
    },
  },
  linkedin: {
    backgroundColor: "#0077B5",
    "&:hover": {
      backgroundColor: "#0077B5",
    },
  },
  banner: {
    backgroundImage: `url(${Banner})`,
    padding: 50,
    textAlign: "center",
    color: "white",
    width: "100%",
  },
  cardTitle,
});

const us = [
  {
    name: "John Bolaños",
    at: "@el_tal_john",
    instagramURL: "https://www.instagram.com/el_tal_john/",
    photo: JohnPhoto,
    ocupation: "DESARROLLADOR FULL STACK",
    description:
      "Ingeniero de sistemas, amante de las nuevas tecnologías y la investigación.",
    socialNetworks: {
      twitter: "https://twitter.com/jhonedwardb",
      facebook: "https://www.facebook.com/jhonedwardb",
      instagram: "https://www.instagram.com/el_tal_john/",
      linkedin: "https://www.linkedin.com/in/john-bola%C3%B1os-99b687159/",
    },
  },
  {
    name: "Julian Orduz",
    at: "@julian.orduz",
    instagramURL: "https://www.instagram.com/julian.orduz/",
    photo: JulianPhoto,
    ocupation: "CEO / CO-FUNDADOR",
    description: "Lider, Profesor, Futbolero, Ingenierío Mecánico.",
    socialNetworks: {
      twitter: "",
      facebook: "https://www.facebook.com/Julian.OrduzC",
      instagram: "https://www.instagram.com/julian.orduz/",
      linkedin: "https://www.linkedin.com/in/julian-orduz-cifuentes-76a037150",
    },
  },
  {
    name: "Edwin Acosta",
    at: "@_acosta_edwin",
    instagramURL: "https://www.instagram.com/_acosta_edwin/",
    photo: EdiwnPhoto,
    ocupation: "DESARROLLADOR FULL STACK / CO-FUNDADOR",
    description: "Joven emprendedor apasionado por la vida!.",
    socialNetworks: {
      twitter: "https://twitter.com/_acosta_edwin",
      facebook: "https://www.facebook.com/edwinmanuel.acostasalazar",
      instagram: "https://www.instagram.com/_acosta_edwin/",
      linkedin: "https://www.linkedin.com/in/acostaedwin/",
    },
  },
  {
    name: "Mauricio Castañeda",
    at: "@maurcastaneda",
    instagramURL: "https://www.instagram.com/maurcastaneda/",
    photo: MauricioPhoto,
    ocupation: "Industrial Design UX-IU / CO-FUNDADOR",
    description:
      "Diseñador Industrial empresario y emprendedor, consultor en innovación, diseño y marketing.",
    socialNetworks: {
      twitter: "",
      facebook: "https://www.facebook.com/mauricio.castaneda.3517",
      instagram: "https://www.instagram.com/maurcastaneda/",
      linkedin:
        "http://www.linkedin.com/in/mauricio-andr%C3%A9s-casta%C3%B1eda-acu%C3%B1a-376948163",
    },
  },
];

export default function AboutUs() {
  const classes = useStyles();

  const goSocial = (social) => {
    if (social) {
      window.open(social, "_blank");
    }
  };

  return (
    <>
      <div className={classes.banner}>
        <h1>Nosotros</h1>
        <p>4 jóvenes emprendedores con ganas de comerse el mundo.</p>
      </div>
      <GridContainer className={classes.color}>
        {us.map((i, index) => (
          <GridItem key={index} xs={12} md={3} sm={12} lg={3}>
            <Card>
              <CardHeader>
                <div className="button-container">
                  <a target="_blank" href={i.instagramURL} rel="noopener noreferrer">
                    {i.at}
                  </a>
                  <img src={i.photo} alt={""} />
                </div>
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>{i.name}</h4>
                <p>{i.ocupation}</p>
                <p style={{ fontFamily: "arial" }}>{i.description}</p>
              </CardBody>
              <CardFooter>
                <Button
                  color="twitter"
                  justIcon
                  onClick={() => {
                    goSocial(i.socialNetworks.twitter);
                  }}
                >
                  <i className={"fab fa-twitter"} />
                </Button>
                <Button
                  color="facebook"
                  justIcon
                  onClick={() => {
                    goSocial(i.socialNetworks.facebook);
                  }}
                >
                  <i className={"fab fa-facebook"} />
                </Button>
                <Button
                  className={classes.instagram}
                  justIcon
                  onClick={() => {
                    goSocial(i.socialNetworks.instagram);
                  }}
                >
                  <i className={"fab fa-instagram"} />
                </Button>
                <Button
                  className={classes.linkedin}
                  justIcon
                  onClick={() => {
                    goSocial(i.socialNetworks.linkedin);
                  }}
                >
                  <i className={"fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </>
  );
}
