import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isMobile } from "react-device-detect";
import FavoriteIcon from "@material-ui/icons/Favorite";
import RecommendationsByCorrectAnswer from "./recommendations/RecommendationsByCorrectAnswer";
import { getCorrectAnswer } from "functions/getCorrectAnswer";
import Loader from "react-loader-spinner";
import ButtonNextTest from "./ButtonNextTest";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { questions } from "variables/questions";
import { scrollToTop } from "functions/scrollToTop";

import "assets/css/animations.css";


const AutoTestFinal = props => {
  const [correctAnswers, setCorrectAnswers] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [lastTest, setLastTest] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [symptoms, setSymptoms] = useState("-> ");

  const msgBlueHands =
    "¡Un saludo!, me remitieron de *coronaviruscol.com*, quisiera tener una consulta médica, mis síntomas son los siguientes: %0A%0A ";

  useEffect(() => {
    var OneSignal = window.OneSignal || [];

    OneSignal.push(function () {
      const userIn = JSON.parse(localStorage.getItem("user"));
      OneSignal.sendTags({
        email: userIn.email
      });
      OneSignal.getNotificationPermission(permission => {
        console.log("Site Notification Permission:", permission);
        OneSignal.getUserId(userId => {
          console.log(
            "OneSignal User ID (getNotificationPermission): " + userId
          );
          if (userId != null) {
            console.log("userId: " + userId);
            //updateOneSignalToken(userId, userIn.token);
          }
        });
      });
    });
  }, []);

  useEffect(() => {
    (async () => {
      //buscamos el ultimo auto-test
      const idTest = await props.db
        .collection("auto-test")
        .where("email", "==", user.email)
        .orderBy("date", "desc")
        .limit(1)
        .get()
        .then(querySnapshot => {
          setLastTest(querySnapshot.docs[0].data());
          return querySnapshot.docs[0].id;
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });

      props.db
        .collection("auto-test")
        .doc(idTest)
        .get()
        .then(doc => {
          if (doc.exists) {
            const answers = doc.data().answers;
            setCorrectAnswers(getCorrectAnswer(answers));

            // Se llena variable para envio de mesaje de Whatsapp
            var smsString = "";
            questions.forEach((question, index) => {
              const answer = answers[index + 1] === "yes" ? "*Si*" : "*No*";
              console.log(answer);
              smsString = smsString + (symptoms + question.text + " : " + answer + " %0A ");
            });

            setSymptoms(smsString);

          } else {
            console.log("No such document!");
          }
          setLoading(false);
        })
        .catch(error => {
          console.log("Error getting document:", error);
          setLoading(false);
        });
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    scrollToTop();
  }, []);

  const getColorByCorrectAnswers = () => {
    if (correctAnswers === -1) {
      return "#fff";
    } else if (
      correctAnswers === 0 ||
      correctAnswers === 1 ||
      correctAnswers === 2 ||
      correctAnswers === 3 ||
      correctAnswers === 4
    ) {
      return "forestgreen";
    } else if (correctAnswers === 5 || correctAnswers === 6) {
      return "orange";
    } else {
      return "crimson";
    }

  }

  return (
    <GridContainer style={{ padding: isMobile ? 0 : 50 }}>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h2 style={{ fontWeight: "bold" }}>Tu Evaluación</h2>
        <span style={{ fontSize: 14, color: "#aaa", fontFamily: "arial" }}>
          Estamos trabajando en una app en la que podrás realizar un seguimiento de tu autoevaluación.
        </span>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <br></br>
      </GridItem>
      <GridItem xs={12} md={6} sm={12} lg={6}>
        <div
          style={{
            backgroundColor: getColorByCorrectAnswers(),
            color: "#fff",
            borderRadius: 10,
            padding: 10,
            marginTop: "20px"
          }}
        >
          <h4
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={() => props.setStep("medical-attention")}
          >
            <span style={{ color: "navy" }}>
              {"Sigue las instrucciones que aparecen a continuación:"}
            </span>
          </h4>
          <span style={{ textAlign: "justify" }}>
            {loading && (
              <div style={{ textAlign: "center" }}>
                <Loader
                  type="BallTriangle"
                  color="#000"
                  height={50}
                  width={50}
                />
              </div>
            )}
            {correctAnswers >= 0 && (
              <RecommendationsByCorrectAnswer correctAnswers={correctAnswers} />
            )}
          </span>
        </div>
      </GridItem>
      <GridItem xs={12} md={6} sm={12} lg={6}>
        {(correctAnswers >= 5 || true) && (
          <>
            <div
              className="pulse"
              style={{
                borderRadius: 10,
                border: "2px solid rgb(0,0,0,.2)",
                flexDirection: "row",
                display: "flex",
                paddingBottom: 30,
                textAlign: "center",
                padding: 20,
                marginTop: "20px",
                backgroundColor: "#2CB742"
              }}
              onClick={() => window.open(`https://api.whatsapp.com/send?phone=573183541782&text=${msgBlueHands}${symptoms}`, "wapp")}
            >
              <div
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  width: "20%"
                }}
              >
                <WhatsappIcon
                  style={{
                    alignSelf: "center",
                    textAlign: "center"
                  }}
                  size={50}
                  round={true}
                />
              </div>
              <div
                style={{
                  alignSelf: "center",
                  width: "80%",
                  textAlign: "justify",
                  color: "#847b78"
                }}
              >
                <span style={{ color: "#fff", fontFamily: "arial" }}>
                  Toma tu consulta medica online dando clic <b>aquí</b>
                </span>
              </div>
            </div>
          </>
        )}

        <div
          style={{
            borderRadius: 10,
            border: "2px solid rgb(0,0,0,.2)",
            padding: 10,
            height: 300,
            marginTop: "20px",
            backgroundColor: "crimson"
          }}
        >
          <FavoriteIcon style={{ fontSize: 80, color: "#fff" }} />
          <h4 style={{ fontFamily: "arial", color: "#fff" }}><b>{"Invita a quien quieras"}</b></h4>
          <p style={{ textAlign: "justify", color: "#fff", fontFamily: "arial", fontSize: 16 }}>
            Esta aplicación te ofrece información según tus síntomas y ayuda a
            los equipos de atención a canalizar las consultas.
          </p>
          <WhatsappShareButton
            style={{
              width: "100%",
              borderRadius: 10,
              fontSize: 20,
              border: "2px solid #fff",
              //height: 60,
              display: "flex",
              backgroundColor: "#2CB742",
              color: "#fff"
            }}
            url={
              "http://coronaviruscol.com/ \n ¡Haz tu test diagnóstico del Covid 19! Súmate y ayudemos a descongestionar el sistema de salud, es por tu bien, por el de todos. *#EstaEnNuestrasManos* 🇨🇴 \n\n¡Compártelo en tu historia y ayudemos a Colombia!"
            }
          >
            <div
              style={{
                width: isMobile ? "40%" : "43%",
                textAlign: "right",

              }}
            >
              <WhatsappIcon size={28} round={true} style={{ marginTop: 6 }} />
            </div>
            <div
              style={{
                width: isMobile ? "60%" : "57%",
                textAlign: "left",
                alignSelf: "center"
              }}
            >
              <span>{" Compartir"}</span>
            </div>
          </WhatsappShareButton>
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <hr></hr>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        {Object.keys(lastTest).length > 0 && (
          <ButtonNextTest lastTest={lastTest} setStep={props.setStep} />
        )}
      </GridItem>
    </GridContainer>
  );
};

export default AutoTestFinal;
