import React from "react";
import { deparments } from "variables/deparments";

const RecommendationsByCorrectAnswer = props => {
  let componentLocation = "No disponible";
  try {
    if (
      localStorage.getItem("personData") !== undefined &&
      localStorage.getItem("personData") !== null
    ) {
      const personData = JSON.parse(localStorage.getItem("personData"));
      const dpts = deparments.filter(
        obj => parseInt(obj.code) === parseInt(personData.deparment)
      );
      componentLocation = (
        <b
          style={{ color: "#000" }}
        >{`${dpts[0].name} - ${dpts[0].phone}`}</b>
      );
    }
  } catch (err) {
    console.error(err);
  }

  if (
    props.correctAnswers === 0 ||
    props.correctAnswers === 1 ||
    props.correctAnswers === 2 ||
    props.correctAnswers === 3 ||
    props.correctAnswers === 4
  ) {
    return (
      <>
        <h5 style={{ textAlign: "center" }}>
          RIESGO LEVE
        </h5>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          Tus datos nos indican que no tienes síntomas o tal vez son muy leves
          para concluir que tienes CORONAVIRUS. Sin embargo, no debes olvidar
          que algunos portadores del virus son asintomáticos o pueden estar en
          periodo de incubación.
        </p>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          Te recomendamos realizar este TEST cada 12 horas para chequear tu
          evolución. Por cualquier anomalía contacta al número de emergencia de
          tu zona: <b>{componentLocation}</b>
        </p>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          No olvides que los servicios médicos solo deben ser usados por
          pacientes que realmente lo necesiten.
        </p>
        <p>
          <b style={{ fontSize: 18 }}>¡Está en nuestras manos!</b>
        </p>
      </>
    );
  } else if (props.correctAnswers === 5 || props.correctAnswers === 6) {
    return (
      <>
        <h5 style={{ textAlign: "center" }}>
          RIESGO NORMAL
        </h5>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          Según tus respuestas, los síntomas que presentas están relacionados
          con el COVID19, sin embargo, no son tan fuertes para contactar al
          número de emergencias. Recuerda que muchas personas presentan síntomas
          leves y pueden quedarse en casa. Realiza este TEST en 12 horas y haz
          un seguimiento a tu evolución.
        </p>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          Mantén especial atención con tu situación respiratoria, si presentas
          alguna alteración comunícate con el número de emergencias asignado
          para tu ubicación: <b>{componentLocation}</b>
        </p>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          <b style={{ color: "#2CB742" }}>
            Cumples con los requisitos para hablar con el WhatsApp azul, ¡Ellos
            te darán una consulta médica online!
          </b>
        </p>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          Recuerda que los servicios médicos solo deben ser usados por pacientes
          que realmente lo necesiten.
        </p>
        <p>
          <b style={{ fontSize: 18 }}>¡Está en nuestras manos!</b>
        </p>
      </>
    );
  } else {
    return (
      <>
        <h5 style={{ textAlign: "center" }}>
          RIESGO ALTO
        </h5>
        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          De acuerdo a tus respuestas, los síntomas que presentas están muy
          relacionados con el COVID19, mantén una atención especial de tu estado
          de salud. Recuerda que muchas personas presentan síntomas y pueden
          quedarse en casa.
        </p>

        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          <span style={{ color: "cyan" }}>
            ¡Por tus síntomas, hemos habilitado el WhatsApp azul, escríbeles,
            ellos te darán un diagnóstico más profundo!
          </span>
        </p>

        <p style={{ textAlign: "justify", fontSize: 18, fontFamily: "arial" }}>
          Si perteneces a alguno de los siguientes grupos:{" "}
          <b>
            Adultos mayores de 70 años, pacientes con diabetes mellitus,
            hipertensión arterial, enfermedad renal crónica, enfermedad pulmonar
            crónica
          </b>{" "}
          te aconsejamos que te comuniques con el número de emergencias asignado
          para tu ubicación: <b>{componentLocation}</b>
        </p>
        <p>
          <b style={{ fontSize: 18 }}>¡Está en nuestras manos!</b>
        </p>
      </>
    );
  }
};

export default RecommendationsByCorrectAnswer;
