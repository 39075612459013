import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import FirebaseConfig from "config/FirebaseConfig";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { cardTitle } from "assets/jss/material-kit-react.js";
import NavPills from "components/NavPills/NavPills.js";
import "assets/css/table.css";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
//import EditIcon from "@material-ui/icons/Edit";

const db = firebase.firestore(FirebaseConfig);

const useStyles = makeStyles({
  color: {
    color: "#000"
  },
  colorWhite: {
    color: "#fff",
    backgroundColor: "#000",
    fontWeight: "bold"
  },
  textCenter: {
    textAlign: "center"
  },
  cardTitle,
  textArea: {
    border: "1px solid #DBE1EB",
    fontSize: 14,
    fontFamily: "Arial, Verdana",
    borderRadius: 4,
    background: "#FFFFFF",
    color: "#2E3133",
    float: "left",
    width: "100%",
    marginTop: 10
  },
  alignLeft: {
    textAlign: "left"
  }
});

export default function News(props) {
  const { history } = props;
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [idTwit, setIdTwit] = useState("");
  const [posts, setPosts] = useState([]);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    (async () => {
      // firebase.auth().signOut();
      await firebase.auth().onAuthStateChanged(userInfo => {
        if (!userInfo) {
          alert("Debes iniciar sesión para acceder a este módulo");
          history.push("admin");
        } else {
          db.collection("news")
            .orderBy("date", "desc")
            .get()
            .then(querySnapshot => {
              const news = querySnapshot.docs.map(doc => doc.data());
              // setPosts(news.filter(post => post.active === true));
              setPosts(news);
            });
        }
      });

      // await setUser(user.providerData[0]);
    })();
  }, [reloadData]); // eslint-disable-line

  const submit = e => {
    e.preventDefault();
    db.collection("news")
      .add({
        idPost: uuidv4(),
        title: title,
        description: description,
        idTwit: idTwit,
        active: true,
        date: new Date()
      })
      .then(() => {
        // setIsLoading(false);
        setTitle("");
        setDescription("");
        setIdTwit("");

        alert("Noticia creada correctamente");
      })
      .catch(error => {
        alert("Ha ocurrido un error al crear la noticia.");
        // setIsLoading(false);
        console.log(error);
      });
  };

  const deletePost = async (e, post) => {
    e.preventDefault();
    let value = window.confirm("¿Está seguro que desea eliminar esta noticia?");
    if (value) {
      //buscamos el id del idNews
      const idPost = await db
        .collection("news")
        .where("idPost", "==", post.idPost)
        .get()
        .then(querySnapshot => {
          return querySnapshot.docs[0].id;
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });

      let postRef = await db.collection("news").doc(idPost);

      await postRef
        .update({
          idPost: post.idPost,
          title: post.title,
          description: post.description,
          idTwit: post.idTwit,
          active: false
        })
        .then(() => {
          setReloadData(true);
        });
      setReloadData(false);
    }
  };

  /*
  const editPost = e => {
    console.log("Editando...");
  };
  */

  const uploadPost = async (e, post) => {
    e.preventDefault();
    let value = window.confirm(
      "¿Está seguro que desea subir nuevamente esta noticia?"
    );
    if (value) {
      //buscamos el id del idNews
      const idPost = await db
        .collection("news")
        .where("idPost", "==", post.idPost)
        .get()
        .then(querySnapshot => {
          return querySnapshot.docs[0].id;
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });

      let postRef = await db.collection("news").doc(idPost);

      await postRef
        .update({
          idPost: post.idPost,
          title: post.title,
          description: post.description,
          idTwit: post.idTwit,
          active: true
        })
        .then(() => {
          setReloadData(true);
        });

      setReloadData(false);
    }
  };

  return (
    <NavPills
      tabs={[
        {
          tabButton: "Noticias",
          tabContent: (
            <GridContainer>
              <GridItem md={3} lg={3} />
              <GridItem xs={12} md={6} sm={12} lg={6}>
                <form onSubmit={e => submit(e)}>
                  <Card className={classes.textCenter}>
                    <CardHeader className={classes.colorWhite}>
                      <h2>Creación de noticias</h2>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Título"
                        name="title"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "title",
                          onChange: e => setTitle(e.target.value),
                          required: true,
                          value: title
                        }}
                      />
                      <div className={classes.alignLeft}>
                        <label>Descripción</label>
                        <textarea
                          className={classes.textArea}
                          onChange={e => setDescription(e.target.value)}
                          name="description"
                          id="description"
                          rows="4"
                          value={description}
                          required={true}
                        ></textarea>
                      </div>
                      <CustomInput
                        labelText="ID Twit"
                        name="twit"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "twit",
                          onChange: e => setIdTwit(e.target.value),
                          required: true,
                          value: idTwit
                        }}
                      />
                      <Button type="submit" style={{ backgroundColor: "#000" }}>
                        Crear
                      </Button>
                    </CardBody>
                  </Card>
                </form>
              </GridItem>
              <GridItem md={3} lg={3} />
            </GridContainer>
          )
        },
        {
          tabButton: "Lista noticias",
          tabContent: (
            <div className="table-title">
              <h3>Lista noticias</h3>
              <table className="table-fill">
                <thead>
                  <tr>
                    <th className="text-left">ID</th>
                    <th className="text-left">Titulo</th>
                    <th className="text-left">Twit</th>
                    <th className="text-left"></th>
                  </tr>
                </thead>
                <tbody className="table-hover">
                  {posts.map((post, index) => (
                    <tr key={index}>
                      <td className="text-left">{index + 1}</td>
                      <td className="text-left">{post.title}</td>
                      <td className="text-left">{post.idTwit}</td>
                      <td className="text-left">
                        {post.active ? (
                          <div>
                            <Button
                              color="danger"
                              onClick={e => deletePost(e, post)}
                            >
                              <DeleteIcon className={classes.icons} />
                            </Button>
                            {/* <Button
                              color="warning"
                              onClick={e => editPost(e, post)}
                            >
                              <EditIcon className={classes.icons} />
                            </Button> */}
                          </div>
                        ) : (
                            <Button
                              color="success"
                              onClick={e => uploadPost(e, post)}
                            >
                              <CloudUploadIcon className={classes.icons} />
                            </Button>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        }
      ]}
    />
  );
}
